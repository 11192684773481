"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.SAVE_HELPCENTER_SETTINGS_SUCCESS = exports.SAVE_HELPCENTER_SETTINGS_FAIL = exports.SAVE_HELPCENTER_SETTINGS = void 0;
var _module = require("../../utils/module");
// separate out to fix auth/account circular dependency
var type = (0, _module.namespaceType)('redux/help-center/');
var SAVE_HELPCENTER_SETTINGS = exports.SAVE_HELPCENTER_SETTINGS = type('SAVE_HELPCENTER_SETTINGS');
var SAVE_HELPCENTER_SETTINGS_SUCCESS = exports.SAVE_HELPCENTER_SETTINGS_SUCCESS = type('SAVE_HELPCENTER_SETTINGS_SUCCESS');
var SAVE_HELPCENTER_SETTINGS_FAIL = exports.SAVE_HELPCENTER_SETTINGS_FAIL = type('SAVE_HELPCENTER_SETTINGS_FAIL');