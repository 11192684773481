"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.toggleFakeLoading = exports.fakeLoadingWithPromise = exports.fakeLoading = exports.default = void 0;
var _module = require("../../utils/module");
function _typeof(o) { "@babel/helpers - typeof"; return _typeof = "function" == typeof Symbol && "symbol" == typeof Symbol.iterator ? function (o) { return typeof o; } : function (o) { return o && "function" == typeof Symbol && o.constructor === Symbol && o !== Symbol.prototype ? "symbol" : typeof o; }, _typeof(o); }
function ownKeys(e, r) { var t = Object.keys(e); if (Object.getOwnPropertySymbols) { var o = Object.getOwnPropertySymbols(e); r && (o = o.filter(function (r) { return Object.getOwnPropertyDescriptor(e, r).enumerable; })), t.push.apply(t, o); } return t; }
function _objectSpread(e) { for (var r = 1; r < arguments.length; r++) { var t = null != arguments[r] ? arguments[r] : {}; r % 2 ? ownKeys(Object(t), !0).forEach(function (r) { _defineProperty(e, r, t[r]); }) : Object.getOwnPropertyDescriptors ? Object.defineProperties(e, Object.getOwnPropertyDescriptors(t)) : ownKeys(Object(t)).forEach(function (r) { Object.defineProperty(e, r, Object.getOwnPropertyDescriptor(t, r)); }); } return e; }
function _defineProperty(e, r, t) { return (r = _toPropertyKey(r)) in e ? Object.defineProperty(e, r, { value: t, enumerable: !0, configurable: !0, writable: !0 }) : e[r] = t, e; }
function _toPropertyKey(t) { var i = _toPrimitive(t, "string"); return "symbol" == _typeof(i) ? i : i + ""; }
function _toPrimitive(t, r) { if ("object" != _typeof(t) || !t) return t; var e = t[Symbol.toPrimitive]; if (void 0 !== e) { var i = e.call(t, r || "default"); if ("object" != _typeof(i)) return i; throw new TypeError("@@toPrimitive must return a primitive value."); } return ("string" === r ? String : Number)(t); }
var type = (0, _module.namespaceType)('redux/apiLoading/');
var API_FAKE_LOADING = type('API_FAKE_LOADING');
var API_FAKE_LOADING_SUCCESS = type('API_FAKE_LOADING_SUCCESS');
var API_FAKE_LOADING_TOGGLE = type('API_FAKE_LOADING_TOGGLE');
var API_FAKE_LOADING_WITH_PROMISE = type('API_FAKE_LOADING_WITH_PROMISE');
var API_FAKE_LOADING_WITH_PROMISE_DONE = type('API_FAKE_LOADING_WITH_PROMISE_DONE');
var initialState = {
  requests: 0,
  fakeLoading: false
};
var _default = exports.default = function _default() {
  var state = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : initialState;
  var action = arguments.length > 1 ? arguments[1] : undefined;
  if (action._apiRequest) {
    return _objectSpread(_objectSpread({}, state), {}, {
      requests: state.requests + 1
    });
  }
  if (action._apiRequestDone) {
    var newRequests = state.requests - 1;
    if (newRequests === 0) return initialState;
    return _objectSpread(_objectSpread({}, state), {}, {
      requests: newRequests
    });
  }
  if (action.type === API_FAKE_LOADING) {
    return _objectSpread(_objectSpread({}, state), {}, {
      fakeLoading: true
    });
  }
  if (action.type === API_FAKE_LOADING_SUCCESS) {
    return _objectSpread(_objectSpread({}, state), {}, {
      fakeLoading: false
    });
  }
  if (action.type === API_FAKE_LOADING_TOGGLE) {
    return _objectSpread(_objectSpread({}, state), {}, {
      fakeLoading: typeof action.value === 'undefined' ? !state.fakeLoading : !!action.value
    });
  }
  return state;
};
var fakeLoading = exports.fakeLoading = function fakeLoading(timeMS) {
  return function (dispatch) {
    dispatch({
      type: API_FAKE_LOADING
    });
    setTimeout(function () {
      dispatch({
        type: API_FAKE_LOADING_SUCCESS
      });
    }, timeMS);
  };
};
var fakeLoadingWithPromise = exports.fakeLoadingWithPromise = function fakeLoadingWithPromise(promise) {
  return function (dispatch) {
    dispatch({
      type: API_FAKE_LOADING_WITH_PROMISE,
      _apiRequest: true
    });
    Promise.resolve(promise).then(function () {
      dispatch({
        type: API_FAKE_LOADING_WITH_PROMISE_DONE,
        _apiRequestDone: true
      });
    }).catch(function () {
      dispatch({
        type: API_FAKE_LOADING_WITH_PROMISE_DONE,
        _apiRequestDone: true
      });
    });
  };
};
var toggleFakeLoading = exports.toggleFakeLoading = function toggleFakeLoading(value) {
  return {
    type: API_FAKE_LOADING_TOGGLE,
    value: value
  };
};