"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.SHARING_SCREENSHOT_SIZES = exports.PLAYER_VIDEO_VIEWPORT_WIDTH = exports.PLAYER_VIDEO_VIEWPORT_HEIGHT = exports.MIN_SCALE_PERCENT = void 0;
exports.checkTutorialCanUseAudio = checkTutorialCanUseAudio;
exports.checkTutorialCanUseAudioTranscription = checkTutorialCanUseAudioTranscription;
exports.checkTutorialCanUseMusic = checkTutorialCanUseMusic;
exports.checkTutorialCanUseVideo = checkTutorialCanUseVideo;
exports.checkTutorialCanUseVideoTranscription = checkTutorialCanUseVideoTranscription;
exports.checkTutorialHasAudio = checkTutorialHasAudio;
exports.checkTutorialHasAudioTranscription = checkTutorialHasAudioTranscription;
exports.checkTutorialHasMusic = checkTutorialHasMusic;
exports.checkTutorialHasVideo = checkTutorialHasVideo;
exports.checkTutorialHasVideoTranscription = checkTutorialHasVideoTranscription;
exports.getDescription = getDescription;
exports.getDurationIso8601 = getDurationIso8601;
exports.getFlattenedSteps = getFlattenedSteps;
exports.getQuizSteps = getQuizSteps;
exports.getSocialDescription = getSocialDescription;
exports.getSocialImageUrl = getSocialImageUrl;
exports.getStepDescription = getStepDescription;
exports.getStepLabel = getStepLabel;
exports.getStepPlayDuration = getStepPlayDuration;
exports.stripTags = stripTags;
var urlUtils = _interopRequireWildcard(require("./urls"));
var _config = _interopRequireDefault(require("../config"));
var _capitalize = _interopRequireDefault(require("lodash/capitalize"));
var _compact = _interopRequireDefault(require("lodash/compact"));
var _unescape = _interopRequireDefault(require("lodash/unescape"));
function _interopRequireDefault(e) { return e && e.__esModule ? e : { default: e }; }
function _getRequireWildcardCache(e) { if ("function" != typeof WeakMap) return null; var r = new WeakMap(), t = new WeakMap(); return (_getRequireWildcardCache = function _getRequireWildcardCache(e) { return e ? t : r; })(e); }
function _interopRequireWildcard(e, r) { if (!r && e && e.__esModule) return e; if (null === e || "object" != _typeof(e) && "function" != typeof e) return { default: e }; var t = _getRequireWildcardCache(r); if (t && t.has(e)) return t.get(e); var n = { __proto__: null }, a = Object.defineProperty && Object.getOwnPropertyDescriptor; for (var u in e) if ("default" !== u && {}.hasOwnProperty.call(e, u)) { var i = a ? Object.getOwnPropertyDescriptor(e, u) : null; i && (i.get || i.set) ? Object.defineProperty(n, u, i) : n[u] = e[u]; } return n.default = e, t && t.set(e, n), n; }
function _typeof(o) { "@babel/helpers - typeof"; return _typeof = "function" == typeof Symbol && "symbol" == typeof Symbol.iterator ? function (o) { return typeof o; } : function (o) { return o && "function" == typeof Symbol && o.constructor === Symbol && o !== Symbol.prototype ? "symbol" : typeof o; }, _typeof(o); }
function _toConsumableArray(r) { return _arrayWithoutHoles(r) || _iterableToArray(r) || _unsupportedIterableToArray(r) || _nonIterableSpread(); }
function _nonIterableSpread() { throw new TypeError("Invalid attempt to spread non-iterable instance.\nIn order to be iterable, non-array objects must have a [Symbol.iterator]() method."); }
function _unsupportedIterableToArray(r, a) { if (r) { if ("string" == typeof r) return _arrayLikeToArray(r, a); var t = {}.toString.call(r).slice(8, -1); return "Object" === t && r.constructor && (t = r.constructor.name), "Map" === t || "Set" === t ? Array.from(r) : "Arguments" === t || /^(?:Ui|I)nt(?:8|16|32)(?:Clamped)?Array$/.test(t) ? _arrayLikeToArray(r, a) : void 0; } }
function _iterableToArray(r) { if ("undefined" != typeof Symbol && null != r[Symbol.iterator] || null != r["@@iterator"]) return Array.from(r); }
function _arrayWithoutHoles(r) { if (Array.isArray(r)) return _arrayLikeToArray(r); }
function _arrayLikeToArray(r, a) { (null == a || a > r.length) && (a = r.length); for (var e = 0, n = Array(a); e < a; e++) n[e] = r[e]; return n; }
function ownKeys(e, r) { var t = Object.keys(e); if (Object.getOwnPropertySymbols) { var o = Object.getOwnPropertySymbols(e); r && (o = o.filter(function (r) { return Object.getOwnPropertyDescriptor(e, r).enumerable; })), t.push.apply(t, o); } return t; }
function _objectSpread(e) { for (var r = 1; r < arguments.length; r++) { var t = null != arguments[r] ? arguments[r] : {}; r % 2 ? ownKeys(Object(t), !0).forEach(function (r) { _defineProperty(e, r, t[r]); }) : Object.getOwnPropertyDescriptors ? Object.defineProperties(e, Object.getOwnPropertyDescriptors(t)) : ownKeys(Object(t)).forEach(function (r) { Object.defineProperty(e, r, Object.getOwnPropertyDescriptor(t, r)); }); } return e; }
function _defineProperty(e, r, t) { return (r = _toPropertyKey(r)) in e ? Object.defineProperty(e, r, { value: t, enumerable: !0, configurable: !0, writable: !0 }) : e[r] = t, e; }
function _toPropertyKey(t) { var i = _toPrimitive(t, "string"); return "symbol" == _typeof(i) ? i : i + ""; }
function _toPrimitive(t, r) { if ("object" != _typeof(t) || !t) return t; var e = t[Symbol.toPrimitive]; if (void 0 !== e) { var i = e.call(t, r || "default"); if ("object" != _typeof(i)) return i; throw new TypeError("@@toPrimitive must return a primitive value."); } return ("string" === r ? String : Number)(t); }
var SHARING_SCREENSHOT_SIZES = exports.SHARING_SCREENSHOT_SIZES = {
  default: [600, 315],
  email: [378, 378],
  twitter: [506, 253],
  facebook: [600, 315],
  linkedin: [552, 289]
};

// A number between 0 and 1. The lower is the number, the biggest is the zoom out.
// Use 0 for no restrictions. Use 1 to prevent any zoom out.
var MIN_SCALE_PERCENT = exports.MIN_SCALE_PERCENT = 0.8;
var PLAYER_VIDEO_VIEWPORT_WIDTH = exports.PLAYER_VIDEO_VIEWPORT_WIDTH = 272;
var PLAYER_VIDEO_VIEWPORT_HEIGHT = exports.PLAYER_VIDEO_VIEWPORT_HEIGHT = 272;
function getSocialImageUrl(json) {
  var options = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : {};
  var url;
  if (json.steps.length > 0 && json.steps[0].screenshot) {
    url = 'https:' + urlUtils.api('/tutorial/sharingScreenshot', {
      tutorial_id: parseInt(json.tutorial.moduleID, 10),
      sharing_type: options.sharingType,
      cache: json.tutorial && json.tutorial.updated ? new Date(json.tutorial.updated).getTime() : Date.now()
    });
  } else {
    // TODO: default image
    url = '';
  }
  return url;
}
function getSocialDescription(json) {
  if (json.tutorial.isPremiumTutorial) {
    return getDescription(json);
  }
  return "Check out this tutorial on ".concat(urlUtils.normaliseDomain(_config.default.host, {
    removeWWW: true
  }));
}
function stripTags(str) {
  return (0, _unescape.default)(String(str || '').replace(/<br(\s*?)\/?>/ig, ' ') // replace HTML line break with space (see #12364#issuecomment-1239269935)
  .replace(/<.+?>/g, '')).replace(/&nbsp;/g, ' ');
}

// return an array with the stepJson and its sub steps, recursively.
function getSubSteps(stepJson) {
  var values = [stepJson];
  (stepJson.steps || []).forEach(function (subStepJson) {
    values = values.concat(getSubSteps(subStepJson));
  });
  return values;
}
function getStepDescription(stepJson) {
  if (!stepJson) return '';
  if (stepJson.description) {
    // convert to string in case of corrupted description
    // e.g. a number (see https://github.com/iorad/iorad/issues/7705#issuecomment-537496258)
    return String(stepJson.description);
  }
  return (0, _compact.default)(getSubSteps(stepJson).map(function (step) {
    return String(step.description || '');
  })).join(', ');
}
function getDescription(json) {
  return json.tutorial.description || (json.steps || []).map(function (step) {
    return stripTags(getStepDescription(step));
  }).join('. ');
}
function checkTutorialHasAudio(json) {
  return Boolean((json.steps || []).find(function (step) {
    return step.audioId !== null && typeof step.audioId !== 'undefined';
  }));
}
function checkTutorialHasVideo(json) {
  return Boolean((json.steps || []).find(function (step) {
    return step.videoId !== null && typeof step.videoId !== 'undefined';
  }));
}
function checkTutorialCanUseAudio(json) {
  var tutorial = json.tutorial || {};
  if (tutorial.account_is_trialing_with_quota) {
    return tutorial.quota_is_trial_tutorial || checkTutorialHasAudio(json);
  }
  var count = tutorial.user_audio_count;
  var limit = tutorial.audio_limit;
  return count < limit || count === limit && checkTutorialHasAudio(json);
}
function checkTutorialCanUseVideo(json) {
  var tutorial = json.tutorial || {};
  if (tutorial.account_is_trialing_with_quota) {
    return tutorial.quota_is_trial_tutorial || checkTutorialHasVideo(json);
  }
  var count = tutorial.user_video_count;
  var limit = tutorial.video_limit;
  return count < limit || count === limit && checkTutorialHasVideo(json);
}
function checkTutorialHasAudioTranscription(json) {
  return Boolean((json.steps || []).find(function (step) {
    return step.audioId !== null && typeof step.audioId !== 'undefined' && step.audioTranscription;
  }));
}
function checkTutorialHasVideoTranscription(json) {
  return Boolean((json.steps || []).find(function (step) {
    return step.videoId !== null && typeof step.videoId !== 'undefined' && step.videoTranscription;
  }));
}
function checkTutorialCanUseAudioTranscription(json, user) {
  return checkTutorialCanUseAudio(json) && user.audioTranscription;
}
function checkTutorialCanUseVideoTranscription(json, user) {
  return checkTutorialCanUseVideo(json) && user.videoTranscription;
}
function checkTutorialHasMusic(json) {
  return json.tutorial.musicEnabled && !!json.tutorial.musicId;
}
function checkTutorialCanUseMusic(json) {
  var tutorial = json.tutorial || {};
  if (tutorial.account_is_trialing_with_quota) {
    return tutorial.quota_is_trial_tutorial || checkTutorialHasMusic(json);
  }
  var count = tutorial.music_usage_count;
  var limit = tutorial.music_usage_limit;
  return count < limit || count === limit && checkTutorialHasMusic(json);
}
function getSubStepAudioFields(subStep, step, subStepIndex) {
  if (subStepIndex !== 0) {
    // no audio
    return {
      audioId: null,
      audioModTime: null
    };
  }
  // parent audio by default
  return {
    audioId: typeof subStep.audioId !== 'undefined' && subStep.audioId !== null ? subStep.audioId : step.audioId,
    audioModTime: typeof subStep.audioModTime !== 'undefined' && subStep.audioModTime !== null ? subStep.audioModTime : step.audioModTime,
    audioEncrypted: typeof subStep.audioEncrypted !== 'undefined' && subStep.audioEncrypted !== null ? subStep.audioEncrypted : step.audioEncrypted
  };
}
function getSubStepVideoFields(subStep, step, subStepIndex) {
  if (subStepIndex !== 0) {
    // no video
    return {
      videoId: null,
      videoModTime: null
    };
  }
  // parent video by default
  return {
    videoId: typeof subStep.videoId !== 'undefined' && subStep.videoId !== null ? subStep.videoId : step.videoId,
    videoModTime: typeof subStep.videoModTime !== 'undefined' && subStep.videoModTime !== null ? subStep.videoModTime : step.videoModTime,
    videoEncrypted: typeof subStep.videoEncrypted !== 'undefined' && subStep.videoEncrypted !== null ? subStep.videoEncrypted : step.videoEncrypted
  };
}
function getFlattenedSteps(steps) {
  // flatten combo steps
  var newSteps = [];
  steps.forEach(function (step) {
    if (step.steps && step.steps.length !== 0 && !step.summary && step.type !== 'SCROLL' // ignore broken non-postprocessed SCROLL substeps (See https://github.com/iorad/iorad/issues/12775)
    ) {
      var _newSteps;
      var newSubSteps = step.steps.map(function (subStep, i) {
        return _objectSpread(_objectSpread(_objectSpread({}, subStep), {}, {
          _flattenedSubStep: i > 0,
          description: getSubStepDescription(subStep, step),
          number: getSubStepNumber(subStep, step, steps),
          parentType: step.type,
          event: _objectSpread(_objectSpread({}, step.event), subStep.event)
        }, getSubStepAudioFields(subStep, step, i)), getSubStepVideoFields(subStep, step, i));
      });
      (_newSteps = newSteps).push.apply(_newSteps, _toConsumableArray(newSubSteps));
    } else {
      newSteps.push(step);
    }
  });
  newSteps = newSteps.map(function (newStep, i) {
    return _objectSpread(_objectSpread({}, newStep), {}, {
      number: typeof newStep.number !== 'undefined' ? newStep.number : newStep.index + 1,
      index: i,
      window: newStep.window || {}
    });
  });
  return newSteps;
}
function getSubStepDescription(step, parentStep) {
  if (!parentStep) {
    return String(step.description || '');
  }
  if (parentStep.steps.indexOf(step) === 0) {
    return String(parentStep.description || '');
  }

  // other sub-step, show type as description
  var description = step.type; // "LEFT_CLICK"
  description = description.replace(new RegExp('_', 'g'), ' '); // "LEFT CLICK"
  description = description.toLowerCase(); // "left click"
  description = (0, _capitalize.default)(description); // "Left click"
  if (description === 'Left click') {
    // overrides
    description = 'Click';
  }
  return description;
}

// Note: assumes we don't have more than one level of sub-steps
function getSubStepNumber(step, parentStep, topSteps) {
  if (!parentStep) return topSteps.indexOf(step) + 1;
  if (parentStep.steps.indexOf(step) === 0) return topSteps.indexOf(parentStep) + 1;
  var topNumber = topSteps.indexOf(parentStep) + 1;

  // non-first sub-step, use parent number + letter
  // e.g. 1b for second sub-step of the first step
  // NOTE: this method won't work if there are more sub-steps than the letters of the alphabet!
  var position = parentStep.steps.indexOf(step); // sub-step position
  var letter = 'abcdefghijklmnopqrstuvwxyz'.charAt(position);
  return topNumber + letter;
}
function getStepReadDuration(step) {
  var WORD_DURATION = 250; // milliseconds
  var IMAGE_DURATION = 0; // milliseconds

  var descriptionText = stripTags(getStepDescription(step));
  var descriptionWords = descriptionText.trim().split(/\s+/);
  return descriptionWords.length * WORD_DURATION + IMAGE_DURATION;
}
function getStepPlayDuration(step) {
  if (!step) return 0;
  return step.videoDurationMS || step.audioDurationMS || getStepReadDuration(step);
}
function getDurationIso8601(json) {
  var duration = (json.steps || []).reduce(function (currentDuration, step) {
    return currentDuration + getStepPlayDuration(step);
  }, 0);
  var durationMinutes = Math.floor(duration / 60000);
  var durationLeft = duration - durationMinutes * 60000;
  var durationSeconds = Math.ceil(durationLeft / 1000);
  return "PT".concat(durationMinutes, "M").concat(durationSeconds, "S");
}
function getStepLabel(_ref) {
  var step = _ref.step,
    mobile = _ref.mobile;
  var stepType = step.type;
  var dblClick = Boolean(step.event && step.event.dblclick);
  if (stepType === 'READ') return 'Info';
  if (stepType === 'LEFT_CLICK') {
    if (mobile) return dblClick ? 'Double Tap' : 'Tap';
    return dblClick ? 'Double Click' : 'Click';
  }
  if (stepType === 'RIGHT_CLICK') {
    return mobile ? 'Two Finger Tap' : 'Right Click';
  }
  if (stepType === 'HOVER') {
    if (mobile) return 'Long Press';
  }
  if (stepType === 'TYPING') return 'Type';
  if (stepType === 'DRAG_AND_DROP') return 'Drag & Drop';
  if (stepType === 'SELECT_TEXT') return 'Select Text';
  return (0, _capitalize.default)((stepType || '').replace(/_/g, ' '));
}
function getQuizSteps(steps) {
  // exclude all READ steps and the last step (where reports are shown)
  return steps.filter(function (step, i) {
    return step.type !== 'READ' && i < steps.length - 1;
  });
}