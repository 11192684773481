"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.isPublicAccessType = exports.isPrivateAccessType = exports.isLockedAccessType = exports.accessTypes = void 0;
var accessTypes = exports.accessTypes = {
  public: 'U',
  private: 'P',
  publicExpired: 'UX',
  privateExpired: 'X'
};
var isPrivateAccessType = exports.isPrivateAccessType = function isPrivateAccessType(v) {
  return v !== null && [accessTypes.private, accessTypes.privateExpired].includes(v);
};
var isPublicAccessType = exports.isPublicAccessType = function isPublicAccessType(v) {
  return v !== null && [accessTypes.public, accessTypes.publicExpired].includes(v);
};
var isLockedAccessType = exports.isLockedAccessType = function isLockedAccessType(v) {
  return v !== null && [accessTypes.privateExpired, accessTypes.publicExpired].includes(v);
};