"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.actionCreators = void 0;
exports.default = reducer;
function _typeof(o) { "@babel/helpers - typeof"; return _typeof = "function" == typeof Symbol && "symbol" == typeof Symbol.iterator ? function (o) { return typeof o; } : function (o) { return o && "function" == typeof Symbol && o.constructor === Symbol && o !== Symbol.prototype ? "symbol" : typeof o; }, _typeof(o); }
function ownKeys(e, r) { var t = Object.keys(e); if (Object.getOwnPropertySymbols) { var o = Object.getOwnPropertySymbols(e); r && (o = o.filter(function (r) { return Object.getOwnPropertyDescriptor(e, r).enumerable; })), t.push.apply(t, o); } return t; }
function _objectSpread(e) { for (var r = 1; r < arguments.length; r++) { var t = null != arguments[r] ? arguments[r] : {}; r % 2 ? ownKeys(Object(t), !0).forEach(function (r) { _defineProperty(e, r, t[r]); }) : Object.getOwnPropertyDescriptors ? Object.defineProperties(e, Object.getOwnPropertyDescriptors(t)) : ownKeys(Object(t)).forEach(function (r) { Object.defineProperty(e, r, Object.getOwnPropertyDescriptor(t, r)); }); } return e; }
function _defineProperty(e, r, t) { return (r = _toPropertyKey(r)) in e ? Object.defineProperty(e, r, { value: t, enumerable: !0, configurable: !0, writable: !0 }) : e[r] = t, e; }
function _toPropertyKey(t) { var i = _toPrimitive(t, "string"); return "symbol" == _typeof(i) ? i : i + ""; }
function _toPrimitive(t, r) { if ("object" != _typeof(t) || !t) return t; var e = t[Symbol.toPrimitive]; if (void 0 !== e) { var i = e.call(t, r || "default"); if ("object" != _typeof(i)) return i; throw new TypeError("@@toPrimitive must return a primitive value."); } return ("string" === r ? String : Number)(t); }
var API_REQUEST = 'redux-example/aiassistant/API_REQUEST';
var API_REQUEST_SUCCESS = 'redux-example/aiassistant/API_REQUEST_SUCCESS';
var API_REQUEST_FAIL = 'redux-example/aiassistant/API_REQUEST_FAIL';
var SET_IS_AUTO_SPEAK = 'redux-example/aiassistant/SET_IS_AUTO_SPEAK';
var initialState = {
  loading: false,
  succeed: false,
  error: {},
  isAutoSpeak: false
};
var generateSpeechCache = {};
function reducer() {
  var state = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : initialState;
  var action = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : {};
  switch (action.type) {
    case SET_IS_AUTO_SPEAK:
      return _objectSpread(_objectSpread({}, state), {}, {
        isAutoSpeak: action.isAutoSpeak
      });
    default:
      return state;
  }
}
var actionCreators = exports.actionCreators = {
  search: function search(params) {
    return {
      types: [API_REQUEST, API_REQUEST_SUCCESS, API_REQUEST_FAIL],
      promise: function promise(client) {
        return client.get('/aiassistant/search', {
          params: params
        });
      }
    };
  },
  ask: function ask(data) {
    return {
      types: [API_REQUEST, API_REQUEST_SUCCESS, API_REQUEST_FAIL],
      promise: function promise(client) {
        return client.post('/aiassistant/ask', {
          data: data
        });
      }
    };
  },
  loadWebQuestionnaire: function loadWebQuestionnaire(params) {
    return {
      types: [API_REQUEST, API_REQUEST_SUCCESS, API_REQUEST_FAIL],
      promise: function promise(client) {
        return client.get('/aiassistant/loadWebQuestionnaire', {
          params: params
        });
      }
    };
  },
  sendAIAssistantLearnSalesAlert: function sendAIAssistantLearnSalesAlert(data) {
    return {
      types: [API_REQUEST, API_REQUEST_SUCCESS, API_REQUEST_FAIL],
      promise: function promise(client) {
        return client.post('/aiassistant/sendAIAssistantLearnSalesAlert', {
          data: data
        });
      }
    };
  },
  generateSpeech: function generateSpeech(text, voiceId, roleId) {
    return function (dispatch, getState) {
      return dispatch({
        types: [API_REQUEST, API_REQUEST_SUCCESS, API_REQUEST_FAIL],
        promise: function promise(client) {
          var cacheKey = JSON.stringify([text, voiceId]);
          if (!generateSpeechCache[cacheKey]) {
            generateSpeechCache[cacheKey] = client.post('/aiassistant/textToSpeech', {
              data: {
                text: text,
                voiceId: voiceId,
                roleId: roleId
              }
            }).catch(function () {
              generateSpeechCache[cacheKey] = undefined;
              return Promise.reject.apply(Promise, arguments);
            });
          }
          return generateSpeechCache[cacheKey];
        }
      });
    };
  },
  setIsAutoSpeak: function setIsAutoSpeak(isAutoSpeak) {
    return {
      type: SET_IS_AUTO_SPEAK,
      isAutoSpeak: isAutoSpeak
    };
  }
};