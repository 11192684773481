"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = reducer;
exports.isLoaded = isLoaded;
exports.joinCreators = joinCreators;
exports.load = load;
var _redux = require("utils/redux");
var _auth = require("./auth");
var _account = require("./account");
var _tutorials = require("redux/modules/tutorials");
function _typeof(o) { "@babel/helpers - typeof"; return _typeof = "function" == typeof Symbol && "symbol" == typeof Symbol.iterator ? function (o) { return typeof o; } : function (o) { return o && "function" == typeof Symbol && o.constructor === Symbol && o !== Symbol.prototype ? "symbol" : typeof o; }, _typeof(o); }
function ownKeys(e, r) { var t = Object.keys(e); if (Object.getOwnPropertySymbols) { var o = Object.getOwnPropertySymbols(e); r && (o = o.filter(function (r) { return Object.getOwnPropertyDescriptor(e, r).enumerable; })), t.push.apply(t, o); } return t; }
function _objectSpread(e) { for (var r = 1; r < arguments.length; r++) { var t = null != arguments[r] ? arguments[r] : {}; r % 2 ? ownKeys(Object(t), !0).forEach(function (r) { _defineProperty(e, r, t[r]); }) : Object.getOwnPropertyDescriptors ? Object.defineProperties(e, Object.getOwnPropertyDescriptors(t)) : ownKeys(Object(t)).forEach(function (r) { Object.defineProperty(e, r, Object.getOwnPropertyDescriptor(t, r)); }); } return e; }
function _defineProperty(e, r, t) { return (r = _toPropertyKey(r)) in e ? Object.defineProperty(e, r, { value: t, enumerable: !0, configurable: !0, writable: !0 }) : e[r] = t, e; }
function _toPropertyKey(t) { var i = _toPrimitive(t, "string"); return "symbol" == _typeof(i) ? i : i + ""; }
function _toPrimitive(t, r) { if ("object" != _typeof(t) || !t) return t; var e = t[Symbol.toPrimitive]; if (void 0 !== e) { var i = e.call(t, r || "default"); if ("object" != _typeof(i)) return i; throw new TypeError("@@toPrimitive must return a primitive value."); } return ("string" === r ? String : Number)(t); }
var LOAD = 'redux-example/creatorProcedure/LOAD';
var LOAD_SUCCESS = 'redux-example/creatorProcedure/LOAD_SUCCESS';
var LOAD_FAIL = 'redux-example/creatorProcedure/LOAD_FAIL';
var JOIN_CREATORS = 'redux-example/creatorProcedure/JOIN_CREATORS';
var JOIN_CREATORS_SUCCESS = 'redux-example/creatorProcedure/JOIN_CREATORS_SUCCESS';
var JOIN_CREATORS_FAIL = 'redux-example/creatorProcedure/JOIN_CREATORS_FAIL';
function reducer() {
  var state = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : {};
  var action = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : {};
  switch (action.type) {
    case LOAD:
      return _objectSpread(_objectSpread({}, state), {}, {
        loading: true,
        ti: action.ti
      });
    case LOAD_SUCCESS:
      return _objectSpread(_objectSpread({}, state), {}, {
        loading: false,
        loaded: true,
        moduleCreator: action.result.moduleCreator,
        userExists: action.result.userExists
      });
    case LOAD_FAIL:
      return _objectSpread(_objectSpread({}, state), {}, {
        loading: false,
        loaded: false,
        error: (0, _redux.getActionError)(action, 'other'),
        errorStatusCode: action.errorStatusCode
      });
    case JOIN_CREATORS:
      return _objectSpread(_objectSpread({}, state), {}, {
        joinCreatorsError: null,
        joinCreatorsErrorStatusCode: null
      });
    case JOIN_CREATORS_FAIL:
      return _objectSpread(_objectSpread({}, state), {}, {
        joinCreatorsError: (0, _redux.getActionError)(action, 'other'),
        joinCreatorsErrorStatusCode: action.errorStatusCode
      });
    default:
      return state;
  }
}
function isLoaded(globalState, ti) {
  return globalState.creatorProcedure && globalState.creatorProcedure.loaded && globalState.creatorProcedure.ti === ti;
}
function load(ti) {
  return {
    types: [LOAD, LOAD_SUCCESS, LOAD_FAIL],
    promise: function promise(client) {
      return client.get('/loadCreatorProcedure', {
        params: {
          ti: ti
        }
      });
    },
    ti: ti
  };
}
function joinCreators(ti) {
  return function (dispatch) {
    return dispatch({
      types: [JOIN_CREATORS, JOIN_CREATORS_SUCCESS, JOIN_CREATORS_FAIL],
      promise: function promise(client) {
        return client.post('/user/joinCreators', {
          data: {
            invite: ti
          }
        });
      },
      ti: ti
    }).then(function () {
      return dispatch((0, _auth.load)());
    }).then(function () {
      return dispatch((0, _account.fetchAccount)());
    }).then(function () {
      return dispatch((0, _tutorials.clearTutorialsState)());
    });
  };
}