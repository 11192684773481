"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.hexToRgbObject = hexToRgbObject;
exports.rgbObjectToHex = rgbObjectToHex;
// http://stackoverflow.com/a/5624139/1418049
function hexToRgbObject(hex) {
  if (!hex) return undefined;

  // Expand shorthand form (e.g. "03F") to full form (e.g. "0033FF")
  var shorthandRegex = /^#?([a-f\d])([a-f\d])([a-f\d])$/i;
  hex = hex.replace(shorthandRegex, function (m, r, g, b) {
    return r + r + g + g + b + b;
  });
  var result = /^#?([a-f\d]{2})([a-f\d]{2})([a-f\d]{2})$/i.exec(hex);
  return result ? {
    r: parseInt(result[1], 16),
    g: parseInt(result[2], 16),
    b: parseInt(result[3], 16)
  } : undefined;
}

// http://stackoverflow.com/a/5624139/1418049
function rgbObjectToHex(rgbObject) {
  if (!rgbObject) return undefined;
  var r = rgbObject.r,
    g = rgbObject.g,
    b = rgbObject.b;
  return '#' + ((1 << 24) + (r << 16) + (g << 8) + b).toString(16).slice(1);
}