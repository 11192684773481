"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.numericStr = exports.formatExpiryChange = exports.ccIncomplete = void 0;
var formatExpiryChange = exports.formatExpiryChange = function formatExpiryChange(val) {
  if (val && Number(val[0]) > 1) {
    val = '0' + val;
  }
  if (val && val.length > 1 && Number(val[0] + val[1]) > 12) {
    val = '12' + val.substring(2, val.length);
  }
  if (val && val.length === 2) {
    val = val + '/';
  } else {
    val = val.substring(0, 2) + (val.length > 2 ? '/' + val.substr(2, val.length) : '');
  }
  if (val.length > 7) {
    val = val.substr(0, 7);
  }
  return val;
};
var ccIncomplete = exports.ccIncomplete = function ccIncomplete(fields) {
  var _fields$cardNumber, _fields$cardNumber2, _fields$expDate, _fields$expDate2, _fields$cvc, _fields$cvc2;
  return !((_fields$cardNumber = fields.cardNumber) !== null && _fields$cardNumber !== void 0 && _fields$cardNumber.value) || ((_fields$cardNumber2 = fields.cardNumber) === null || _fields$cardNumber2 === void 0 ? void 0 : _fields$cardNumber2.value.trim().length) < 14 || !((_fields$expDate = fields.expDate) !== null && _fields$expDate !== void 0 && _fields$expDate.value) || ((_fields$expDate2 = fields.expDate) === null || _fields$expDate2 === void 0 ? void 0 : _fields$expDate2.value.trim().length) < 4 || !((_fields$cvc = fields.cvc) !== null && _fields$cvc !== void 0 && _fields$cvc.value) || ((_fields$cvc2 = fields.cvc) === null || _fields$cvc2 === void 0 ? void 0 : _fields$cvc2.value.trim().length) < 3;
};
var numericStr = exports.numericStr = function numericStr(v) {
  return String(v).replace(/\D/g, '');
};