"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
var _dayjs = _interopRequireDefault(require("dayjs"));
var _utc = _interopRequireDefault(require("dayjs/plugin/utc"));
var _updateLocale = _interopRequireDefault(require("dayjs/plugin/updateLocale"));
var _relativeTime = _interopRequireDefault(require("dayjs/plugin/relativeTime"));
var _timezone = _interopRequireDefault(require("dayjs/plugin/timezone"));
var _customParseFormat = _interopRequireDefault(require("dayjs/plugin/customParseFormat"));
var _isSameOrAfter = _interopRequireDefault(require("dayjs/plugin/isSameOrAfter"));
function _interopRequireDefault(e) { return e && e.__esModule ? e : { default: e }; }
// add modules that are used in our app

_dayjs.default.extend(_utc.default);
_dayjs.default.extend(_updateLocale.default);
_dayjs.default.extend(_relativeTime.default);
_dayjs.default.extend(_timezone.default);
_dayjs.default.extend(_customParseFormat.default);
_dayjs.default.extend(_isSameOrAfter.default);
var _default = exports.default = _dayjs.default;
module.exports = exports.default;