"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = reducer;
exports.sendEmail = sendEmail;
var _redux = require("utils/redux");
function _typeof(o) { "@babel/helpers - typeof"; return _typeof = "function" == typeof Symbol && "symbol" == typeof Symbol.iterator ? function (o) { return typeof o; } : function (o) { return o && "function" == typeof Symbol && o.constructor === Symbol && o !== Symbol.prototype ? "symbol" : typeof o; }, _typeof(o); }
function ownKeys(e, r) { var t = Object.keys(e); if (Object.getOwnPropertySymbols) { var o = Object.getOwnPropertySymbols(e); r && (o = o.filter(function (r) { return Object.getOwnPropertyDescriptor(e, r).enumerable; })), t.push.apply(t, o); } return t; }
function _objectSpread(e) { for (var r = 1; r < arguments.length; r++) { var t = null != arguments[r] ? arguments[r] : {}; r % 2 ? ownKeys(Object(t), !0).forEach(function (r) { _defineProperty(e, r, t[r]); }) : Object.getOwnPropertyDescriptors ? Object.defineProperties(e, Object.getOwnPropertyDescriptors(t)) : ownKeys(Object(t)).forEach(function (r) { Object.defineProperty(e, r, Object.getOwnPropertyDescriptor(t, r)); }); } return e; }
function _defineProperty(e, r, t) { return (r = _toPropertyKey(r)) in e ? Object.defineProperty(e, r, { value: t, enumerable: !0, configurable: !0, writable: !0 }) : e[r] = t, e; }
function _toPropertyKey(t) { var i = _toPrimitive(t, "string"); return "symbol" == _typeof(i) ? i : i + ""; }
function _toPrimitive(t, r) { if ("object" != _typeof(t) || !t) return t; var e = t[Symbol.toPrimitive]; if (void 0 !== e) { var i = e.call(t, r || "default"); if ("object" != _typeof(i)) return i; throw new TypeError("@@toPrimitive must return a primitive value."); } return ("string" === r ? String : Number)(t); }
var SEND_EMAIL = 'feedback/SEND_EMAIL';
var SEND_EMAIL_SUCCESS = 'feedback/SEND_EMAIL_SUCCESS';
var SEND_EMAIL_FAIL = 'feedback/SEND_EMAIL_FAIL';
var initialState = {
  loading: false,
  error: {}
};
function reducer() {
  var state = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : initialState;
  var action = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : {};
  switch (action.type) {
    case SEND_EMAIL:
      return _objectSpread(_objectSpread({}, state), {}, {
        loading: true,
        error: {}
      });
    case SEND_EMAIL_SUCCESS:
      return initialState;
    case SEND_EMAIL_FAIL:
      return _objectSpread(_objectSpread({}, state), {}, {
        loading: false,
        error: (0, _redux.getActionErrorObject)(action)
      });
    default:
      return _objectSpread(_objectSpread({}, state), {}, {
        error: {}
      });
  }
}
function sendEmail(data) {
  return {
    types: [SEND_EMAIL, SEND_EMAIL_SUCCESS, SEND_EMAIL_FAIL],
    promise: function promise(client) {
      return client.post('/feedback', {
        data: data
      });
    }
  };
}