"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.calculateTotalPrice = exports.applyDiscount = exports.annualDiscount = void 0;
exports.curFrequency = curFrequency;
exports.curUserCount = curUserCount;
exports.isPlanChanged = exports.isOnTrial = exports.isCharging = exports.humanizeLimit = exports.freqChanges = exports.downgradePending = exports.customerExists = void 0;
exports.isTrialCardSkipped = isTrialCardSkipped;
exports.planChanges = exports.perUserPrice = exports.isUnlimited = void 0;
exports.planVariant = planVariant;
exports.proratePriceForCreatorChange = proratePriceForCreatorChange;
exports.toMoneyPrecision = exports.showCancelSubsctiption = void 0;
exports.toShortFrequency = toShortFrequency;
var _findKey = _interopRequireDefault(require("lodash/findKey"));
var _dayjs = _interopRequireDefault(require("dayjs"));
var _urls = require("./urls");
var _account = require("./account");
function _interopRequireDefault(e) { return e && e.__esModule ? e : { default: e }; }
var toMoneyPrecision = exports.toMoneyPrecision = function toMoneyPrecision(cost) {
  if (!cost || Number.isInteger(cost)) return cost;
  return cost.toFixed(2);
};
var applyDiscount = exports.applyDiscount = function applyDiscount(fullCost, discountPercent) {
  return discountPercent ? fullCost * ((100 - discountPercent) / 100) : fullCost;
};
// price per month/year, input prices are in cents, output price in dollars
var calculateTotalPrice = exports.calculateTotalPrice = function calculateTotalPrice() {
  var plan = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : {};
  var userCount = arguments.length > 1 ? arguments[1] : undefined;
  var discountPercentApplied = arguments.length > 2 ? arguments[2] : undefined;
  if (plan.isCustomFreePlan) return 0;
  var discountPercent = discountPercentApplied || plan.discountPercentApplied || 0;
  var fullPrice = (applyDiscount(plan.basePrice, discountPercent) + plan.perUserPrice * Math.max(0, userCount - plan.freeUsers)) / 100;
  return fullPrice;
};
function proratePriceForCreatorChange(price, nextChargeDate, frequency) {
  var dailyPrice = (0, _account.getSubDailyPrice)(price, nextChargeDate, frequency);
  var daysLeft = (0, _dayjs.default)(nextChargeDate).diff((0, _dayjs.default)(), 'days') + 1;
  return Math.min(Math.round(dailyPrice * daysLeft), price);
}
var downgradePending = exports.downgradePending = function downgradePending(account) {
  return account.downPlanStatus === 'PENDING' && account.downPlanId === -1;
};
var isUnlimited = exports.isUnlimited = function isUnlimited(limit) {
  return !(limit < 99999 && limit !== null);
};
var humanizeLimit = exports.humanizeLimit = function humanizeLimit(limit) {
  return isUnlimited(limit) ? 'unlimited' : limit;
};
var isOnTrial = exports.isOnTrial = function isOnTrial(account) {
  return account && account.downPlanStatus === 'TRIAL';
};
var perUserPrice = exports.perUserPrice = function perUserPrice() {
  var plan = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : {};
  return plan.perUserPrice / 100;
};
var yearlyPrice = function yearlyPrice(plan, userCount) {
  return calculateTotalPrice(plan, userCount);
};
var monthlyPrice = function monthlyPrice(plan, userCount) {
  return calculateTotalPrice(plan, userCount);
};
var annualDiscount = exports.annualDiscount = function annualDiscount(plans, userCount) {
  return monthlyPrice(plans.month, userCount) * 12 - yearlyPrice(plans.year, userCount);
};
var isPlanChanged = exports.isPlanChanged = function isPlanChanged(account, userCount, frequency) {
  var newPlanId = arguments.length > 3 && arguments[3] !== undefined ? arguments[3] : null;
  return !(account && userCount === account.userCount && (account.planFrequency === frequency || account.planId && account.isSpecialPlan && frequency === 'special') && (newPlanId ? account.planId === newPlanId : true));
};
var freqChanges = exports.freqChanges = function freqChanges(account, newFreq) {
  var newPlan = arguments.length > 2 && arguments[2] !== undefined ? arguments[2] : {};
  return {
    upgrade: account.planFrequency !== newFreq,
    downgrade: false,
    unchanged: account.planFrequency === newFreq
  };
};
var getPlanAnnualPrice = function getPlanAnnualPrice(periodPrice, frequency) {
  return frequency === 'year' ? periodPrice : periodPrice * 12;
};

/**
 * This function describe the same pricig type (i.e:  premium1, premium2), but same/difference period.
 * So, if premium0 monthly and premium0 yearly will be treated as same pricing.
 * For the pricing key, please refer to plan_pricing table
 *
 * UPDATE: same plan id now will also treat as same pricing
 *
 * @param planData
 * @param currentPlanId
 * @param nextPlanId
 */
var isSamePricingPlan = function isSamePricingPlan(planData, currentPlanId, nextPlanId) {
  var currentPlan = planData.find(function (plan) {
    return plan.id === currentPlanId;
  });
  var nextPlan = planData.find(function (plan) {
    return plan.id === nextPlanId;
  });
  return (currentPlan === null || currentPlan === void 0 ? void 0 : currentPlan.id) === (nextPlan === null || nextPlan === void 0 ? void 0 : nextPlan.id) || (currentPlan === null || currentPlan === void 0 ? void 0 : currentPlan.pricingKey) + 'a' === (nextPlan === null || nextPlan === void 0 ? void 0 : nextPlan.pricingKey) || (currentPlan === null || currentPlan === void 0 ? void 0 : currentPlan.pricingKey) === (nextPlan === null || nextPlan === void 0 ? void 0 : nextPlan.pricingKey) + 'a';
};
var isCharging = exports.isCharging = function isCharging(account, planData, newPlan, newUserCount, newFreq) {
  var currentAnnualPrice = getPlanAnnualPrice(account.planFullPrice, account.planFrequency);
  var nextAnnualPrice = getPlanAnnualPrice(calculateTotalPrice(newPlan, newUserCount) * 100, newFreq);
  var isSamePricing = isSamePricingPlan(planData, account.planId, newPlan.id);
  return account && (account.downPlanStatus === 'TRIAL' || isSamePricing && account.planFrequency === 'month' && newFreq === 'year' || account.userCount < newUserCount || currentAnnualPrice < nextAnnualPrice || account.isCustomFreePlan || newPlan.isCustomFreePlan);
};
var planChanges = exports.planChanges = function planChanges(account, planData, newPlan, newUserCount, newFreq) {
  if (!account || !newPlan) return {};
  var isSamePlanId = account.planId === newPlan.id;
  var charge = isCharging(account, planData, newPlan, newUserCount, newFreq);
  return {
    upgrade: charge,
    charge: isCharging(account, planData, newPlan, newUserCount, newFreq),
    idSwitch: !isSamePlanId,
    downgrade: !charge
  };
};
var customerExists = exports.customerExists = function customerExists(account) {
  return !!(account && account.customer);
};
function curFrequency(account, plans, location, fields) {
  var query = location ? (0, _urls.parseQuery)(location.search) : {};
  var activeFrequency = plans.promo ? 'promo' : plans.special && (!!query.specialPlanId || account.isSpecialPlan) ? 'special' : account ? account.planFrequency : 'month';
  return fields.frequency.value || activeFrequency;
}
function planVariant(plans, pricingKey, location, fields) {
  var _fields$frequency;
  var query = location ? (0, _urls.parseQuery)(location.search) : {};
  var pricingPlan = pricingKey && (0, _findKey.default)(plans, function (o) {
    return o && o.pricingKey === pricingKey;
  });
  var specialPlan = plans.special && query.specialPlanId || !pricingPlan;
  var selectedPlan = plans.promo ? 'promo' : specialPlan ? 'special' : pricingPlan;
  return specialPlan ? selectedPlan : (fields === null || fields === void 0 ? void 0 : (_fields$frequency = fields.frequency) === null || _fields$frequency === void 0 ? void 0 : _fields$frequency.value) || selectedPlan;
}
function curUserCount(account, userCount, plan) {
  var digitsLimit = plan && plan.userLimit.toString().length;
  var activeUserCount = (account && plan ? account.userCount > plan.userLimit ? plan.userLimit : account.userCount : 1) || 0;
  var newUserCount = function newUserCount() {
    if (plan && plan.isCustomFreePlan) return plan.userLimit;
    if (!!userCount) {
      var count = Number(String(userCount).slice(0, digitsLimit));
      return plan && count > plan.userLimit ? plan.userLimit : count;
    } else return 0;
  };
  var minUserCount = plan ? Math.max(plan.minUsers, plan.freeUsers) : 0;
  return Math.max(minUserCount, newUserCount() || activeUserCount);
}
function isTrialCardSkipped(account, plans, cardSkipped, variant) {
  if (variant === 'special') {
    var isFirstSubscription = account && !account.haveBeenSubscribed;
    return (isOnTrial(account) || isFirstSubscription) && plans.special && plans.special.trialDays > 0 && !plans.special.trialCardRequired;
  } else {
    return plans.promo && !plans.promo.trialCardRequired && plans.promo.trialDays > 0 && cardSkipped;
  }
}
function toShortFrequency(frequency) {
  var ignoreSpecial = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : false;
  if (frequency === 'year') return 'yr';
  if (ignoreSpecial) return 'mo';
  return frequency.substr(0, 2);
}
var showCancelSubsctiption = exports.showCancelSubsctiption = function showCancelSubsctiption(_ref) {
  var account = _ref.account,
    notificationError = _ref.notificationError,
    updateCancelSubscriptionString = _ref.updateCancelSubscriptionString,
    setShowCancelSubsctiptionModal = _ref.setShowCancelSubsctiptionModal;
  if (account.downPlanStatus === 'PENDING' && account.isOwner) {
    notificationError({
      message: 'Please wait until pending changes take effect.'
    });
  }
  updateCancelSubscriptionString('');
  return setShowCancelSubsctiptionModal(true);
};