"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.displayRelativeTime = displayRelativeTime;
var _dayjs = _interopRequireDefault(require("dayjs"));
function _interopRequireDefault(e) { return e && e.__esModule ? e : { default: e }; }
function displayRelativeTime(date) {
  var formatStr = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : 'MMMM D, YYYY';
  var formatDate = (0, _dayjs.default)(date);
  if (!formatDate.isValid()) return date;
  if ((0, _dayjs.default)().isSame(formatDate, 'y')) {
    return formatDate.format('MMM D');
  }
  if ((0, _dayjs.default)().isAfter(formatDate, 'M')) return formatDate.format(formatStr);
  return (0, _dayjs.default)().to((0, _dayjs.default)(formatDate));
}