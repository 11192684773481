"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.acceptShare = acceptShare;
exports.default = reducer;
exports.isLoaded = isLoaded;
exports.load = load;
var _redux = require("utils/redux");
function _typeof(o) { "@babel/helpers - typeof"; return _typeof = "function" == typeof Symbol && "symbol" == typeof Symbol.iterator ? function (o) { return typeof o; } : function (o) { return o && "function" == typeof Symbol && o.constructor === Symbol && o !== Symbol.prototype ? "symbol" : typeof o; }, _typeof(o); }
function ownKeys(e, r) { var t = Object.keys(e); if (Object.getOwnPropertySymbols) { var o = Object.getOwnPropertySymbols(e); r && (o = o.filter(function (r) { return Object.getOwnPropertyDescriptor(e, r).enumerable; })), t.push.apply(t, o); } return t; }
function _objectSpread(e) { for (var r = 1; r < arguments.length; r++) { var t = null != arguments[r] ? arguments[r] : {}; r % 2 ? ownKeys(Object(t), !0).forEach(function (r) { _defineProperty(e, r, t[r]); }) : Object.getOwnPropertyDescriptors ? Object.defineProperties(e, Object.getOwnPropertyDescriptors(t)) : ownKeys(Object(t)).forEach(function (r) { Object.defineProperty(e, r, Object.getOwnPropertyDescriptor(t, r)); }); } return e; }
function _defineProperty(e, r, t) { return (r = _toPropertyKey(r)) in e ? Object.defineProperty(e, r, { value: t, enumerable: !0, configurable: !0, writable: !0 }) : e[r] = t, e; }
function _toPropertyKey(t) { var i = _toPrimitive(t, "string"); return "symbol" == _typeof(i) ? i : i + ""; }
function _toPrimitive(t, r) { if ("object" != _typeof(t) || !t) return t; var e = t[Symbol.toPrimitive]; if (void 0 !== e) { var i = e.call(t, r || "default"); if ("object" != _typeof(i)) return i; throw new TypeError("@@toPrimitive must return a primitive value."); } return ("string" === r ? String : Number)(t); }
var LOAD = 'redux-example/shareProcedure/LOAD';
var LOAD_SUCCESS = 'redux-example/shareProcedure/LOAD_SUCCESS';
var LOAD_FAIL = 'redux-example/shareProcedure/LOAD_FAIL';
var ACCEPT_SHARE = 'redux-example/shareProcedure/ACCEPT_SHARE';
var ACCEPT_SHARE_SUCCESS = 'redux-example/shareProcedure/ACCEPT_SHARE_SUCCESS';
var ACCEPT_SHARE_FAIL = 'redux-example/shareProcedure/ACCEPT_SHARE_FAIL';
function reducer() {
  var state = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : {};
  var action = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : {};
  switch (action.type) {
    case LOAD:
      return _objectSpread(_objectSpread({}, state), {}, {
        loading: true,
        pi: action.pi
      });
    case LOAD_SUCCESS:
      return _objectSpread(_objectSpread({}, state), {}, {
        loading: false,
        loaded: true,
        moduleShare: action.result.moduleShare,
        userExists: action.result.userExists,
        tutorialAuthorId: action.result.tutorialAuthorId
      });
    case LOAD_FAIL:
      return _objectSpread(_objectSpread({}, state), {}, {
        loading: false,
        loaded: false,
        error: (0, _redux.getActionError)(action, 'other'),
        errorStatusCode: action.errorStatusCode
      });
    case ACCEPT_SHARE:
      return _objectSpread(_objectSpread({}, state), {}, {
        acceptShareError: null,
        acceptShareErrorStatusCode: null
      });
    case ACCEPT_SHARE_FAIL:
      return _objectSpread(_objectSpread({}, state), {}, {
        acceptShareError: (0, _redux.getActionError)(action, 'other'),
        acceptShareErrorStatusCode: action.errorStatusCode
      });
    case ACCEPT_SHARE_SUCCESS:
      return _objectSpread(_objectSpread({}, state), {}, {
        playerURL: action.result.playerURL
      });
    default:
      return state;
  }
}
function isLoaded(globalState, pi) {
  return globalState.shareProcedure && globalState.shareProcedure.loaded && globalState.shareProcedure.pi === pi;
}
function load(pi) {
  return {
    types: [LOAD, LOAD_SUCCESS, LOAD_FAIL],
    promise: function promise(client) {
      return client.get('/loadShareProcedure', {
        params: {
          pi: pi
        }
      });
    },
    pi: pi
  };
}
function acceptShare(pi) {
  return {
    types: [ACCEPT_SHARE, ACCEPT_SHARE_SUCCESS, ACCEPT_SHARE_FAIL],
    promise: function promise(client) {
      return client.post('/acceptShare', {
        data: {
          pi: pi
        }
      });
    },
    pi: pi
  };
}