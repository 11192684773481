"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.tabs = exports.setPlanActiveTabIndex = exports.setPlanActivePlanIndex = exports.personalTabIndex = exports.nonProfitsTabIndex = exports.k12TabIndex = exports.highEducationTabIndex = exports.getPricingPlanName = exports.default = exports.businessTabIndex = void 0;
var _module = require("../../utils/module");
function _typeof(o) { "@babel/helpers - typeof"; return _typeof = "function" == typeof Symbol && "symbol" == typeof Symbol.iterator ? function (o) { return typeof o; } : function (o) { return o && "function" == typeof Symbol && o.constructor === Symbol && o !== Symbol.prototype ? "symbol" : typeof o; }, _typeof(o); }
function _defineProperty(e, r, t) { return (r = _toPropertyKey(r)) in e ? Object.defineProperty(e, r, { value: t, enumerable: !0, configurable: !0, writable: !0 }) : e[r] = t, e; }
function _toPropertyKey(t) { var i = _toPrimitive(t, "string"); return "symbol" == _typeof(i) ? i : i + ""; }
function _toPrimitive(t, r) { if ("object" != _typeof(t) || !t) return t; var e = t[Symbol.toPrimitive]; if (void 0 !== e) { var i = e.call(t, r || "default"); if ("object" != _typeof(i)) return i; throw new TypeError("@@toPrimitive must return a primitive value."); } return ("string" === r ? String : Number)(t); }
var tabs = exports.tabs = [{
  level: 'Personal',
  key: 'personal',
  planIndexes: [0]
}, {
  level: 'Business',
  key: 'business',
  planIndexes: [2, 3, 4],
  id: 'business'
}, {
  level: 'K-12',
  key: 'k12',
  planIndexes: [5, 6]
}, {
  level: 'Higher Ed',
  key: 'high_education',
  planIndexes: [7]
}, {
  level: 'Non-Profit',
  key: 'non-profits',
  planIndexes: [8]
}];
var businessTabIndex = exports.businessTabIndex = tabs.findIndex(function (tab) {
  return tab.key === 'business';
});
var personalTabIndex = exports.personalTabIndex = tabs.findIndex(function (tab) {
  return tab.key === 'personal';
});
var k12TabIndex = exports.k12TabIndex = tabs.findIndex(function (tab) {
  return tab.key === 'k12';
});
var highEducationTabIndex = exports.highEducationTabIndex = tabs.findIndex(function (tab) {
  return tab.key === 'high_education';
});
var nonProfitsTabIndex = exports.nonProfitsTabIndex = tabs.findIndex(function (tab) {
  return tab.key === 'non-profits';
});
var type = (0, _module.namespaceType)('redux/checkout/');
var SET_PLAN_ACTIVE_TAB_INDEX = type('SET_PLAN_ACTIVE_TAB_INDEX');
var SET_PLAN_ACTIVE_PLAN_INDEX = type('SET_PLAN_ACTIVE_PLAN_INDEX');
var initialState = {
  planActiveTabIndex: businessTabIndex,
  planActivePlanIndex: 0
};
var handlers = _defineProperty(_defineProperty({}, SET_PLAN_ACTIVE_TAB_INDEX, (0, _module.setterHandler)('planActiveTabIndex')), SET_PLAN_ACTIVE_PLAN_INDEX, (0, _module.setterHandler)('planActivePlanIndex'));
var _default = exports.default = (0, _module.createReducer)(handlers, initialState);
var setPlanActiveTabIndex = exports.setPlanActiveTabIndex = (0, _module.actionCreator)(SET_PLAN_ACTIVE_TAB_INDEX, 'planActiveTabIndex');
var setPlanActivePlanIndex = exports.setPlanActivePlanIndex = (0, _module.actionCreator)(SET_PLAN_ACTIVE_PLAN_INDEX, 'planActivePlanIndex');
var pricingPlanKeyToName = {
  'free': 'Free',
  'personal0': 'Basic',
  'premium0': 'Individual',
  'premium1': 'Team',
  'premium2': 'Enterprise',
  'educator': 'Educator',
  'districts': 'Districts',
  'high_education': 'Higher Education'
};
var getPricingPlanName = exports.getPricingPlanName = function getPricingPlanName(pricingKey) {
  return pricingPlanKeyToName[pricingKey];
};