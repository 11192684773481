"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.openDialog = exports.default = exports.confirmAction = exports.closeDialog = void 0;
var _module = require("../../utils/module");
function _typeof(o) { "@babel/helpers - typeof"; return _typeof = "function" == typeof Symbol && "symbol" == typeof Symbol.iterator ? function (o) { return typeof o; } : function (o) { return o && "function" == typeof Symbol && o.constructor === Symbol && o !== Symbol.prototype ? "symbol" : typeof o; }, _typeof(o); }
function ownKeys(e, r) { var t = Object.keys(e); if (Object.getOwnPropertySymbols) { var o = Object.getOwnPropertySymbols(e); r && (o = o.filter(function (r) { return Object.getOwnPropertyDescriptor(e, r).enumerable; })), t.push.apply(t, o); } return t; }
function _objectSpread(e) { for (var r = 1; r < arguments.length; r++) { var t = null != arguments[r] ? arguments[r] : {}; r % 2 ? ownKeys(Object(t), !0).forEach(function (r) { _defineProperty(e, r, t[r]); }) : Object.getOwnPropertyDescriptors ? Object.defineProperties(e, Object.getOwnPropertyDescriptors(t)) : ownKeys(Object(t)).forEach(function (r) { Object.defineProperty(e, r, Object.getOwnPropertyDescriptor(t, r)); }); } return e; }
function _defineProperty(e, r, t) { return (r = _toPropertyKey(r)) in e ? Object.defineProperty(e, r, { value: t, enumerable: !0, configurable: !0, writable: !0 }) : e[r] = t, e; }
function _toPropertyKey(t) { var i = _toPrimitive(t, "string"); return "symbol" == _typeof(i) ? i : i + ""; }
function _toPrimitive(t, r) { if ("object" != _typeof(t) || !t) return t; var e = t[Symbol.toPrimitive]; if (void 0 !== e) { var i = e.call(t, r || "default"); if ("object" != _typeof(i)) return i; throw new TypeError("@@toPrimitive must return a primitive value."); } return ("string" === r ? String : Number)(t); }
// it's double used in Admin component, better move it to the App

var type = (0, _module.namespaceType)('redux/dialog/');
var OPEN = type('OPEN');
var CLOSE = type('CLOSE');
var initialState = {
  open: false,
  props: {}
};
var handlers = _defineProperty(_defineProperty({}, OPEN, function (state, action) {
  return _objectSpread(_objectSpread({}, state), {}, {
    open: true,
    props: action.update ? _objectSpread(_objectSpread({}, state.props), action.props) : action.props || {}
  });
}), CLOSE, function (s) {
  return _objectSpread(_objectSpread({}, s), {}, {
    open: false
  });
});
var _default = exports.default = (0, _module.createReducer)(handlers, initialState);
var getLocalState = function getLocalState(state) {
  return state.dialog;
};
var openDialog = exports.openDialog = (0, _module.actionCreator)(OPEN, 'props', 'update');
var closeDialog = exports.closeDialog = function closeDialog() {
  return function (dispatch, getState) {
    var localState = getLocalState(getState());
    var listener = localState.props.onClose || localState.props.onCancel;
    if (listener) listener();
    dispatch({
      type: CLOSE
    });
  };
};
var confirmAction = exports.confirmAction = function confirmAction(text) {
  var _ref = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : {},
    _ref$confirmText = _ref.confirmText,
    confirmText = _ref$confirmText === void 0 ? 'Yes' : _ref$confirmText,
    onCancel = _ref.onCancel,
    onConfirm = _ref.onConfirm,
    onClose = _ref.onClose;
  return function (dispatch) {
    return new Promise(function (resolve) {
      dispatch(openDialog({
        text: text,
        buttons: [{
          text: 'Cancel',
          styleName: 'btn-fancy-grey',
          onClick: onCancel
        }, {
          text: confirmText,
          styleName: 'btn-fancy-danger',
          onClick: function onClick() {
            if (onConfirm) onConfirm();
            resolve();
          }
        }],
        onClose: onClose || onCancel
      }));
    });
  };
};