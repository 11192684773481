"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.pushGoogleAnalyticsEvent = pushGoogleAnalyticsEvent;
exports.trackHubspotEvent = trackHubspotEvent;
exports.trackSignup = trackSignup;
var urls = _interopRequireWildcard(require("./urls"));
var _export = require("../../static/assets/js/export");
function _getRequireWildcardCache(e) { if ("function" != typeof WeakMap) return null; var r = new WeakMap(), t = new WeakMap(); return (_getRequireWildcardCache = function _getRequireWildcardCache(e) { return e ? t : r; })(e); }
function _interopRequireWildcard(e, r) { if (!r && e && e.__esModule) return e; if (null === e || "object" != _typeof(e) && "function" != typeof e) return { default: e }; var t = _getRequireWildcardCache(r); if (t && t.has(e)) return t.get(e); var n = { __proto__: null }, a = Object.defineProperty && Object.getOwnPropertyDescriptor; for (var u in e) if ("default" !== u && {}.hasOwnProperty.call(e, u)) { var i = a ? Object.getOwnPropertyDescriptor(e, u) : null; i && (i.get || i.set) ? Object.defineProperty(n, u, i) : n[u] = e[u]; } return n.default = e, t && t.set(e, n), n; }
function _typeof(o) { "@babel/helpers - typeof"; return _typeof = "function" == typeof Symbol && "symbol" == typeof Symbol.iterator ? function (o) { return typeof o; } : function (o) { return o && "function" == typeof Symbol && o.constructor === Symbol && o !== Symbol.prototype ? "symbol" : typeof o; }, _typeof(o); }
function ownKeys(e, r) { var t = Object.keys(e); if (Object.getOwnPropertySymbols) { var o = Object.getOwnPropertySymbols(e); r && (o = o.filter(function (r) { return Object.getOwnPropertyDescriptor(e, r).enumerable; })), t.push.apply(t, o); } return t; }
function _objectSpread(e) { for (var r = 1; r < arguments.length; r++) { var t = null != arguments[r] ? arguments[r] : {}; r % 2 ? ownKeys(Object(t), !0).forEach(function (r) { _defineProperty(e, r, t[r]); }) : Object.getOwnPropertyDescriptors ? Object.defineProperties(e, Object.getOwnPropertyDescriptors(t)) : ownKeys(Object(t)).forEach(function (r) { Object.defineProperty(e, r, Object.getOwnPropertyDescriptor(t, r)); }); } return e; }
function _defineProperty(e, r, t) { return (r = _toPropertyKey(r)) in e ? Object.defineProperty(e, r, { value: t, enumerable: !0, configurable: !0, writable: !0 }) : e[r] = t, e; }
function _toPropertyKey(t) { var i = _toPrimitive(t, "string"); return "symbol" == _typeof(i) ? i : i + ""; }
function _toPrimitive(t, r) { if ("object" != _typeof(t) || !t) return t; var e = t[Symbol.toPrimitive]; if (void 0 !== e) { var i = e.call(t, r || "default"); if ("object" != _typeof(i)) return i; throw new TypeError("@@toPrimitive must return a primitive value."); } return ("string" === r ? String : Number)(t); }
var getGoogleAnalyticsAPIPromise;
function getGoogleAnalyticsAPI() {
  if (!getGoogleAnalyticsAPIPromise) {
    getGoogleAnalyticsAPIPromise = new Promise(function (resolve) {
      var check = function check() {
        if (window.dataLayer) {
          resolve(window.dataLayer);
          return;
        }
        setTimeout(check, 100);
      };
      check();
    });
  }
  return getGoogleAnalyticsAPIPromise;
}
function trackHubspotEvent(name, data) {
  if (!__CLIENT__) return;
  if (!(0, _export.isCookieConsentAccepted)('analytics.hubspot_tracking')) return;

  // Keep also if config.hubspotId is not defined,
  // since the backend service could do more
  // than just notying hubspot

  var params = _objectSpread({
    eventId: name
  }, typeof data === 'number' ? {
    // data must be the "contact revenue" (optional)
    eventRevenue: data
  } : {});
  var request = new XMLHttpRequest();
  request.open('GET', urls.api('/user/hubspot/trackEvent', params));
  var credentialless = window.location.href.match(/[?&]credentialless=true(&|$|#)/);
  if (credentialless) {
    request.setRequestHeader('iorad-credentialless', 'true');
    request.withCredentials = false;
  }
  request.send();
  console.log('trackHubspotEvent', params);
}
function pushGoogleAnalyticsEvent(name) {
  var additionalFields = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : {};
  if (!__CLIENT__) return;
  if (!(0, _export.isCookieConsentAccepted)('analytics.google_analytics_tracking')) return;
  getGoogleAnalyticsAPI().then(function (api) {
    api.push(_objectSpread({
      'event': name
    }, additionalFields));
  });
}
function trackSignup() {
  pushGoogleAnalyticsEvent('signUpSuccess');
  if (window.qp) window.qp('track', 'Signed Up'); // quora
  if (window.fbq) window.fbq('track', 'CompleteRegistration'); // facebook
  if (window.twttr && window.twttr.conversion) {
    window.twttr.conversion.trackPid('nxvyw', {
      tw_sale_amount: 0,
      tw_order_quantity: 0
    }); // twitter
  }
}