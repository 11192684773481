"use strict";

// dynamically set env from the browser (through window.__processEnv var)
var processEnv = {};

// can use processEnv from the client dynamically
if ('undefined' !== typeof window) {
  processEnv = window.__processEnv || {};
  //console.log('processEnv', processEnv);
} else {
  processEnv = process.env;
}
module.exports = processEnv;