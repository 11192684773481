"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
var _customDomain = _interopRequireDefault(require("./custom-domain"));
var _redux = require("redux");
function _interopRequireDefault(e) { return e && e.__esModule ? e : { default: e }; }
var reducer = (0, _redux.combineReducers)({
  customDomain: _customDomain.default
});
var _default = exports.default = reducer;
module.exports = exports.default;