"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.getQuery = exports.getLocation = void 0;
var _urls = require("../../utils/urls");
// The redurer itself is added by connected-react-router library

var getLocalState = function getLocalState(state) {
  return state.router;
};
var getLocation = exports.getLocation = function getLocation(state) {
  return getLocalState(state).location;
};
var getQuery = exports.getQuery = function getQuery(state) {
  return (0, _urls.parseQuery)(getLocation(state).search);
};