"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
var _bundleLoader = _interopRequireDefault(require("./bundleLoader"));
var _redux = require("redux");
function _interopRequireDefault(e) { return e && e.__esModule ? e : { default: e }; }
/*
  State that cannot be converted to JSON, such as objects and functions
  This state will not be transferred from the server rendering
*/

var reducer = (0, _redux.combineReducers)({
  bundleLoader: _bundleLoader.default
});
var _default = exports.default = reducer;
module.exports = exports.default;