"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.sendPlanInvitations = exports.default = void 0;
var _module = require("../../utils/module");
function _typeof(o) { "@babel/helpers - typeof"; return _typeof = "function" == typeof Symbol && "symbol" == typeof Symbol.iterator ? function (o) { return typeof o; } : function (o) { return o && "function" == typeof Symbol && o.constructor === Symbol && o !== Symbol.prototype ? "symbol" : typeof o; }, _typeof(o); }
function ownKeys(e, r) { var t = Object.keys(e); if (Object.getOwnPropertySymbols) { var o = Object.getOwnPropertySymbols(e); r && (o = o.filter(function (r) { return Object.getOwnPropertyDescriptor(e, r).enumerable; })), t.push.apply(t, o); } return t; }
function _objectSpread(e) { for (var r = 1; r < arguments.length; r++) { var t = null != arguments[r] ? arguments[r] : {}; r % 2 ? ownKeys(Object(t), !0).forEach(function (r) { _defineProperty(e, r, t[r]); }) : Object.getOwnPropertyDescriptors ? Object.defineProperties(e, Object.getOwnPropertyDescriptors(t)) : ownKeys(Object(t)).forEach(function (r) { Object.defineProperty(e, r, Object.getOwnPropertyDescriptor(t, r)); }); } return e; }
function _defineProperty(e, r, t) { return (r = _toPropertyKey(r)) in e ? Object.defineProperty(e, r, { value: t, enumerable: !0, configurable: !0, writable: !0 }) : e[r] = t, e; }
function _toPropertyKey(t) { var i = _toPrimitive(t, "string"); return "symbol" == _typeof(i) ? i : i + ""; }
function _toPrimitive(t, r) { if ("object" != _typeof(t) || !t) return t; var e = t[Symbol.toPrimitive]; if (void 0 !== e) { var i = e.call(t, r || "default"); if ("object" != _typeof(i)) return i; throw new TypeError("@@toPrimitive must return a primitive value."); } return ("string" === r ? String : Number)(t); }
var type = (0, _module.namespaceType)('redux/planInvitations/');
var SEND_PLAN_INVITATIONS = type('SEND_PLAN_INVITATIONS');
var SEND_PLAN_INVITATIONS_SUCCESS = type('SEND_PLAN_INVITATIONS_SUCCESS');
var SEND_PLAN_INVITATIONS_FAIL = type('SEND_PLAN_INVITATIONS_FAIL');
var initialState = {
  error: ''
};
var handlers = _defineProperty(_defineProperty(_defineProperty({}, SEND_PLAN_INVITATIONS, function (state) {
  return _objectSpread({}, state);
}), SEND_PLAN_INVITATIONS_SUCCESS, function (state) {
  return _objectSpread({}, state);
}), SEND_PLAN_INVITATIONS_FAIL, function (state, action) {
  return _objectSpread(_objectSpread({}, state), {}, {
    error: action.error
  });
});
var _default = exports.default = (0, _module.createReducer)(handlers, initialState);
var sendPlanInvitations = exports.sendPlanInvitations = function sendPlanInvitations(_ref) {
  var planId = _ref.planId,
    emailList = _ref.emailList,
    _ref$isPublic = _ref.isPublic,
    isPublic = _ref$isPublic === void 0 ? false : _ref$isPublic;
  return {
    types: [SEND_PLAN_INVITATIONS, SEND_PLAN_INVITATIONS_SUCCESS, SEND_PLAN_INVITATIONS_FAIL],
    promise: function promise(client) {
      return client.post('/admin/sendPlanInvitations', {
        data: {
          planId: planId,
          emailList: emailList,
          isPublic: isPublic
        }
      });
    }
  };
};