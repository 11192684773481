"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.acceptSpecial = acceptSpecial;
exports.default = reducer;
exports.isLoaded = isLoaded;
exports.load = load;
var _redux = require("utils/redux");
function _typeof(o) { "@babel/helpers - typeof"; return _typeof = "function" == typeof Symbol && "symbol" == typeof Symbol.iterator ? function (o) { return typeof o; } : function (o) { return o && "function" == typeof Symbol && o.constructor === Symbol && o !== Symbol.prototype ? "symbol" : typeof o; }, _typeof(o); }
function ownKeys(e, r) { var t = Object.keys(e); if (Object.getOwnPropertySymbols) { var o = Object.getOwnPropertySymbols(e); r && (o = o.filter(function (r) { return Object.getOwnPropertyDescriptor(e, r).enumerable; })), t.push.apply(t, o); } return t; }
function _objectSpread(e) { for (var r = 1; r < arguments.length; r++) { var t = null != arguments[r] ? arguments[r] : {}; r % 2 ? ownKeys(Object(t), !0).forEach(function (r) { _defineProperty(e, r, t[r]); }) : Object.getOwnPropertyDescriptors ? Object.defineProperties(e, Object.getOwnPropertyDescriptors(t)) : ownKeys(Object(t)).forEach(function (r) { Object.defineProperty(e, r, Object.getOwnPropertyDescriptor(t, r)); }); } return e; }
function _defineProperty(e, r, t) { return (r = _toPropertyKey(r)) in e ? Object.defineProperty(e, r, { value: t, enumerable: !0, configurable: !0, writable: !0 }) : e[r] = t, e; }
function _toPropertyKey(t) { var i = _toPrimitive(t, "string"); return "symbol" == _typeof(i) ? i : i + ""; }
function _toPrimitive(t, r) { if ("object" != _typeof(t) || !t) return t; var e = t[Symbol.toPrimitive]; if (void 0 !== e) { var i = e.call(t, r || "default"); if ("object" != _typeof(i)) return i; throw new TypeError("@@toPrimitive must return a primitive value."); } return ("string" === r ? String : Number)(t); }
var LOAD = 'redux-example/specialProcedure/LOAD';
var LOAD_SUCCESS = 'redux-example/specialProcedure/LOAD_SUCCESS';
var LOAD_FAIL = 'redux-example/specialProcedure/LOAD_FAIL';
var ACCEPT_SPECIAL = 'redux-example/shareProcedure/ACCEPT_SPECIAL';
var ACCEPT_SPECIAL_SUCCESS = 'redux-example/shareProcedure/ACCEPT_SPECIAL_SUCCESS';
var ACCEPT_SPECIAL_FAIL = 'redux-example/shareProcedure/ACCEPT_SPECIAL_FAIL';
function reducer() {
  var state = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : {};
  var action = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : {};
  switch (action.type) {
    case LOAD:
      return _objectSpread(_objectSpread({}, state), {}, {
        loading: true,
        spi: action.spi
      });
    case LOAD_SUCCESS:
      return _objectSpread(_objectSpread({}, state), {}, {
        loading: false,
        loaded: true,
        moduleSpecial: action.result.moduleSpecial,
        userExists: action.result.userExists
      });
    case LOAD_FAIL:
      return _objectSpread(_objectSpread({}, state), {}, {
        loading: false,
        loaded: false,
        error: (0, _redux.getActionError)(action, 'other'),
        errorStatusCode: action.errorStatusCode
      });
    case ACCEPT_SPECIAL:
      return _objectSpread(_objectSpread({}, state), {}, {
        acceptSpecialError: null,
        acceptSpecialErrorStatusCode: null
      });
    case ACCEPT_SPECIAL_FAIL:
      return _objectSpread(_objectSpread({}, state), {}, {
        acceptSpecialError: (0, _redux.getActionError)(action, 'other'),
        acceptSpecialErrorStatusCode: action.errorStatusCode
      });
    case ACCEPT_SPECIAL_SUCCESS:
      return _objectSpread(_objectSpread({}, state), {}, {
        planId: action.result.planId
      });
    default:
      return state;
  }
}
function isLoaded(globalState, spi) {
  return globalState.specialProcedure && globalState.specialProcedure.loaded && globalState.specialProcedure.spi === spi;
}
function load(spi) {
  return {
    types: [LOAD, LOAD_SUCCESS, LOAD_FAIL],
    promise: function promise(client) {
      return client.get('/loadSpecialProcedure', {
        params: {
          spi: spi
        }
      });
    },
    spi: spi
  };
}
function acceptSpecial(spi, publicPlanId) {
  return {
    types: [ACCEPT_SPECIAL, ACCEPT_SPECIAL_SUCCESS, ACCEPT_SPECIAL_FAIL],
    promise: function promise(client) {
      return client.post('/acceptSpecial', {
        data: {
          spi: spi,
          publicPlanId: publicPlanId
        }
      });
    },
    spi: spi
  };
}