"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.actionCreators = void 0;
exports.default = social;
var _module = require("../../utils/module");
function _typeof(o) { "@babel/helpers - typeof"; return _typeof = "function" == typeof Symbol && "symbol" == typeof Symbol.iterator ? function (o) { return typeof o; } : function (o) { return o && "function" == typeof Symbol && o.constructor === Symbol && o !== Symbol.prototype ? "symbol" : typeof o; }, _typeof(o); }
function ownKeys(e, r) { var t = Object.keys(e); if (Object.getOwnPropertySymbols) { var o = Object.getOwnPropertySymbols(e); r && (o = o.filter(function (r) { return Object.getOwnPropertyDescriptor(e, r).enumerable; })), t.push.apply(t, o); } return t; }
function _objectSpread(e) { for (var r = 1; r < arguments.length; r++) { var t = null != arguments[r] ? arguments[r] : {}; r % 2 ? ownKeys(Object(t), !0).forEach(function (r) { _defineProperty(e, r, t[r]); }) : Object.getOwnPropertyDescriptors ? Object.defineProperties(e, Object.getOwnPropertyDescriptors(t)) : ownKeys(Object(t)).forEach(function (r) { Object.defineProperty(e, r, Object.getOwnPropertyDescriptor(t, r)); }); } return e; }
function _defineProperty(e, r, t) { return (r = _toPropertyKey(r)) in e ? Object.defineProperty(e, r, { value: t, enumerable: !0, configurable: !0, writable: !0 }) : e[r] = t, e; }
function _toPropertyKey(t) { var i = _toPrimitive(t, "string"); return "symbol" == _typeof(i) ? i : i + ""; }
function _toPrimitive(t, r) { if ("object" != _typeof(t) || !t) return t; var e = t[Symbol.toPrimitive]; if (void 0 !== e) { var i = e.call(t, r || "default"); if ("object" != _typeof(i)) return i; throw new TypeError("@@toPrimitive must return a primitive value."); } return ("string" === r ? String : Number)(t); }
var type = (0, _module.namespaceType)('redux/social/');
var API_REQUEST = type('API_REQUEST');
var API_REQUEST_SUCCESS = type('API_REQUEST_SUCCESS');
var API_REQUEST_FAIL = type('API_REQUEST_FAIL');
var SCORES_REQUEST = type('SCORES_REQUEST');
var SCORES_REQUEST_SUCCESS = type('SCORES_REQUEST_SUCCESS');
var SCORES_REQUEST_FAIL = type('SCORES_REQUEST_FAIL');
var IORADICALS_REQUEST = type('IORADICALS_REQUEST');
var IORADICALS_REQUEST_SUCCESS = type('IORADICALS_REQUEST_SUCCESS');
var IORADICALS_REQUEST_FAIL = type('IORADICALS_REQUEST_FAIL');
var SET_IS_FULL_SIZE = type('SET_IS_FULL_SIZE');
var SET_IS_SMALL_SCREEN = type('SET_IS_SMALL_SCREEN');
var initialState = {
  isFullSize: false,
  isFullSizeChangeTimeStamp: null,
  isSmallScreen: false,
  scores: {},
  ioradicals: {}
};
function social() {
  var state = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : initialState;
  var action = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : {};
  switch (action.type) {
    case SET_IS_FULL_SIZE:
      return _objectSpread(_objectSpread({}, state), {}, {
        isFullSize: action.isFullSize,
        isFullSizeChangeTimeStamp: Date.now()
      });
    case SET_IS_SMALL_SCREEN:
      return _objectSpread(_objectSpread({}, state), {}, {
        isSmallScreen: action.isSmallScreen
      });
    case SCORES_REQUEST:
      return _objectSpread(_objectSpread({}, state), {}, {
        scoresLoading: true
      });
    case SCORES_REQUEST_SUCCESS:
      return _objectSpread(_objectSpread({}, state), {}, {
        scoresLoading: false,
        scores: _objectSpread(_objectSpread({}, state.scores), {}, _defineProperty({}, action.ioradicalId, action.result))
      });
    case SCORES_REQUEST_FAIL:
      return _objectSpread(_objectSpread({}, state), {}, {
        scoresLoading: false
      });
    case IORADICALS_REQUEST:
      return _objectSpread(_objectSpread({}, state), {}, {
        ioradicalsLoading: true
      });
    case IORADICALS_REQUEST_SUCCESS:
      return _objectSpread(_objectSpread({}, state), {}, {
        ioradicalsLoading: false,
        ioradicals: _objectSpread(_objectSpread({}, state.ioradicals), {}, _defineProperty({}, action.ioradicalId, action.result))
      });
    case IORADICALS_REQUEST_FAIL:
      return _objectSpread(_objectSpread({}, state), {}, {
        ioradicalsLoading: false
      });
    default:
      return state;
  }
}
var actionCreators = exports.actionCreators = {
  listProducts: function listProducts(data) {
    return {
      types: [API_REQUEST, API_REQUEST_SUCCESS, API_REQUEST_FAIL],
      promise: function promise(client) {
        return client.post('/social/listProducts', {
          data: _objectSpread({
            _: Date.now()
          }, data)
        });
      }
    };
  },
  listProductsSimple: function listProductsSimple(params) {
    return {
      types: [API_REQUEST, API_REQUEST_SUCCESS, API_REQUEST_FAIL],
      promise: function promise(client) {
        return client.get('/social/listProductsSimple', {
          params: _objectSpread({
            _: Date.now()
          }, params)
        });
      }
    };
  },
  listProductWaitList: function listProductWaitList(params) {
    return {
      types: [API_REQUEST, API_REQUEST_SUCCESS, API_REQUEST_FAIL],
      promise: function promise(client) {
        return client.get('/social/listProductWaitList', {
          params: _objectSpread({
            _: Date.now()
          }, params)
        });
      }
    };
  },
  createProductWait: function createProductWait(data) {
    return {
      types: [API_REQUEST, API_REQUEST_SUCCESS, API_REQUEST_FAIL],
      promise: function promise(client) {
        return client.post('/social/createProductWait', {
          data: data
        });
      }
    };
  },
  removeProductWait: function removeProductWait(data) {
    return {
      types: [API_REQUEST, API_REQUEST_SUCCESS, API_REQUEST_FAIL],
      promise: function promise(client) {
        return client.post('/social/removeProductWait', {
          data: data
        });
      }
    };
  },
  createProductWaitVote: function createProductWaitVote(data) {
    return {
      types: [API_REQUEST, API_REQUEST_SUCCESS, API_REQUEST_FAIL],
      promise: function promise(client) {
        return client.post('/social/createProductWaitVote', {
          data: data
        });
      }
    };
  },
  removeProductWaitVote: function removeProductWaitVote(data) {
    return {
      types: [API_REQUEST, API_REQUEST_SUCCESS, API_REQUEST_FAIL],
      promise: function promise(client) {
        return client.post('/social/removeProductWaitVote', {
          data: data
        });
      }
    };
  },
  getProductUrlInfo: function getProductUrlInfo(params) {
    return {
      types: [API_REQUEST, API_REQUEST_SUCCESS, API_REQUEST_FAIL],
      promise: function promise(client) {
        return client.get('/social/getProductUrlInfo', {
          params: params
        });
      }
    };
  },
  getProductLeaderboard: function getProductLeaderboard(params) {
    return {
      types: [API_REQUEST, API_REQUEST_SUCCESS, API_REQUEST_FAIL],
      promise: function promise(client) {
        return client.get('/social/getProductLeaderboard', {
          params: params
        });
      }
    };
  },
  createQuestion: function createQuestion(data) {
    return {
      types: [API_REQUEST, API_REQUEST_SUCCESS, API_REQUEST_FAIL],
      promise: function promise(client) {
        return client.post('/social/createQuestion', {
          data: data
        });
      }
    };
  },
  editQuestion: function editQuestion(data) {
    return {
      types: [API_REQUEST, API_REQUEST_SUCCESS, API_REQUEST_FAIL],
      promise: function promise(client) {
        return client.post('/social/editQuestion', {
          data: data
        });
      }
    };
  },
  removeQuestion: function removeQuestion(data) {
    return {
      types: [API_REQUEST, API_REQUEST_SUCCESS, API_REQUEST_FAIL],
      promise: function promise(client) {
        return client.post('/social/removeQuestion', {
          data: data
        });
      }
    };
  },
  listQuestions: function listQuestions(params) {
    return {
      types: [API_REQUEST, API_REQUEST_SUCCESS, API_REQUEST_FAIL],
      promise: function promise(client) {
        return client.post('/social/listQuestions', {
          data: _objectSpread({
            _: Date.now()
          }, params)
        });
      }
    };
  },
  createQuestionView: function createQuestionView(data) {
    return {
      types: [API_REQUEST, API_REQUEST_SUCCESS, API_REQUEST_FAIL],
      promise: function promise(client) {
        return client.post('/social/createQuestionView', {
          data: data
        });
      }
    };
  },
  createQuestionAsk: function createQuestionAsk(data) {
    return {
      types: [API_REQUEST, API_REQUEST_SUCCESS, API_REQUEST_FAIL],
      promise: function promise(client) {
        return client.post('/social/createQuestionAsk', {
          data: data
        });
      }
    };
  },
  removeQuestionAsk: function removeQuestionAsk(data) {
    return {
      types: [API_REQUEST, API_REQUEST_SUCCESS, API_REQUEST_FAIL],
      promise: function promise(client) {
        return client.post('/social/removeQuestionAsk', {
          data: data
        });
      }
    };
  },
  detectQuestionLanguage: function detectQuestionLanguage(data) {
    return {
      types: [API_REQUEST, API_REQUEST_SUCCESS, API_REQUEST_FAIL],
      promise: function promise(client) {
        return client.post('/social/detectQuestionLanguage', {
          data: data
        });
      }
    };
  },
  createAnswer: function createAnswer(data) {
    return {
      types: [API_REQUEST, API_REQUEST_SUCCESS, API_REQUEST_FAIL],
      promise: function promise(client) {
        return client.post('/social/createAnswer', {
          data: data
        });
      }
    };
  },
  removeAnswer: function removeAnswer(data) {
    return {
      types: [API_REQUEST, API_REQUEST_SUCCESS, API_REQUEST_FAIL],
      promise: function promise(client) {
        return client.post('/social/removeAnswer', {
          data: data
        });
      }
    };
  },
  createAnswerView: function createAnswerView(data) {
    return {
      types: [API_REQUEST, API_REQUEST_SUCCESS, API_REQUEST_FAIL],
      promise: function promise(client) {
        return client.post('/social/createAnswerView', {
          data: data
        });
      }
    };
  },
  createAnswerLike: function createAnswerLike(data) {
    return {
      types: [API_REQUEST, API_REQUEST_SUCCESS, API_REQUEST_FAIL],
      promise: function promise(client) {
        return client.post('/social/createAnswerLike', {
          data: data
        });
      }
    };
  },
  removeAnswerLike: function removeAnswerLike(data) {
    return {
      types: [API_REQUEST, API_REQUEST_SUCCESS, API_REQUEST_FAIL],
      promise: function promise(client) {
        return client.post('/social/removeAnswerLike', {
          data: data
        });
      }
    };
  },
  createAnswerDislike: function createAnswerDislike(data) {
    return {
      types: [API_REQUEST, API_REQUEST_SUCCESS, API_REQUEST_FAIL],
      promise: function promise(client) {
        return client.post('/social/createAnswerDislike', {
          data: data
        });
      }
    };
  },
  removeAnswerDislike: function removeAnswerDislike(data) {
    return {
      types: [API_REQUEST, API_REQUEST_SUCCESS, API_REQUEST_FAIL],
      promise: function promise(client) {
        return client.post('/social/removeAnswerDislike', {
          data: data
        });
      }
    };
  },
  createAnswerFinish: function createAnswerFinish(data) {
    return {
      types: [API_REQUEST, API_REQUEST_SUCCESS, API_REQUEST_FAIL],
      promise: function promise(client) {
        return client.post('/social/createAnswerFinish', {
          data: data
        });
      }
    };
  },
  validateTutorialForAnswer: function validateTutorialForAnswer(params) {
    return {
      types: [API_REQUEST, API_REQUEST_SUCCESS, API_REQUEST_FAIL],
      promise: function promise(client) {
        return client.get('/social/validateTutorialForAnswer', {
          params: params
        });
      }
    };
  },
  makeTutorialPublic: function makeTutorialPublic(data) {
    return {
      types: [API_REQUEST, API_REQUEST_SUCCESS, API_REQUEST_FAIL],
      promise: function promise(client) {
        return client.post('/social/makeTutorialPublic', {
          data: data
        });
      }
    };
  },
  requestMakeTutorialPublic: function requestMakeTutorialPublic(data) {
    return {
      types: [API_REQUEST, API_REQUEST_SUCCESS, API_REQUEST_FAIL],
      promise: function promise(client) {
        return client.post('/social/requestMakeTutorialPublic', {
          data: data
        });
      }
    };
  },
  changeQuestionVerify: function changeQuestionVerify(data) {
    return {
      types: [API_REQUEST, API_REQUEST_SUCCESS, API_REQUEST_FAIL],
      promise: function promise(client) {
        return client.post('/social/changeQuestionVerify', {
          data: data
        });
      }
    };
  },
  removeQuestionVerify: function removeQuestionVerify(data) {
    return {
      types: [API_REQUEST, API_REQUEST_SUCCESS, API_REQUEST_FAIL],
      promise: function promise(client) {
        return client.post('/social/removeQuestionVerify', {
          data: data
        });
      }
    };
  },
  createProductFollow: function createProductFollow(data) {
    return {
      types: [API_REQUEST, API_REQUEST_SUCCESS, API_REQUEST_FAIL],
      promise: function promise(client) {
        return client.post('/social/createProductFollow', {
          data: data
        });
      }
    };
  },
  removeProductFollow: function removeProductFollow(data) {
    return {
      types: [API_REQUEST, API_REQUEST_SUCCESS, API_REQUEST_FAIL],
      promise: function promise(client) {
        return client.post('/social/removeProductFollow', {
          data: data
        });
      }
    };
  },
  listIoradicals: function listIoradicals(data) {
    return {
      types: [API_REQUEST, API_REQUEST_SUCCESS, API_REQUEST_FAIL],
      promise: function promise(client) {
        return client.post('/social/listIoradicals', {
          data: _objectSpread({
            _: Date.now()
          }, data)
        });
      }
    };
  },
  getIoradicalDetails: function getIoradicalDetails(params) {
    return {
      types: [IORADICALS_REQUEST, IORADICALS_REQUEST_SUCCESS, IORADICALS_REQUEST_FAIL],
      promise: function promise(client) {
        return client.get('/social/getIoradicalDetails', {
          params: _objectSpread({
            _: Date.now()
          }, params)
        });
      },
      ioradicalId: params.ioradicalId
    };
  },
  listIoradicalScoreChanges: function listIoradicalScoreChanges(params) {
    return {
      types: [SCORES_REQUEST, SCORES_REQUEST_SUCCESS, SCORES_REQUEST_FAIL],
      promise: function promise(client) {
        return client.get('/social/listIoradicalScoreChanges', {
          params: _objectSpread({
            _: Date.now()
          }, params)
        });
      },
      ioradicalId: params.ioradicalId
    };
  },
  createIoradicalFollow: function createIoradicalFollow(data) {
    return {
      types: [API_REQUEST, API_REQUEST_SUCCESS, API_REQUEST_FAIL],
      promise: function promise(client) {
        return client.post('/social/createIoradicalFollow', {
          data: data
        });
      }
    };
  },
  removeIoradicalFollow: function removeIoradicalFollow(data) {
    return {
      types: [API_REQUEST, API_REQUEST_SUCCESS, API_REQUEST_FAIL],
      promise: function promise(client) {
        return client.post('/social/removeIoradicalFollow', {
          data: data
        });
      }
    };
  },
  setIsFullSize: function setIsFullSize(isFullSize) {
    return {
      type: SET_IS_FULL_SIZE,
      isFullSize: isFullSize
    };
  },
  setIsSmallScreen: function setIsSmallScreen(isSmallScreen) {
    return {
      type: SET_IS_SMALL_SCREEN,
      isSmallScreen: isSmallScreen
    };
  },
  getListFilters: function getListFilters() {
    return {
      types: [API_REQUEST, API_REQUEST_SUCCESS, API_REQUEST_FAIL],
      promise: function promise(client) {
        return client.get('/social/questions/filters/list');
      }
    };
  },
  addFilter: function addFilter(data) {
    return {
      types: [API_REQUEST, API_REQUEST_SUCCESS, API_REQUEST_FAIL],
      promise: function promise(client) {
        return client.post('/social/questions/filters/add', {
          data: data
        });
      }
    };
  },
  editFilter: function editFilter(data) {
    return {
      types: [API_REQUEST, API_REQUEST_SUCCESS, API_REQUEST_FAIL],
      promise: function promise(client) {
        return client.post('/social/questions/filters/modify', {
          data: data
        });
      }
    };
  },
  deleteFilter: function deleteFilter(data) {
    return {
      types: [API_REQUEST, API_REQUEST_SUCCESS, API_REQUEST_FAIL],
      promise: function promise(client) {
        return client.post('/social/questions/filters/remove', {
          data: data
        });
      }
    };
  }
};