"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.checkSAMLCertificate = checkSAMLCertificate;
exports.checkSAMLDomain = checkSAMLDomain;
exports.default = reducer;
exports.deleteGoogleAnalyticsSettings = deleteGoogleAnalyticsSettings;
exports.deleteSAMLSettings = deleteSAMLSettings;
exports.getGoogleAnalyticsSettings = getGoogleAnalyticsSettings;
exports.getSAMLLoginURL = getSAMLLoginURL;
exports.getSAMLSettings = getSAMLSettings;
exports.saveGoogleAnalyticsSettings = saveGoogleAnalyticsSettings;
exports.saveSAMLSettings = saveSAMLSettings;
function _typeof(o) { "@babel/helpers - typeof"; return _typeof = "function" == typeof Symbol && "symbol" == typeof Symbol.iterator ? function (o) { return typeof o; } : function (o) { return o && "function" == typeof Symbol && o.constructor === Symbol && o !== Symbol.prototype ? "symbol" : typeof o; }, _typeof(o); }
function ownKeys(e, r) { var t = Object.keys(e); if (Object.getOwnPropertySymbols) { var o = Object.getOwnPropertySymbols(e); r && (o = o.filter(function (r) { return Object.getOwnPropertyDescriptor(e, r).enumerable; })), t.push.apply(t, o); } return t; }
function _objectSpread(e) { for (var r = 1; r < arguments.length; r++) { var t = null != arguments[r] ? arguments[r] : {}; r % 2 ? ownKeys(Object(t), !0).forEach(function (r) { _defineProperty(e, r, t[r]); }) : Object.getOwnPropertyDescriptors ? Object.defineProperties(e, Object.getOwnPropertyDescriptors(t)) : ownKeys(Object(t)).forEach(function (r) { Object.defineProperty(e, r, Object.getOwnPropertyDescriptor(t, r)); }); } return e; }
function _defineProperty(e, r, t) { return (r = _toPropertyKey(r)) in e ? Object.defineProperty(e, r, { value: t, enumerable: !0, configurable: !0, writable: !0 }) : e[r] = t, e; }
function _toPropertyKey(t) { var i = _toPrimitive(t, "string"); return "symbol" == _typeof(i) ? i : i + ""; }
function _toPrimitive(t, r) { if ("object" != _typeof(t) || !t) return t; var e = t[Symbol.toPrimitive]; if (void 0 !== e) { var i = e.call(t, r || "default"); if ("object" != _typeof(i)) return i; throw new TypeError("@@toPrimitive must return a primitive value."); } return ("string" === r ? String : Number)(t); }
var GET_SAML_SETTINGS = 'redux/settings/GET_SAML_SETTINGS';
var GET_SAML_SETTINGS_SUCCESS = 'redux/settings/GET_SAML_SETTINGS_SUCCESS';
var GET_SAML_SETTINGS_FAIL = 'redux/settings/GET_SAML_SETTINGS_FAIL';
var SAVE_SAML_SETTINGS = 'redux/settings/SAVE_SAML_SETTINGS';
var SAVE_SAML_SETTINGS_SUCCESS = 'redux/settings/SAVE_SAML_SETTINGS_SUCCESS';
var SAVE_SAML_SETTINGS_FAIL = 'redux/settings/SAVE_SAML_SETTINGS_FAIL';
var DELETE_SAML_SETTINGS = 'redux/settings/DELETE_SAML_SETTINGS';
var DELETE_SAML_SETTINGS_SUCCESS = 'redux/settings/DELETE_SAML_SETTINGS_SUCCESS';
var DELETE_SAML_SETTINGS_FAIL = 'redux/settings/DELETE_SAML_SETTINGS_FAIL';
var CHECK_SAML_CERTIFICATE = 'redux/settings/CHECK_SAML_CERTIFICATE';
var CHECK_SAML_CERTIFICATE_SUCCESS = 'redux/settings/CHECK_SAML_CERTIFICATE_SUCCESS';
var CHECK_SAML_CERTIFICATE_FAIL = 'redux/settings/CHECK_SAML_CERTIFICATE_FAIL';
var GET_SAML_LOGIN_URL = 'redux/settings/GET_SAML_LOGIN_URL';
var GET_SAML_LOGIN_URL_SUCCESS = 'redux/settings/GET_SAML_LOGIN_URL_SUCCESS';
var GET_SAML_LOGIN_URL_FAIL = 'redux/settings/GET_SAML_LOGIN_URL_FAIL';
var GET_GOOGLE_ANALYTICS_SETTINGS = 'redux/settings/GET_GOOGLE_ANALYTICS_SETTINGS';
var GET_GOOGLE_ANALYTICS_SETTINGS_SUCCESS = 'redux/settings/GET_GOOGLE_ANALYTICS_SETTINGS_SUCCESS';
var GET_GOOGLE_ANALYTICS_SETTINGS_FAIL = 'redux/settings/GET_GOOGLE_ANALYTICS_SETTINGS_FAIL';
var SAVE_GOOGLE_ANALYTICS_SETTINGS = 'redux/settings/SAVE_GOOGLE_ANALYTICS_SETTINGS';
var SAVE_GOOGLE_ANALYTICS_SETTINGS_SUCCESS = 'redux/settings/SAVE_GOOGLE_ANALYTICS_SETTINGS_SUCCESS';
var SAVE_GOOGLE_ANALYTICS_SETTINGS_FAIL = 'redux/settings/SAVE_GOOGLE_ANALYTICS_SETTINGS_FAIL';
var DELETE_GOOGLE_ANALYTICS_SETTINGS = 'redux/settings/DELETE_GOOGLE_ANALYTICS_SETTINGS';
var DELETE_GOOGLE_ANALYTICS_SETTINGS_SUCCESS = 'redux/settings/DELETE_GOOGLE_ANALYTICS_SETTINGS_SUCCESS';
var DELETE_GOOGLE_ANALYTICS_SETTINGS_FAIL = 'redux/settings/DELETE_GOOGLE_ANALYTICS_SETTINGS_FAIL';
var initialStates = {
  loading: false,
  error: null,
  settings: null
};
function reducer() {
  var state = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : initialStates;
  var action = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : {};
  switch (action.type) {
    case GET_SAML_SETTINGS:
      return _objectSpread(_objectSpread({}, state), {}, {
        saml_settings: null,
        loading: true,
        error: null
      });
    case GET_SAML_SETTINGS_SUCCESS:
      return _objectSpread(_objectSpread({}, state), {}, {
        loading: false,
        saml_settings: action.result
      });
    case GET_SAML_SETTINGS_FAIL:
      return _objectSpread(_objectSpread({}, state), {}, {
        loading: false,
        error: action.error
      });
    case SAVE_SAML_SETTINGS:
      return _objectSpread(_objectSpread({}, state), {}, {
        saml_settings: null,
        loading: true,
        error: null
      });
    case SAVE_SAML_SETTINGS_SUCCESS:
      return _objectSpread(_objectSpread({}, state), {}, {
        loading: false,
        saml_settings: action.result
      });
    case SAVE_SAML_SETTINGS_FAIL:
      return _objectSpread(_objectSpread({}, state), {}, {
        loading: false,
        error: action.error
      });
    case CHECK_SAML_CERTIFICATE:
      return _objectSpread(_objectSpread({}, state), {}, {
        saml_settings: null,
        loading: true,
        error: null
      });
    case CHECK_SAML_CERTIFICATE_SUCCESS:
      return _objectSpread(_objectSpread({}, state), {}, {
        loading: false,
        saml_settings: action.result
      });
    case CHECK_SAML_CERTIFICATE_FAIL:
      return _objectSpread(_objectSpread({}, state), {}, {
        loading: false,
        error: action.error
      });
    case DELETE_SAML_SETTINGS:
      return _objectSpread(_objectSpread({}, state), {}, {
        saml_settings: null,
        loading: true,
        error: null
      });
    case DELETE_SAML_SETTINGS_SUCCESS:
      return _objectSpread(_objectSpread({}, state), {}, {
        loading: false,
        saml_settings: action.result
      });
    case DELETE_SAML_SETTINGS_FAIL:
      return _objectSpread(_objectSpread({}, state), {}, {
        loading: false,
        error: action.error
      });
    case GET_GOOGLE_ANALYTICS_SETTINGS:
      return _objectSpread(_objectSpread({}, state), {}, {
        googleAnalytics_settings: null,
        loading: true,
        error: null
      });
    case GET_GOOGLE_ANALYTICS_SETTINGS_SUCCESS:
      return _objectSpread(_objectSpread({}, state), {}, {
        loading: false,
        googleAnalytics_settings: action.result
      });
    case GET_GOOGLE_ANALYTICS_SETTINGS_FAIL:
      return _objectSpread(_objectSpread({}, state), {}, {
        loading: false,
        error: action.error
      });
    case SAVE_GOOGLE_ANALYTICS_SETTINGS:
      return _objectSpread(_objectSpread({}, state), {}, {
        googleAnalytics_settings: null,
        loading: true,
        error: null
      });
    case SAVE_GOOGLE_ANALYTICS_SETTINGS_SUCCESS:
      return _objectSpread(_objectSpread({}, state), {}, {
        loading: false,
        googleAnalytics_settings: action.result
      });
    case SAVE_GOOGLE_ANALYTICS_SETTINGS_FAIL:
      return _objectSpread(_objectSpread({}, state), {}, {
        loading: false,
        error: action.error
      });
    case DELETE_GOOGLE_ANALYTICS_SETTINGS:
      return _objectSpread(_objectSpread({}, state), {}, {
        googleAnalytics_settings: null,
        loading: true,
        error: null
      });
    case DELETE_GOOGLE_ANALYTICS_SETTINGS_SUCCESS:
      return _objectSpread(_objectSpread({}, state), {}, {
        loading: false,
        googleAnalytics_settings: action.result
      });
    case DELETE_GOOGLE_ANALYTICS_SETTINGS_FAIL:
      return _objectSpread(_objectSpread({}, state), {}, {
        loading: false,
        error: action.error
      });
    default:
      return _objectSpread(_objectSpread({}, state), {}, {
        loading: false,
        error: null
      });
  }
}
function checkSAMLCertificate(data) {
  return {
    types: [CHECK_SAML_CERTIFICATE, CHECK_SAML_CERTIFICATE_SUCCESS, CHECK_SAML_CERTIFICATE_FAIL],
    promise: function promise(client) {
      return client.post('/admin/settings/checkSAMLCertificate', {
        data: data
      });
    }
  };
}
function getSAMLSettings() {
  return {
    types: [GET_SAML_SETTINGS, GET_SAML_SETTINGS_SUCCESS, GET_SAML_SETTINGS_FAIL],
    promise: function promise(client) {
      return client.get('/admin/settings/getSAML');
    }
  };
}
function checkSAMLDomain(domain, strict) {
  return {
    types: [GET_SAML_SETTINGS, GET_SAML_SETTINGS_SUCCESS, GET_SAML_SETTINGS_FAIL],
    promise: function promise(client) {
      return client.get("/admin/settings/getSAML?domain=".concat(domain, "&strict=").concat(strict));
    }
  };
}
function saveSAMLSettings(data) {
  return {
    types: [SAVE_SAML_SETTINGS, SAVE_SAML_SETTINGS_SUCCESS, SAVE_SAML_SETTINGS_FAIL],
    promise: function promise(client) {
      return client.post('/admin/settings/saveSAML', {
        data: data
      });
    }
  };
}
function deleteSAMLSettings() {
  return {
    types: [DELETE_SAML_SETTINGS, DELETE_SAML_SETTINGS_SUCCESS, DELETE_SAML_SETTINGS_FAIL],
    promise: function promise(client) {
      return client.post('/admin/settings/deleteSAML');
    }
  };
}
function getSAMLLoginURL(data) {
  return {
    types: [GET_SAML_LOGIN_URL, GET_SAML_LOGIN_URL_SUCCESS, GET_SAML_LOGIN_URL_FAIL],
    promise: function promise(client) {
      return client.post('/settings/samlLoginURL', {
        data: data
      });
    }
  };
}
function getGoogleAnalyticsSettings() {
  return {
    types: [GET_GOOGLE_ANALYTICS_SETTINGS, GET_GOOGLE_ANALYTICS_SETTINGS_SUCCESS, GET_GOOGLE_ANALYTICS_SETTINGS_FAIL],
    promise: function promise(client) {
      return client.get('/admin/settings/googleAnalytics');
    }
  };
}
function saveGoogleAnalyticsSettings(data) {
  return {
    types: [SAVE_GOOGLE_ANALYTICS_SETTINGS, SAVE_GOOGLE_ANALYTICS_SETTINGS_SUCCESS, SAVE_GOOGLE_ANALYTICS_SETTINGS_FAIL],
    promise: function promise(client) {
      return client.post('/admin/settings/saveGoogleAnalytics', {
        data: data
      });
    }
  };
}
function deleteGoogleAnalyticsSettings(data) {
  return {
    types: [DELETE_GOOGLE_ANALYTICS_SETTINGS, DELETE_GOOGLE_ANALYTICS_SETTINGS_SUCCESS, DELETE_GOOGLE_ANALYTICS_SETTINGS_FAIL],
    promise: function promise(client) {
      return client.post('/admin/settings/deleteGoogleAnalytics', {
        data: data
      });
    }
  };
}