"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.loadBaseThemes = exports.default = void 0;
var _module2 = require("../../utils/module");
var _stateModule = require("../../utils/stateModule");
var initialState = {
  baseThemeList: [],
  query: {}
};
var namespace = (0, _module2.namespaceType)('redux/baseThemes/');
var moduleRootPath = 'baseThemes';
var _module = (0, _stateModule.createStateModule)({
  typeNamespace: namespace(''),
  moduleRootPath: moduleRootPath,
  initialState: initialState
});
var _default = exports.default = _module.reducer;
var loadBaseThemesRequest = (0, _module2.makeRequestAction)(namespace('LOAD_BASE_THEMES'), function () {
  return function (client) {
    return client.get('/loadBaseThemes');
  };
});
var loadBaseThemes = exports.loadBaseThemes = _module.makeQueryAction({
  queryStatePath: 'query',
  resultToState: function resultToState(res) {
    return {
      baseThemeList: res
    };
  },
  requestAction: loadBaseThemesRequest
});