"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.loadImage = exports.closest = void 0;
var closest = exports.closest = function closest(el, fn) {
  var currentEl = el;
  while (currentEl) {
    if (fn(currentEl)) return currentEl;
    currentEl = currentEl.parentElement;
  }
};
var loadImage = exports.loadImage = function loadImage(src) {
  var image = new Image();
  image.src = src;
  return image;
};