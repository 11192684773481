"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.setClientStore = exports.getClientStore = void 0;
var store;
var setClientStore = exports.setClientStore = function setClientStore(newStore) {
  store = newStore;
};
var getClientStore = exports.getClientStore = function getClientStore() {
  return store;
};