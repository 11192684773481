"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.extensionDefaultView = exports.extensionDefaultTab = void 0;
exports.getExtensionDefaultTab = getExtensionDefaultTab;
var extensionDefaultView = exports.extensionDefaultView = [{
  key: 'categories',
  text: 'Only Categories'
}, {
  key: 'categories,subcategories',
  text: 'Categories/Subcategories'
}, {
  key: 'all',
  text: 'Everything expanded'
}];
var extensionDefaultTab = exports.extensionDefaultTab = [{
  key: 'creator',
  text: 'Capture'
}, {
  key: 'categories',
  text: 'Library'
}, {
  key: 'qa',
  text: 'The Square'
}];
function getExtensionDefaultTab() {
  var disableTheSquare = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : false;
  var disableCapture = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : false;
  return extensionDefaultTab.filter(function (tab) {
    if (disableTheSquare && tab.key === 'qa') {
      return false;
    }
    return !(disableCapture && tab.key === 'creator');
  });
}