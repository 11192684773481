"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.updateShareUser = exports.updateEmailDomains = exports.toggleShareAccess = exports.toggleInviteInput = exports.shareRole = exports.setIsEditingDomain = exports.setAllowedDomain = exports.restorePendingShareRequest = exports.rememberEmailDomains = exports.isEmailDomainBlacklisted = exports.fetchSharedUserList = exports.deletePendingShareRequest = exports.default = exports.clearSharedUserList = exports.cleanupPendingShareRequest = void 0;
var _module = require("../../utils/module");
var _auth = require("./auth");
var roleActions = _interopRequireWildcard(require("redux/modules/userRoles"));
var _config = _interopRequireDefault(require("../../config"));
var urlUtils = _interopRequireWildcard(require("../../utils/urls"));
var _handlers;
function _interopRequireDefault(e) { return e && e.__esModule ? e : { default: e }; }
function _getRequireWildcardCache(e) { if ("function" != typeof WeakMap) return null; var r = new WeakMap(), t = new WeakMap(); return (_getRequireWildcardCache = function _getRequireWildcardCache(e) { return e ? t : r; })(e); }
function _interopRequireWildcard(e, r) { if (!r && e && e.__esModule) return e; if (null === e || "object" != _typeof(e) && "function" != typeof e) return { default: e }; var t = _getRequireWildcardCache(r); if (t && t.has(e)) return t.get(e); var n = { __proto__: null }, a = Object.defineProperty && Object.getOwnPropertyDescriptor; for (var u in e) if ("default" !== u && {}.hasOwnProperty.call(e, u)) { var i = a ? Object.getOwnPropertyDescriptor(e, u) : null; i && (i.get || i.set) ? Object.defineProperty(n, u, i) : n[u] = e[u]; } return n.default = e, t && t.set(e, n), n; }
function _typeof(o) { "@babel/helpers - typeof"; return _typeof = "function" == typeof Symbol && "symbol" == typeof Symbol.iterator ? function (o) { return typeof o; } : function (o) { return o && "function" == typeof Symbol && o.constructor === Symbol && o !== Symbol.prototype ? "symbol" : typeof o; }, _typeof(o); }
function ownKeys(e, r) { var t = Object.keys(e); if (Object.getOwnPropertySymbols) { var o = Object.getOwnPropertySymbols(e); r && (o = o.filter(function (r) { return Object.getOwnPropertyDescriptor(e, r).enumerable; })), t.push.apply(t, o); } return t; }
function _objectSpread(e) { for (var r = 1; r < arguments.length; r++) { var t = null != arguments[r] ? arguments[r] : {}; r % 2 ? ownKeys(Object(t), !0).forEach(function (r) { _defineProperty(e, r, t[r]); }) : Object.getOwnPropertyDescriptors ? Object.defineProperties(e, Object.getOwnPropertyDescriptors(t)) : ownKeys(Object(t)).forEach(function (r) { Object.defineProperty(e, r, Object.getOwnPropertyDescriptor(t, r)); }); } return e; }
function _defineProperty(e, r, t) { return (r = _toPropertyKey(r)) in e ? Object.defineProperty(e, r, { value: t, enumerable: !0, configurable: !0, writable: !0 }) : e[r] = t, e; }
function _toPropertyKey(t) { var i = _toPrimitive(t, "string"); return "symbol" == _typeof(i) ? i : i + ""; }
function _toPrimitive(t, r) { if ("object" != _typeof(t) || !t) return t; var e = t[Symbol.toPrimitive]; if (void 0 !== e) { var i = e.call(t, r || "default"); if ("object" != _typeof(i)) return i; throw new TypeError("@@toPrimitive must return a primitive value."); } return ("string" === r ? String : Number)(t); }
var type = (0, _module.namespaceType)('redux/roleShareAccess/');
var TOGGLE_SHARE_ACCESS = type('TOGGLE_SHARE_ACCESS');
var TOGGLE_INVITE_INPUT = type('TOGGLE_INVITE_INPUT');
var SET_ALLOWED_DOMAIN = type('SET_ALLOWED_DOMAIN');
var SET_IS_EDITING_DOMAIN = type('SET_IS_EDITING_DOMAIN');
var FETCH_SHARED_LIST = type('FETCH_SHARED_LIST');
var FETCH_SHARED_LIST_SUCCESS = type('FETCH_SHARED_LIST_SUCCESS');
var FETCH_SHARED_LIST_FAIL = type('FETCH_SHARED_LIST_FAIL');
var FETCH_SHARED_LIST_CLEAR = type('FETCH_SHARED_LIST_CLEAR');
var SHARE_ROLES = type('SHARE_ROLES');
var SHARE_ROLES_SUCCESS = type('SHARE_ROLES_SUCCESS');
var SHARE_ROLES_FAIL = type('SHARE_ROLES_FAIL');
var DELETE_PENDING_SHARE_REQUEST = type('DELETE_PENDING_SHARE_REQUEST');
var DELETE_PENDING_SHARE_REQUEST_SUCCESS = type('DELETE_PENDING_SHARE_REQUEST_SUCCESS');
var DELETE_PENDING_SHARE_REQUEST_FAIL = type('DELETE_PENDING_SHARE_REQUEST_FAIL');
var RESTORE_PENDING_SHARE_REQUEST = type('RESTORE_PENDING_SHARE_REQUEST');
var RESTORE_PENDING_SHARE_REQUEST_SUCCESS = type('RESTORE_PENDING_SHARE_REQUEST_SUCCESS');
var RESTORE_PENDING_SHARE_REQUEST_FAIL = type('RESTORE_PENDING_SHARE_REQUEST_FAIL');
var CLEANUP_PENDING_SHARE_REQUEST = type('CLEANUP_PENDING_SHARE_REQUEST');
var CLEANUP_PENDING_SHARE_REQUEST_SUCCESS = type('CLEANUP_PENDING_SHARE_REQUEST_SUCCESS');
var CLEANUP_PENDING_SHARE_REQUEST_FAIL = type('CLEANUP_PENDING_SHARE_REQUEST_FAIL');
var UPDATE_SHARE_USER = type('UPDATE_SHARE_USER');
var UPDATE_SHARE_USER_SUCCESS = type('UPDATE_SHARE_USER_SUCCESS');
var UPDATE_SHARE_USER_FAIL = type('UPDATE_SHARE_USER_FAIL');
var UPDATE_EMAIL_DOMAINS = type('ROLES_UPDATE_EMAIL_DOMAINS');
var UPDATE_EMAIL_DOMAINS_SUCCESS = type('ROLES_UPDATE_EMAIL_DOMAINS_SUCCESS');
var UPDATE_EMAIL_DOMAINS_FAIL = type('ROLES_UPDATE_EMAIL_DOMAINS_FAIL');
var API_REQUEST = type('API_REQUEST');
var API_REQUEST_SUCCESS = type('API_REQUEST_SUCCESS');
var API_REQUEST_FAIL = type('API_REQUEST_FAIL');
var initialState = {
  visible: false,
  showInviteInput: false,
  allowedDomain: urlUtils.normaliseDomain(_config.default.host, {
    removeWWW: true
  }),
  // e.g. 'iorad.com'
  isEditingDomain: false,
  sharedUserListFetching: true,
  sharedUserList: [],
  roleIds: [],
  roleLearners: []
};
var handlers = (_handlers = {}, _defineProperty(_defineProperty(_defineProperty(_defineProperty(_defineProperty(_defineProperty(_defineProperty(_defineProperty(_defineProperty(_defineProperty(_handlers, TOGGLE_SHARE_ACCESS, function (state, action) {
  return _objectSpread(_objectSpread({}, state), {}, {
    visible: typeof action.showOrHide !== 'undefined' ? action.showOrHide : !state.visible
  });
}), TOGGLE_INVITE_INPUT, function (state, action) {
  return _objectSpread(_objectSpread({}, state), {}, {
    showInviteInput: typeof action.showOrHide !== 'undefined' ? action.showOrHide : !state.visible
  });
}), SET_ALLOWED_DOMAIN, function (state, action) {
  return _objectSpread(_objectSpread({}, state), {}, {
    allowedDomain: action.domain
  });
}), SET_IS_EDITING_DOMAIN, function (state, action) {
  return _objectSpread(_objectSpread({}, state), {}, {
    isEditingDomain: action.isEditing
  });
}), FETCH_SHARED_LIST, function (state, action) {
  return _objectSpread(_objectSpread({}, state), {}, {
    sharedUserListFetching: true,
    roleIds: action.roleIds
  });
}), FETCH_SHARED_LIST_SUCCESS, function (state, action) {
  return _objectSpread(_objectSpread({}, state), {}, {
    sharedUserListFetching: false,
    sharedUserList: action.result
  });
}), FETCH_SHARED_LIST_FAIL, function (state, action) {
  return _objectSpread(_objectSpread({}, state), {}, {
    sharedUserListFetching: false,
    error: action.error
  });
}), FETCH_SHARED_LIST_CLEAR, function (state) {
  return _objectSpread(_objectSpread({}, state), {}, {
    sharedUserList: initialState.sharedUserList,
    roleIds: []
  });
}), SHARE_ROLES, function (state) {
  return _objectSpread({}, state);
}), SHARE_ROLES_SUCCESS, function (state) {
  return _objectSpread({}, state);
}), _defineProperty(_defineProperty(_defineProperty(_defineProperty(_defineProperty(_defineProperty(_defineProperty(_handlers, SHARE_ROLES_FAIL, function (state, action) {
  return _objectSpread(_objectSpread({}, state), {}, {
    error: action.error
  });
}), DELETE_PENDING_SHARE_REQUEST_FAIL, function (state, action) {
  return _objectSpread(_objectSpread({}, state), {}, {
    error: action.error
  });
}), RESTORE_PENDING_SHARE_REQUEST_FAIL, function (state, action) {
  return _objectSpread(_objectSpread({}, state), {}, {
    error: action.error
  });
}), CLEANUP_PENDING_SHARE_REQUEST_FAIL, function (state, action) {
  return _objectSpread(_objectSpread({}, state), {}, {
    error: action.error
  });
}), UPDATE_SHARE_USER, function (state) {
  return _objectSpread({}, state);
}), UPDATE_SHARE_USER_SUCCESS, function (state) {
  return _objectSpread({}, state);
}), UPDATE_SHARE_USER_FAIL, function (state, action) {
  return _objectSpread(_objectSpread({}, state), {}, {
    error: action.error
  });
}));
var _default = exports.default = (0, _module.createReducer)(handlers, initialState);
var toggleShareAccess = exports.toggleShareAccess = function toggleShareAccess(showOrHide) {
  return {
    type: TOGGLE_SHARE_ACCESS,
    showOrHide: showOrHide
  };
};
var toggleInviteInput = exports.toggleInviteInput = function toggleInviteInput(showOrHide) {
  return {
    type: TOGGLE_INVITE_INPUT,
    showOrHide: showOrHide
  };
};
var setAllowedDomain = exports.setAllowedDomain = function setAllowedDomain(domain) {
  return {
    type: SET_ALLOWED_DOMAIN,
    domain: domain
  };
};
var setIsEditingDomain = exports.setIsEditingDomain = function setIsEditingDomain(isEditing) {
  return {
    type: SET_IS_EDITING_DOMAIN,
    isEditing: isEditing
  };
};
var fetchSharedUserList = exports.fetchSharedUserList = function fetchSharedUserList(roleIdsString) {
  return {
    types: [FETCH_SHARED_LIST, FETCH_SHARED_LIST_SUCCESS, FETCH_SHARED_LIST_FAIL],
    promise: function promise(client) {
      return client.get("/role/getSharedUserList?role_ids=".concat(roleIdsString, "&noCache=").concat(Date.now()));
    },
    roleIds: String(roleIdsString || '').split(';')
  };
};
var clearSharedUserList = exports.clearSharedUserList = function clearSharedUserList() {
  return {
    type: FETCH_SHARED_LIST_CLEAR
  };
};
var shareRole = exports.shareRole = function shareRole(roleId, emailList) {
  var userPermission = arguments.length > 2 && arguments[2] !== undefined ? arguments[2] : 'V';
  var sendEmails = arguments.length > 3 && arguments[3] !== undefined ? arguments[3] : true;
  var remember = arguments.length > 4 && arguments[4] !== undefined ? arguments[4] : false;
  return {
    types: [SHARE_ROLES, SHARE_ROLES_SUCCESS, SHARE_ROLES_FAIL],
    promise: function promise(client) {
      return client.post('/role/share', {
        data: {
          role_id: roleId,
          email_list: emailList,
          user_permission: userPermission,
          send_emails: sendEmails,
          remember: remember
        }
      });
    }
  };
};
var updateShareUser = exports.updateShareUser = function updateShareUser(roleId, userId, privilege) {
  return {
    types: [UPDATE_SHARE_USER, UPDATE_SHARE_USER, SHARE_ROLES_SUCCESS],
    promise: function promise(client) {
      return client.post('/role/updateShareUser', {
        data: {
          role_id: roleId,
          user_id: userId,
          privilege: privilege
        }
      });
    }
  };
};
var deletePendingShareRequest = exports.deletePendingShareRequest = function deletePendingShareRequest(roleId, userEmail) {
  return {
    types: [DELETE_PENDING_SHARE_REQUEST, DELETE_PENDING_SHARE_REQUEST_SUCCESS, DELETE_PENDING_SHARE_REQUEST_FAIL],
    promise: function promise(client) {
      return client.post('/role/deletePendingShareRequest', {
        data: {
          role_id: roleId,
          userEmail: userEmail
        }
      });
    }
  };
};
var restorePendingShareRequest = exports.restorePendingShareRequest = function restorePendingShareRequest(roleId, userEmail) {
  return {
    types: [RESTORE_PENDING_SHARE_REQUEST, RESTORE_PENDING_SHARE_REQUEST_SUCCESS, RESTORE_PENDING_SHARE_REQUEST_FAIL],
    promise: function promise(client) {
      return client.post('/role/restorePendingShareRequest', {
        data: {
          role_id: roleId,
          userEmail: userEmail
        }
      });
    }
  };
};
var cleanupPendingShareRequest = exports.cleanupPendingShareRequest = function cleanupPendingShareRequest(roleId, userEmail) {
  return {
    types: [CLEANUP_PENDING_SHARE_REQUEST, CLEANUP_PENDING_SHARE_REQUEST_SUCCESS, CLEANUP_PENDING_SHARE_REQUEST_FAIL],
    promise: function promise(client) {
      return client.post('/role/cleanupPendingShareRequest', {
        data: {
          role_id: roleId,
          userEmail: userEmail
        }
      });
    }
  };
};
var isEmailDomainBlacklisted = exports.isEmailDomainBlacklisted = function isEmailDomainBlacklisted(emailDomain) {
  return {
    types: [API_REQUEST, API_REQUEST_SUCCESS, API_REQUEST_FAIL],
    promise: function promise(client) {
      return client.get('/tutorial/isEmailDomainBlacklisted', {
        params: {
          emailDomain: emailDomain
        }
      });
    }
  };
};
var updateEmailDomains = exports.updateEmailDomains = function updateEmailDomains(roleIds, isEmailDomainAllowed, emailDomains, emailDomainsMode) {
  var throwOnError = arguments.length > 4 && arguments[4] !== undefined ? arguments[4] : false;
  var backgroundRun = arguments.length > 5 ? arguments[5] : undefined;
  return function (dispatch) {
    return dispatch({
      types: [UPDATE_EMAIL_DOMAINS, UPDATE_EMAIL_DOMAINS_SUCCESS, UPDATE_EMAIL_DOMAINS_FAIL],
      promise: function promise(client) {
        return client.post('/role/updateEmailDomains', {
          data: {
            roleIds: roleIds,
            isEmailDomainAllowed: isEmailDomainAllowed,
            emailDomains: emailDomains && Array.isArray(emailDomains) ? emailDomains.join(';') : emailDomains,
            emailDomainsMode: emailDomainsMode,
            throwOnError: throwOnError,
            backgroundRun: backgroundRun
          }
        });
      },
      backgroundRun: backgroundRun
    }).then(function () {
      return dispatch((0, _auth.load)());
    }) // to get updated quota usage
    .then(function () {
      return dispatch(roleActions.reloadRole(roleIds, ['emailDomains']));
    });
  };
};
var rememberEmailDomains = exports.rememberEmailDomains = function rememberEmailDomains(emailDomains) {
  return {
    types: [API_REQUEST, API_REQUEST_SUCCESS, API_REQUEST_FAIL],
    promise: function promise(client) {
      return client.post('/role/rememberEmailDomains', {
        data: {
          emailDomains: emailDomains && Array.isArray(emailDomains) ? emailDomains.join(';') : emailDomains
        }
      });
    }
  };
};