"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.samlLearnersCaptureDisabledMessage = exports.samlDefaultValue = exports.LOGIN_SOURCE_EXTENSION = exports.LOGIN_SOURCE_BROWSER = void 0;
var _extensionOptions = require("./extension-options");
var samlDefaultValue = exports.samlDefaultValue = {
  saml_domain: '',
  saml_force_login: true,
  saml_vendor: '',
  saml_entity_id: '',
  saml_login_url: '',
  saml_logout_url: '',
  saml_identifier: '',
  saml_reply_url: '',
  saml_certificate_fingerprint: '',
  saml_learners_tutorial_creation_disabled: false,
  saml_learners_team_tutorials_visible: false,
  saml_learners_hide_the_square: false,
  saml_strict_mode: false,
  saml_one_click_url: '',
  saml_default_view: _extensionOptions.extensionDefaultView[1].key,
  saml_default_tab: _extensionOptions.extensionDefaultTab[0].key,
  saml_show_community_tutorials: true,
  saml_show_help_icon: false,
  saml_show_tutorial_count: false
};
var LOGIN_SOURCE_BROWSER = exports.LOGIN_SOURCE_BROWSER = 'browser';
var LOGIN_SOURCE_EXTENSION = exports.LOGIN_SOURCE_EXTENSION = 'extension';
var samlLearnersCaptureDisabledMessage = exports.samlLearnersCaptureDisabledMessage = 'Capture is disabled for learner accounts. Contact your Administrator.';