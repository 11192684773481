"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.userTypes = exports.userAdminTypes = exports.UserType = void 0;
var UserType = exports.UserType = {
  ADMIN: 1,
  // FIXME: other user types below also migrated from cf app,
  // but they don't seem to represent actual things
  // so it's used only for admin check
  // GUEST: 2,
  // FREE_USER: 3,
  NOT_ADMIN: 3,
  // PREMIUM_USER: 4,
  ADMIN_READ_ONLY: 5
};
var userAdminTypes = exports.userAdminTypes = [UserType.ADMIN, UserType.ADMIN_READ_ONLY];
var userTypes = exports.userTypes = Object.values(UserType);