"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.updatePermission = exports.setInputFocus = exports.setInput = exports.setExistingEmails = exports.fetchKnownContacts = exports.emptyStoredEmails = exports.deleteEmail = exports.default = exports.clearEmails = exports.addEmail = void 0;
var _module = require("../../utils/module");
var _find = _interopRequireDefault(require("lodash/find"));
var _union = _interopRequireDefault(require("lodash/union"));
var _difference = _interopRequireDefault(require("lodash/difference"));
var _validation = require("../../utils/validation");
var _handlers;
function _interopRequireDefault(e) { return e && e.__esModule ? e : { default: e }; }
function _typeof(o) { "@babel/helpers - typeof"; return _typeof = "function" == typeof Symbol && "symbol" == typeof Symbol.iterator ? function (o) { return typeof o; } : function (o) { return o && "function" == typeof Symbol && o.constructor === Symbol && o !== Symbol.prototype ? "symbol" : typeof o; }, _typeof(o); }
function _toConsumableArray(r) { return _arrayWithoutHoles(r) || _iterableToArray(r) || _unsupportedIterableToArray(r) || _nonIterableSpread(); }
function _nonIterableSpread() { throw new TypeError("Invalid attempt to spread non-iterable instance.\nIn order to be iterable, non-array objects must have a [Symbol.iterator]() method."); }
function _unsupportedIterableToArray(r, a) { if (r) { if ("string" == typeof r) return _arrayLikeToArray(r, a); var t = {}.toString.call(r).slice(8, -1); return "Object" === t && r.constructor && (t = r.constructor.name), "Map" === t || "Set" === t ? Array.from(r) : "Arguments" === t || /^(?:Ui|I)nt(?:8|16|32)(?:Clamped)?Array$/.test(t) ? _arrayLikeToArray(r, a) : void 0; } }
function _iterableToArray(r) { if ("undefined" != typeof Symbol && null != r[Symbol.iterator] || null != r["@@iterator"]) return Array.from(r); }
function _arrayWithoutHoles(r) { if (Array.isArray(r)) return _arrayLikeToArray(r); }
function _arrayLikeToArray(r, a) { (null == a || a > r.length) && (a = r.length); for (var e = 0, n = Array(a); e < a; e++) n[e] = r[e]; return n; }
function ownKeys(e, r) { var t = Object.keys(e); if (Object.getOwnPropertySymbols) { var o = Object.getOwnPropertySymbols(e); r && (o = o.filter(function (r) { return Object.getOwnPropertyDescriptor(e, r).enumerable; })), t.push.apply(t, o); } return t; }
function _objectSpread(e) { for (var r = 1; r < arguments.length; r++) { var t = null != arguments[r] ? arguments[r] : {}; r % 2 ? ownKeys(Object(t), !0).forEach(function (r) { _defineProperty(e, r, t[r]); }) : Object.getOwnPropertyDescriptors ? Object.defineProperties(e, Object.getOwnPropertyDescriptors(t)) : ownKeys(Object(t)).forEach(function (r) { Object.defineProperty(e, r, Object.getOwnPropertyDescriptor(t, r)); }); } return e; }
function _defineProperty(e, r, t) { return (r = _toPropertyKey(r)) in e ? Object.defineProperty(e, r, { value: t, enumerable: !0, configurable: !0, writable: !0 }) : e[r] = t, e; }
function _toPropertyKey(t) { var i = _toPrimitive(t, "string"); return "symbol" == _typeof(i) ? i : i + ""; }
function _toPrimitive(t, r) { if ("object" != _typeof(t) || !t) return t; var e = t[Symbol.toPrimitive]; if (void 0 !== e) { var i = e.call(t, r || "default"); if ("object" != _typeof(i)) return i; throw new TypeError("@@toPrimitive must return a primitive value."); } return ("string" === r ? String : Number)(t); }
var type = (0, _module.namespaceType)('redux/roleInviteEmail/');
var ADD_EMAIL = type('ADD_EMAIL');
var DELETE_EMAIL = type('DELETE_EMAIL');
var CLEAR_EMAILS = type('CLEAR_EMAILS');
var EMPTY_STORED_EMAILS = type('EMPTY_STORED_EMAILS');
var SET_INPUT_FOCUS = type('SET_INPUT_FOCUS');
var SET_INPUT = type('SET_INPUT');
var SET_EXISTING_EMAILS = type('SET_EXISTING_EMAILS');
var UPDATE_PERMISSION = type('UPDATE_PERMISSION');
var FETCH_CONTACTS = type('FETCH_CONTACTS');
var FETCH_CONTACTS_SUCCESS = type('FETCH_CONTACTS_SUCCESS');
var FETCH_CONTACTS_FAIL = type('FETCH_CONTACTS_FAIL');
var initialState = {
  current: [],
  stored: [],
  inputFocused: false,
  input: '',
  existing: [],
  knownFetching: false,
  knownFetched: false,
  known: []
};
var handlers = (_handlers = {}, _defineProperty(_defineProperty(_defineProperty(_defineProperty(_defineProperty(_defineProperty(_defineProperty(_defineProperty(_defineProperty(_defineProperty(_handlers, ADD_EMAIL, function (state, action) {
  return _objectSpread(_objectSpread({}, state), {}, {
    current: [].concat(_toConsumableArray(state.current), [{
      id: _objectSpread({}, state).current.reduce(function (maxId, email) {
        return Math.max(email.id, maxId);
      }, -1) + 1,
      address: action.address,
      permission: action.permission
    }]),
    stored: _objectSpread({}, state).stored.filter(function (email) {
      return email !== action.address;
    }),
    known: _objectSpread({}, state).known.filter(function (person) {
      return person.EMAIL !== action.address;
    })
  });
}), DELETE_EMAIL, function (state, action) {
  return _objectSpread(_objectSpread({}, state), {}, {
    stored: [].concat(_toConsumableArray(state.stored), [(0, _find.default)(_objectSpread({}, state).current, {
      'id': action.id
    }).address]),
    current: _objectSpread({}, state).current.filter(function (email) {
      return email.id !== action.id;
    })
  });
}), CLEAR_EMAILS, function (state) {
  return _objectSpread(_objectSpread({}, state), {}, {
    stored: (0, _union.default)(_objectSpread({}, state).stored, _objectSpread({}, state).current.map(function (email) {
      return email.address;
    })),
    current: []
  });
}), EMPTY_STORED_EMAILS, function (state) {
  return _objectSpread(_objectSpread({}, state), {}, {
    stored: []
  });
}), UPDATE_PERMISSION, function (state, action) {
  return _objectSpread(_objectSpread({}, state), {}, {
    current: state.current.map(function (email) {
      return email.id === action.id ? _objectSpread(_objectSpread({}, email), {}, {
        permission: action.permission
      }) : email;
    })
  });
}), SET_INPUT_FOCUS, function (state, action) {
  return _objectSpread(_objectSpread({}, state), {}, {
    inputFocused: action.focused
  });
}), SET_INPUT, function (state, action) {
  return _objectSpread(_objectSpread({}, state), {}, {
    input: action.value
  });
}), SET_EXISTING_EMAILS, function (state, action) {
  return _objectSpread(_objectSpread({}, state), {}, {
    existing: action.emails,
    stored: (0, _difference.default)(_objectSpread({}, state).stored, action.emails)
  });
}), FETCH_CONTACTS, function (state) {
  return _objectSpread(_objectSpread({}, state), {}, {
    knownFetching: true,
    knownFetched: false
  });
}), FETCH_CONTACTS_SUCCESS, function (state, action) {
  return _objectSpread(_objectSpread({}, state), {}, {
    knownFetching: false,
    knownFetched: true,
    known: action.result.filter(function (o) {
      return !_objectSpread({}, state).existing.includes(o.EMAIL) && (0, _validation.checkEmail)(o.EMAIL);
    })
  });
}), _defineProperty(_handlers, FETCH_CONTACTS_FAIL, function (state, action) {
  return _objectSpread(_objectSpread({}, state), {}, {
    knownFetching: false,
    error: action.error
  });
}));
var _default = exports.default = (0, _module.createReducer)(handlers, initialState);
var addEmail = exports.addEmail = function addEmail(address) {
  var permission = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : null;
  return {
    type: ADD_EMAIL,
    address: address,
    permission: permission
  };
};
var deleteEmail = exports.deleteEmail = function deleteEmail(id) {
  return {
    type: DELETE_EMAIL,
    id: id
  };
};
var clearEmails = exports.clearEmails = function clearEmails() {
  return {
    type: CLEAR_EMAILS
  };
};
var emptyStoredEmails = exports.emptyStoredEmails = function emptyStoredEmails() {
  return {
    type: EMPTY_STORED_EMAILS
  };
};
var updatePermission = exports.updatePermission = function updatePermission(id, permission) {
  return {
    type: UPDATE_PERMISSION,
    id: id,
    permission: permission
  };
};
var setInputFocus = exports.setInputFocus = function setInputFocus(focused) {
  return {
    type: SET_INPUT_FOCUS,
    focused: focused
  };
};
var setInput = exports.setInput = function setInput(value) {
  return {
    type: SET_INPUT,
    value: value
  };
};
var setExistingEmails = exports.setExistingEmails = function setExistingEmails(emails) {
  return {
    type: SET_EXISTING_EMAILS,
    emails: emails
  };
};
var fetchKnownContacts = exports.fetchKnownContacts = function fetchKnownContacts() {
  return {
    types: [FETCH_CONTACTS, FETCH_CONTACTS_SUCCESS, FETCH_CONTACTS_FAIL],
    promise: function promise(client) {
      return client.get('/user/getAllShareEmail');
    }
  };
};