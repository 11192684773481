"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.createVideo = exports.clearError = void 0;
exports.default = reducer;
exports.saveAvatarOptions = exports.getVoices = exports.getVideo = exports.getAvatars = void 0;
var _module = require("../../utils/module");
function _typeof(o) { "@babel/helpers - typeof"; return _typeof = "function" == typeof Symbol && "symbol" == typeof Symbol.iterator ? function (o) { return typeof o; } : function (o) { return o && "function" == typeof Symbol && o.constructor === Symbol && o !== Symbol.prototype ? "symbol" : typeof o; }, _typeof(o); }
function ownKeys(e, r) { var t = Object.keys(e); if (Object.getOwnPropertySymbols) { var o = Object.getOwnPropertySymbols(e); r && (o = o.filter(function (r) { return Object.getOwnPropertyDescriptor(e, r).enumerable; })), t.push.apply(t, o); } return t; }
function _objectSpread(e) { for (var r = 1; r < arguments.length; r++) { var t = null != arguments[r] ? arguments[r] : {}; r % 2 ? ownKeys(Object(t), !0).forEach(function (r) { _defineProperty(e, r, t[r]); }) : Object.getOwnPropertyDescriptors ? Object.defineProperties(e, Object.getOwnPropertyDescriptors(t)) : ownKeys(Object(t)).forEach(function (r) { Object.defineProperty(e, r, Object.getOwnPropertyDescriptor(t, r)); }); } return e; }
function _defineProperty(e, r, t) { return (r = _toPropertyKey(r)) in e ? Object.defineProperty(e, r, { value: t, enumerable: !0, configurable: !0, writable: !0 }) : e[r] = t, e; }
function _toPropertyKey(t) { var i = _toPrimitive(t, "string"); return "symbol" == _typeof(i) ? i : i + ""; }
function _toPrimitive(t, r) { if ("object" != _typeof(t) || !t) return t; var e = t[Symbol.toPrimitive]; if (void 0 !== e) { var i = e.call(t, r || "default"); if ("object" != _typeof(i)) return i; throw new TypeError("@@toPrimitive must return a primitive value."); } return ("string" === r ? String : Number)(t); }
var type = (0, _module.namespaceType)('redux/avatar-video/');
var GET_AVATARS = type('GET_AVATARS');
var GET_AVATARS__SUCCESS = type('GET_AVATARS__SUCCESS');
var GET_AVATARS__FAIL = type('GET_AVATARS__FAIL');
var GET_VOICES = type('GET_VOICES');
var GET_VOICES__SUCCESS = type('GET_VOICES__SUCCESS');
var GET_VOICES__FAIL = type('GET_VOICES__FAIL');
var CREATE_VIDEO = type('CREATE_VIDEO');
var CREATE_VIDEO__SUCCESS = type('CREATE_VIDEO__SUCCESS');
var CREATE_VIDEO__FAIL = type('CREATE_VIDEO__FAIL');
var GET_VIDEO = type('GET_VIDEO');
var GET_VIDEO__SUCCESS = type('GET_VIDEO__SUCCESS');
var GET_VIDEO__FAIL = type('GET_VIDEO__FAIL');
var SAVE_AVATAR_OPTIONS = type('SAVE_AVATAR_OPTIONS');
var CLEAR_ERROR = type('CLEAR_ERROR');

/**
 * Main reducer.
 *
 * @param {Object} state
 * @param {Object} action
 * @return {Object} state object
 */
var initialState = {
  loading: false,
  error: null,
  avatarOptions: {},
  yepic: {
    avatars: [],
    voices: []
  },
  didStudio: {
    avatars: [],
    voices: []
  },
  azure: {
    avatars: [],
    voices: []
  },
  heyGen: {
    avatars: [],
    voices: []
  }
};
function reducer() {
  var state = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : initialState;
  var action = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : {};
  switch (action.type) {
    case GET_AVATARS:
      return _objectSpread(_objectSpread({}, state), {}, _defineProperty({
        loading: true,
        error: null
      }, action.aiProvider, _objectSpread(_objectSpread({}, state[action.aiProvider]), {}, {
        avatarPromise: action.promise
      })));
    case GET_AVATARS__SUCCESS:
      return _objectSpread(_objectSpread({}, state), {}, _defineProperty({
        loading: false,
        error: null
      }, action.aiProvider, _objectSpread(_objectSpread({}, state[action.aiProvider]), {}, {
        avatars: action.result
      })));
    case GET_AVATARS__FAIL:
      return _objectSpread(_objectSpread({}, state), {}, _defineProperty({
        loading: false,
        error: action.error
      }, action.aiProvider, _objectSpread(_objectSpread({}, state[action.aiProvider]), {}, {
        avatarPromise: null
      })));
    case GET_VOICES:
      return _objectSpread(_objectSpread({}, state), {}, _defineProperty({
        loading: true,
        error: null
      }, action.aiProvider, _objectSpread(_objectSpread({}, state[action.aiProvider]), {}, {
        voicePromise: action.promise
      })));
    case GET_VOICES__SUCCESS:
      return _objectSpread(_objectSpread({}, state), {}, _defineProperty({
        loading: false,
        error: null
      }, action.aiProvider, _objectSpread(_objectSpread({}, state[action.aiProvider]), {}, {
        voices: action.result
      })));
    case GET_VOICES__FAIL:
      return _objectSpread(_objectSpread({}, state), {}, _defineProperty({
        loading: false,
        error: action.error
      }, action.aiProvider, _objectSpread(_objectSpread({}, state[action.aiProvider]), {}, {
        voicePromise: null
      })));
    case CREATE_VIDEO:
      return _objectSpread(_objectSpread({}, state), {}, {
        loading: true,
        error: null
      });
    case CREATE_VIDEO__SUCCESS:
      {
        return _objectSpread(_objectSpread({}, state), {}, {
          loading: false,
          error: null
        });
      }
    case CREATE_VIDEO__FAIL:
      return _objectSpread(_objectSpread({}, state), {}, {
        loading: false,
        error: action.error
      });
    case GET_VIDEO:
      return _objectSpread(_objectSpread({}, state), {}, {
        loading: true,
        error: null
      });
    case GET_VIDEO__SUCCESS:
      {
        return _objectSpread(_objectSpread({}, state), {}, {
          loading: false,
          error: null
        });
      }
    case GET_VIDEO__FAIL:
      return _objectSpread(_objectSpread({}, state), {}, {
        loading: false,
        error: action.error
      });
    case SAVE_AVATAR_OPTIONS:
      return _objectSpread(_objectSpread({}, state), {}, {
        avatarOptions: action.values
      });
    case CLEAR_ERROR:
      return _objectSpread(_objectSpread({}, state), {}, {
        error: null
      });
    default:
      return state;
  }
}
var saveAvatarOptions = exports.saveAvatarOptions = function saveAvatarOptions(values) {
  return {
    type: SAVE_AVATAR_OPTIONS,
    values: values
  };
};
var clearError = exports.clearError = function clearError() {
  return {
    type: CLEAR_ERROR
  };
};
var getAvatars = exports.getAvatars = function getAvatars(aiProvider) {
  return function (dispatch, getState) {
    var existing = getState().avatar[aiProvider];
    if (existing.avatarPromise) {
      return existing.avatarPromise;
    }
    return dispatch({
      types: [GET_AVATARS, GET_AVATARS__SUCCESS, GET_AVATARS__FAIL],
      promise: function promise(client) {
        return client.get('/avatar/getAvatars', {
          params: {
            aiProvider: aiProvider
          }
        });
      },
      aiProvider: aiProvider
    });
  };
};
var getVoices = exports.getVoices = function getVoices(aiProvider) {
  return function (dispatch, getState) {
    var existing = getState().avatar[aiProvider];
    if (existing !== null && existing !== void 0 && existing.voicePromise) {
      return existing.voicePromise;
    }
    return dispatch({
      types: [GET_VOICES, GET_VOICES__SUCCESS, GET_VOICES__FAIL],
      promise: function promise(client) {
        return client.get('/avatar/getVoices', {
          params: {
            aiProvider: aiProvider
          }
        });
      },
      aiProvider: aiProvider
    });
  };
};
var createVideo = exports.createVideo = function createVideo(_ref) {
  var tutorialId = _ref.tutorialId,
    stepIds = _ref.stepIds,
    customDescriptions = _ref.customDescriptions,
    avatarId = _ref.avatarId,
    voiceId = _ref.voiceId,
    provider = _ref.provider,
    aiProvider = _ref.aiProvider;
  return function (dispatch, getState) {
    return dispatch({
      types: [CREATE_VIDEO, CREATE_VIDEO__SUCCESS, CREATE_VIDEO__FAIL],
      promise: function promise(client) {
        return client.post('/avatar/createVideo', {
          data: {
            tutorialId: tutorialId,
            stepIds: stepIds,
            customDescriptions: customDescriptions || getState().editor.video.text,
            avatarId: avatarId,
            voiceId: voiceId,
            provider: provider,
            aiProvider: aiProvider
          }
        });
      },
      tutorialId: tutorialId,
      avatarId: avatarId,
      voiceId: voiceId,
      aiProvider: aiProvider
    });
  };
};
var getVideo = exports.getVideo = function getVideo(tutorialId, stepId, videoId, counter) {
  return {
    types: [GET_VIDEO, GET_VIDEO__SUCCESS, GET_VIDEO__FAIL],
    promise: function promise(client) {
      return client.post('/avatar/getVideo', {
        data: {
          tutorialId: tutorialId,
          stepId: stepId,
          videoId: videoId,
          counter: counter
        }
      });
    },
    tutorialId: tutorialId,
    stepId: stepId,
    videoId: videoId
  };
};