"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.loadBundle = exports.getResolvedBundles = exports.getRenderedLocation = exports.default = void 0;
exports.loadLazyModule = loadLazyModule;
exports.setRenderedLocation = void 0;
var _immutable = require("immutable");
var _module = require("../../../utils/module");
var _promises = require("../../../utils/promises");
function _typeof(o) { "@babel/helpers - typeof"; return _typeof = "function" == typeof Symbol && "symbol" == typeof Symbol.iterator ? function (o) { return typeof o; } : function (o) { return o && "function" == typeof Symbol && o.constructor === Symbol && o !== Symbol.prototype ? "symbol" : typeof o; }, _typeof(o); }
function ownKeys(e, r) { var t = Object.keys(e); if (Object.getOwnPropertySymbols) { var o = Object.getOwnPropertySymbols(e); r && (o = o.filter(function (r) { return Object.getOwnPropertyDescriptor(e, r).enumerable; })), t.push.apply(t, o); } return t; }
function _objectSpread(e) { for (var r = 1; r < arguments.length; r++) { var t = null != arguments[r] ? arguments[r] : {}; r % 2 ? ownKeys(Object(t), !0).forEach(function (r) { _defineProperty(e, r, t[r]); }) : Object.getOwnPropertyDescriptors ? Object.defineProperties(e, Object.getOwnPropertyDescriptors(t)) : ownKeys(Object(t)).forEach(function (r) { Object.defineProperty(e, r, Object.getOwnPropertyDescriptor(t, r)); }); } return e; }
function _defineProperty(e, r, t) { return (r = _toPropertyKey(r)) in e ? Object.defineProperty(e, r, { value: t, enumerable: !0, configurable: !0, writable: !0 }) : e[r] = t, e; }
function _toPropertyKey(t) { var i = _toPrimitive(t, "string"); return "symbol" == _typeof(i) ? i : i + ""; }
function _toPrimitive(t, r) { if ("object" != _typeof(t) || !t) return t; var e = t[Symbol.toPrimitive]; if (void 0 !== e) { var i = e.call(t, r || "default"); if ("object" != _typeof(i)) return i; throw new TypeError("@@toPrimitive must return a primitive value."); } return ("string" === r ? String : Number)(t); }
var type = (0, _module.namespaceType)('nonSerializable/bundleLoader/');
var BUNDLE_LOADED = type('BUNDLE_LOADED');
var SET_RENDERED_LOCATION = type('SET_RENDERED_LOCATION');
function loadLazyModule(lazyModule) {
  return new Promise(function (resolve) {
    if (__SERVER__) {
      // on server-side they are regular modules
      resolve(lazyModule);
      return;
    }
    lazyModule(function (module) {
      resolve(module);
    });
  });
}
var initialState = {
  // key is a bundle function, values is a result (the loaded module)
  resolvedBundles: new _immutable.Map(),
  renderedLocation: null
};
var actions = _defineProperty(_defineProperty({}, BUNDLE_LOADED, function (state, action) {
  return _objectSpread(_objectSpread({}, state), {}, {
    resolvedBundles: state.resolvedBundles.set(action.bundle, action.module)
  });
}), SET_RENDERED_LOCATION, function (state, action) {
  return _objectSpread(_objectSpread({}, state), {}, {
    renderedLocation: action.value
  });
});
var reducer = (0, _module.createReducer)(actions, initialState);
var _default = exports.default = reducer;
var loadBundle = exports.loadBundle = function loadBundle(bundle) {
  return function (dispatch) {
    return loadLazyModule(bundle).then((0, _promises.tap)(function (module) {
      return dispatch({
        type: BUNDLE_LOADED,
        bundle: bundle,
        module: module
      });
    }));
  };
};
var setRenderedLocation = exports.setRenderedLocation = function setRenderedLocation(value) {
  return {
    type: SET_RENDERED_LOCATION,
    value: value
  };
};
var getLocalState = function getLocalState(state) {
  return state.nonSerializable.bundleLoader;
};
var getResolvedBundles = exports.getResolvedBundles = function getResolvedBundles(state) {
  return getLocalState(state).resolvedBundles;
};
var getRenderedLocation = exports.getRenderedLocation = function getRenderedLocation(state) {
  return getLocalState(state).renderedLocation || state.router.location;
};