"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.getActionError = getActionError;
exports.getActionErrorObject = getActionErrorObject;
var _isObject = _interopRequireDefault(require("lodash/isObject"));
function _interopRequireDefault(e) { return e && e.__esModule ? e : { default: e }; }
function getActionError(action) {
  var type = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : 'other';
  if (type === 'other') {
    return (0, _isObject.default)(action.error) ? undefined : action.error;
  }
  return (0, _isObject.default)(action.error) ? action.error[type] : undefined;
}
function getActionErrorObject(action) {
  if (!action.error) return {};
  if ((0, _isObject.default)(action.error)) return action.error;
  return {
    other: action.error
  };
}