"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
var _redux = require("redux");
var _connectedReactRouter = require("connected-react-router");
var _reduxConnect = require("redux-connect");
var _notifications = _interopRequireDefault(require("./notifications"));
var _auth = _interopRequireDefault(require("./auth"));
var _account = _interopRequireDefault(require("./account"));
var _reduxForm = require("redux-form");
var _inviteEmail = _interopRequireDefault(require("./inviteEmail"));
var _roleInviteEmail = _interopRequireDefault(require("./roleInviteEmail"));
var _tutorials = _interopRequireDefault(require("./tutorials"));
var _widgets = _interopRequireDefault(require("./widgets"));
var _sidebar = _interopRequireDefault(require("./sidebar"));
var _header = _interopRequireDefault(require("./header"));
var _footer = _interopRequireDefault(require("./footer"));
var _shareAccess = _interopRequireDefault(require("./shareAccess"));
var _roleShareAccess = _interopRequireDefault(require("./roleShareAccess"));
var _editor = _interopRequireDefault(require("./editor"));
var _player = _interopRequireDefault(require("./player"));
var _shareProcedure = _interopRequireDefault(require("./shareProcedure"));
var _creatorProcedure = _interopRequireDefault(require("./creatorProcedure"));
var _learnerProcedure = _interopRequireDefault(require("./learnerProcedure"));
var _specialProcedure = _interopRequireDefault(require("./specialProcedure"));
var _contactus = _interopRequireDefault(require("./contactus"));
var _questionnaireUpgrade = _interopRequireDefault(require("./questionnaireUpgrade"));
var _integrationConnections = _interopRequireDefault(require("./integrationConnections"));
var _plan = _interopRequireDefault(require("./plan"));
var _planTable = _interopRequireDefault(require("./planTable"));
var _planInvitations = _interopRequireDefault(require("./planInvitations"));
var _checkout = _interopRequireDefault(require("./checkout"));
var _hubspot = _interopRequireDefault(require("./hubspot"));
var _user = _interopRequireDefault(require("./user"));
var _admin = _interopRequireDefault(require("./admin"));
var _manualInvoices = _interopRequireDefault(require("./manualInvoices"));
var _siteError = _interopRequireDefault(require("./siteError"));
var _subscriberTable = _interopRequireDefault(require("./subscriberTable"));
var _integrationsTable = _interopRequireDefault(require("./integrationsTable"));
var _tutorialAuditTable = _interopRequireDefault(require("./tutorialAuditTable"));
var _tutorialAccessAuditTable = _interopRequireDefault(require("./tutorialAccessAuditTable"));
var _creatorAuditTable = _interopRequireDefault(require("./creatorAuditTable"));
var _adminAuditTable = _interopRequireDefault(require("./adminAuditTable"));
var _tutorialsEmbedStatisticsTable = _interopRequireDefault(require("./tutorialsEmbedStatisticsTable"));
var _tutorialStatsTable = _interopRequireDefault(require("./tutorialStatsTable"));
var _dialog = _interopRequireDefault(require("./dialog"));
var _apiLoading = _interopRequireDefault(require("./apiLoading"));
var _pricing = _interopRequireDefault(require("./pricing"));
var _settings = _interopRequireDefault(require("./settings"));
var _feedback = _interopRequireDefault(require("./feedback"));
var _extension = _interopRequireDefault(require("./extension"));
var _helpCenter = _interopRequireDefault(require("./help-center"));
var _nonSerializable = _interopRequireDefault(require("./nonSerializable"));
var _home = _interopRequireDefault(require("./home"));
var _window = _interopRequireDefault(require("./window"));
var _userNotifications = _interopRequireDefault(require("./userNotifications"));
var _userRoles = _interopRequireDefault(require("./userRoles"));
var _publicHelpCenter = _interopRequireDefault(require("./publicHelpCenter"));
var _locationExtra = _interopRequireDefault(require("./locationExtra"));
var _baseThemes = _interopRequireDefault(require("./baseThemes"));
var _applyEmail = _interopRequireDefault(require("./applyEmail"));
var _social = _interopRequireDefault(require("./social"));
var _avatar = _interopRequireDefault(require("./avatar"));
var _translate = _interopRequireDefault(require("./translate"));
var _aiassistant = _interopRequireDefault(require("./aiassistant"));
var _integrations = _interopRequireDefault(require("./integrations"));
function _interopRequireDefault(e) { return e && e.__esModule ? e : { default: e }; }
var createRootReducer = function createRootReducer(history) {
  return (0, _redux.combineReducers)({
    lastAction: function lastAction() {
      var state = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : null;
      var action = arguments.length > 1 ? arguments[1] : undefined;
      return action;
    },
    router: (0, _connectedReactRouter.connectRouter)(history),
    reduxAsyncConnect: _reduxConnect.reducer,
    auth: _auth.default,
    account: _account.default,
    admin: _admin.default,
    form: _reduxForm.reducer,
    inviteEmail: _inviteEmail.default,
    roleInviteEmail: _roleInviteEmail.default,
    tutorials: _tutorials.default,
    widgets: _widgets.default,
    notifications: _notifications.default,
    sidebar: _sidebar.default,
    header: _header.default,
    footer: _footer.default,
    shareAccess: _shareAccess.default,
    roleShareAccess: _roleShareAccess.default,
    manualInvoices: _manualInvoices.default,
    editor: _editor.default,
    player: _player.default,
    shareProcedure: _shareProcedure.default,
    creatorProcedure: _creatorProcedure.default,
    learnerProcedure: _learnerProcedure.default,
    specialProcedure: _specialProcedure.default,
    contactus: _contactus.default,
    questionnaireUpgrade: _questionnaireUpgrade.default,
    integrationConnections: _integrationConnections.default,
    checkout: _checkout.default,
    plan: _plan.default,
    planTable: _planTable.default,
    planInvitations: _planInvitations.default,
    hubspot: _hubspot.default,
    user: _user.default,
    siteError: _siteError.default,
    subscriberTable: _subscriberTable.default,
    integrationsTable: _integrationsTable.default,
    tutorialAuditTable: _tutorialAuditTable.default,
    tutorialAccessAuditTable: _tutorialAccessAuditTable.default,
    creatorAuditTable: _creatorAuditTable.default,
    adminAuditTable: _adminAuditTable.default,
    tutorialsEmbedStatisticsTable: _tutorialsEmbedStatisticsTable.default,
    tutorialStatsTable: _tutorialStatsTable.default,
    dialog: _dialog.default,
    apiLoading: _apiLoading.default,
    pricing: _pricing.default,
    settings: _settings.default,
    feedback: _feedback.default,
    extension: _extension.default,
    helpCenter: _helpCenter.default,
    publicHelpCenter: _publicHelpCenter.default,
    nonSerializable: _nonSerializable.default,
    home: _home.default,
    window: _window.default,
    userNotifications: _userNotifications.default,
    userRoles: _userRoles.default,
    locationExtra: _locationExtra.default,
    baseThemes: _baseThemes.default,
    applyEmail: _applyEmail.default,
    social: _social.default,
    translate: _translate.default,
    aiassistant: _aiassistant.default,
    integrations: _integrations.default,
    avatar: _avatar.default
  });
};
var _default = exports.default = createRootReducer;
module.exports = exports.default;