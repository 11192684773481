"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.profileTimeDuration = exports.debug = void 0;
function _typeof(o) { "@babel/helpers - typeof"; return _typeof = "function" == typeof Symbol && "symbol" == typeof Symbol.iterator ? function (o) { return typeof o; } : function (o) { return o && "function" == typeof Symbol && o.constructor === Symbol && o !== Symbol.prototype ? "symbol" : typeof o; }, _typeof(o); }
var colorReset = '\x1b[0m';
var colorGreen = '\x1b[32m';
var colorUnderscore = '\x1b[4m';
var colorDim = '\x1b[2m';
var profileStartTime = Date.now();
var profileLastMessageTime = profileStartTime;
var profileEventTimeMap = {};
function getStoredProfileTime() {
  try {
    return localStorage.getItem('__profileTime');
  } catch (err) {
    // 3rd party cookies/data are disabled
    console.error(err);
  }
}
var isProfilingEnabled = function isProfilingEnabled() {
  return (typeof window === "undefined" ? "undefined" : _typeof(window)) === 'object' ? getStoredProfileTime() === 'true' : process.env.__PROFILE_TIME === 'true';
};
var profilingEnabled = isProfilingEnabled();

// I use this to profile timings between arbitrary spots in code,
// to do this I just call this function from places between which I do a profiling
// and use the message to describe the place of code that is is called from
var profileTimeDuration = exports.profileTimeDuration = function profileTimeDuration(profilingEvent) {
  return function (message) {
    if (!profilingEnabled) return;
    var messageTime = Date.now();
    var lastEventMessageTime = profileEventTimeMap[profilingEvent] ? profileEventTimeMap[profilingEvent] : messageTime;
    if ((typeof window === "undefined" ? "undefined" : _typeof(window)) === 'object') {
      console.log("%cProfiling time, ".concat(profilingEvent, ": %c") + message, 'color: rgb(38, 169, 56)', 'color: rgb(0, 189, 26); font-weight: 600');
      console.log("%cTime passed: ".concat(messageTime - lastEventMessageTime, ". Since last message: ").concat(messageTime - profileLastMessageTime, ". %cSince start: ").concat(messageTime - profileStartTime), 'color: rgb(38, 169, 56)', 'color: rgb(193, 193, 193)');
    } else {
      console.log(colorGreen + '%s' + colorUnderscore + '%s' + colorReset, "Profiling time, ".concat(profilingEvent, ": "), message);
      console.log(colorGreen + '%s' + colorReset + colorDim + '%s' + colorReset, "Time passed: ".concat(messageTime - lastEventMessageTime, ". "), "Since last message: ".concat(messageTime - profileLastMessageTime, ". Since start: ").concat(messageTime - profileStartTime));
    }
    profileEventTimeMap[profilingEvent] = profileLastMessageTime = messageTime;
  };
};
var debug = exports.debug = function debug() {
  var _console;
  return (_console = console).debug.apply(_console, arguments);
};