"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.listCustomDomains = exports.deleteCustomDomain = exports.default = exports.addNewCustomDomain = void 0;
var _module = require("../../../utils/module");
function _typeof(o) { "@babel/helpers - typeof"; return _typeof = "function" == typeof Symbol && "symbol" == typeof Symbol.iterator ? function (o) { return typeof o; } : function (o) { return o && "function" == typeof Symbol && o.constructor === Symbol && o !== Symbol.prototype ? "symbol" : typeof o; }, _typeof(o); }
function _toConsumableArray(r) { return _arrayWithoutHoles(r) || _iterableToArray(r) || _unsupportedIterableToArray(r) || _nonIterableSpread(); }
function _nonIterableSpread() { throw new TypeError("Invalid attempt to spread non-iterable instance.\nIn order to be iterable, non-array objects must have a [Symbol.iterator]() method."); }
function _unsupportedIterableToArray(r, a) { if (r) { if ("string" == typeof r) return _arrayLikeToArray(r, a); var t = {}.toString.call(r).slice(8, -1); return "Object" === t && r.constructor && (t = r.constructor.name), "Map" === t || "Set" === t ? Array.from(r) : "Arguments" === t || /^(?:Ui|I)nt(?:8|16|32)(?:Clamped)?Array$/.test(t) ? _arrayLikeToArray(r, a) : void 0; } }
function _iterableToArray(r) { if ("undefined" != typeof Symbol && null != r[Symbol.iterator] || null != r["@@iterator"]) return Array.from(r); }
function _arrayWithoutHoles(r) { if (Array.isArray(r)) return _arrayLikeToArray(r); }
function _arrayLikeToArray(r, a) { (null == a || a > r.length) && (a = r.length); for (var e = 0, n = Array(a); e < a; e++) n[e] = r[e]; return n; }
function ownKeys(e, r) { var t = Object.keys(e); if (Object.getOwnPropertySymbols) { var o = Object.getOwnPropertySymbols(e); r && (o = o.filter(function (r) { return Object.getOwnPropertyDescriptor(e, r).enumerable; })), t.push.apply(t, o); } return t; }
function _objectSpread(e) { for (var r = 1; r < arguments.length; r++) { var t = null != arguments[r] ? arguments[r] : {}; r % 2 ? ownKeys(Object(t), !0).forEach(function (r) { _defineProperty(e, r, t[r]); }) : Object.getOwnPropertyDescriptors ? Object.defineProperties(e, Object.getOwnPropertyDescriptors(t)) : ownKeys(Object(t)).forEach(function (r) { Object.defineProperty(e, r, Object.getOwnPropertyDescriptor(t, r)); }); } return e; }
function _defineProperty(e, r, t) { return (r = _toPropertyKey(r)) in e ? Object.defineProperty(e, r, { value: t, enumerable: !0, configurable: !0, writable: !0 }) : e[r] = t, e; }
function _toPropertyKey(t) { var i = _toPrimitive(t, "string"); return "symbol" == _typeof(i) ? i : i + ""; }
function _toPrimitive(t, r) { if ("object" != _typeof(t) || !t) return t; var e = t[Symbol.toPrimitive]; if (void 0 !== e) { var i = e.call(t, r || "default"); if ("object" != _typeof(i)) return i; throw new TypeError("@@toPrimitive must return a primitive value."); } return ("string" === r ? String : Number)(t); }
var type = (0, _module.namespaceType)('redux/integrations/custom-domain/');
var ADD_CUSTOM_DOMAIN = type('ADD_CUSTOM_DOMAIN');
var ADD_CUSTOM_DOMAIN__SUCCESS = type('ADD_CUSTOM_DOMAIN__SUCCESS');
var ADD_CUSTOM_DOMAIN__FAIL = type('ADD_CUSTOM_DOMAIN__FAIL');
var DELETE_CUSTOM_DOMAIN = type('DELETE_CUSTOM_DOMAIN');
var DELETE_CUSTOM_DOMAIN__SUCCESS = type('DELETE_CUSTOM_DOMAIN__SUCCESS');
var DELETE_CUSTOM_DOMAIN__FAIL = type('DELETE_CUSTOM_DOMAIN__FAIL');
var LIST_CUSTOM_DOMAIN = type('LIST_CUSTOM_DOMAIN');
var LIST_CUSTOM_DOMAIN__SUCCESS = type('LIST_CUSTOM_DOMAIN__SUCCESS');
var LIST_CUSTOM_DOMAIN__FAIL = type('LIST_CUSTOM_DOMAIN__FAIL');
var initialState = {
  loading: false,
  error: null,
  domains: []
};
var actions = _defineProperty(_defineProperty(_defineProperty(_defineProperty(_defineProperty(_defineProperty(_defineProperty(_defineProperty(_defineProperty({}, ADD_CUSTOM_DOMAIN, function (state) {
  return _objectSpread(_objectSpread({}, state), {}, {
    error: null,
    loading: true
  });
}), ADD_CUSTOM_DOMAIN__SUCCESS, function (state, action) {
  return _objectSpread(_objectSpread({}, state), {}, {
    loading: false,
    error: null,
    domains: [].concat(_toConsumableArray(state.domains), [action.result])
  });
}), ADD_CUSTOM_DOMAIN__FAIL, function (state, action) {
  return _objectSpread(_objectSpread({}, state), {}, {
    loading: false,
    error: action.error
  });
}), DELETE_CUSTOM_DOMAIN, function (state) {
  return _objectSpread(_objectSpread({}, state), {}, {
    error: null,
    loading: true
  });
}), DELETE_CUSTOM_DOMAIN__SUCCESS, function (state, action) {
  return _objectSpread(_objectSpread({}, state), {}, {
    loading: false,
    error: null,
    domains: state.domains.filter(function (x) {
      return x.unique_id !== action.data.id;
    })
  });
}), DELETE_CUSTOM_DOMAIN__FAIL, function (state, action) {
  return _objectSpread(_objectSpread({}, state), {}, {
    loading: false,
    error: action.error
  });
}), LIST_CUSTOM_DOMAIN, function (state) {
  return _objectSpread(_objectSpread({}, state), {}, {
    error: null,
    loading: true
  });
}), LIST_CUSTOM_DOMAIN__SUCCESS, function (state, action) {
  return _objectSpread(_objectSpread({}, state), {}, {
    loading: false,
    error: null,
    domains: action.result
  });
}), LIST_CUSTOM_DOMAIN__FAIL, function (state, action) {
  return _objectSpread(_objectSpread({}, state), {}, {
    loading: false,
    error: action.error
  });
});
var reducer = (0, _module.createReducer)(actions, initialState);
var _default = exports.default = reducer;
var addNewCustomDomain = exports.addNewCustomDomain = function addNewCustomDomain(data) {
  return {
    types: [ADD_CUSTOM_DOMAIN, ADD_CUSTOM_DOMAIN__SUCCESS, ADD_CUSTOM_DOMAIN__FAIL],
    promise: function promise(client) {
      return client.post('/integrations/domains/add', {
        data: data
      });
    },
    data: data
  };
};
var deleteCustomDomain = exports.deleteCustomDomain = function deleteCustomDomain(data) {
  return {
    types: [DELETE_CUSTOM_DOMAIN, DELETE_CUSTOM_DOMAIN__SUCCESS, DELETE_CUSTOM_DOMAIN__FAIL],
    promise: function promise(client) {
      return client.del('/integrations/domains/remove', {
        data: data
      });
    },
    data: data
  };
};
var listCustomDomains = exports.listCustomDomains = function listCustomDomains(params) {
  return {
    types: [LIST_CUSTOM_DOMAIN, LIST_CUSTOM_DOMAIN__SUCCESS, LIST_CUSTOM_DOMAIN__FAIL],
    promise: function promise(client) {
      return client.get('/integrations/domains/list', {
        params: params
      });
    },
    params: params
  };
};