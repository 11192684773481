"use strict";

var commonConfig = require('../common/config');
var merge = require('lodash/merge');
var isNil = require('lodash/isNil');
var processEnv = require('../common/process-env');
module.exports = merge({}, commonConfig.config);

// expose the env key to the whitelist here to be exclusively exposed to the client
// WARNING: Never ever expose the server keys, only expose the key the clientside code needs
// [{key: defaultValue}]
var clientEnvWhiteList = {
  'NODE_ENV': processEnv.NODE_ENV,
  'API_HOST': commonConfig.config.apiHost,
  'API_PORT': commonConfig.config.apiPort,
  'HOST': commonConfig.config.host,
  'CDN_HOST': commonConfig.config.cdnHost,
  'MAILER_EMAIL_HOST': commonConfig.config.mailerEmailHost,
  'EXTENSION_START_HOST': commonConfig.config.extensionStartHost,
  'VIDEO_HOST': commonConfig.config.videoHost,
  'CRAWLER_HOST': commonConfig.config.crawlerHost,
  'LANDING_HOST': commonConfig.config.landingHost,
  'STATUS_HOST': commonConfig.config.statusHost,
  'DEFAULT_MONTHLY_PLAN': commonConfig.config.defaultMonthlyPlan,
  'DEFAULT_YEARLY_PLAN': commonConfig.config.defaultYearlyPlan,
  'GOOGLE_ANALYTICS_SITE_ID': commonConfig.config.googleAnalyticsSiteId,
  'GOOGLE_CLIENT_ID': commonConfig.config.googleClientId,
  'GOOGLE_EXTENSION_ID': commonConfig.config.googleExtensionId,
  'GDRIVE_CLIENT_ID': commonConfig.config.gdriveClientId,
  'GDRIVE_PICKER_API_KEY': commonConfig.config.gdrivePickerApiKey,
  'INCLUDE_LIVE_EMBED': commonConfig.config.includeLiveEmbed,
  'LIVE_EMBED_USER_ID': commonConfig.config.liveEmbedUserId,
  'LIVE_EMBED_TUTORIAL_ID': commonConfig.config.liveEmbedTutorialId,
  'LIVE_EMBED_DEFAULT_OPTIONS_COLORS_MAIN': commonConfig.config.liveEmbedDefaultOptions.colors.main,
  'LIVE_EMBED_DEFAULT_OPTIONS_COLORS_MAIN_FONT': commonConfig.config.liveEmbedDefaultOptions.colors.mainFont,
  'LIVE_EMBED_DEFAULT_OPTIONS_COLORS_CLOSE_BUTTON': commonConfig.config.liveEmbedDefaultOptions.colors.closeButton,
  'LIVE_EMBED_DEFAULT_OPTIONS_COLORS_CLOSE_BUTTON_FONT': commonConfig.config.liveEmbedDefaultOptions.colors.closeButtonFont,
  'LIVE_EMBED_DEFAULT_OPTIONS_COLORS_NAV_BAR': commonConfig.config.liveEmbedDefaultOptions.colors.navBar,
  'LIVE_EMBED_DEFAULT_OPTIONS_COLORS_ACTION_BUTTON': commonConfig.config.liveEmbedDefaultOptions.colors.actionButton,
  'LIVE_EMBED_DEFAULT_OPTIONS_COLORS_HIGH_LIGHT': commonConfig.config.liveEmbedDefaultOptions.colors.highlight,
  'LIVE_EMBED_DEFAULT_OPTIONS_COLORS_FONT': commonConfig.config.liveEmbedDefaultOptions.colors.font,
  'LIVE_EMBED_DEFAULT_OPTIONS_COMBINE_HUBSPOT': commonConfig.config.liveEmbedDefaultOptions.combineHubspot,
  'LIVE_EMBED_DEFAULT_OPTIONS_POSITION': commonConfig.config.liveEmbedDefaultOptions.position,
  'INTERCOM_APP_CLIENT_ID': commonConfig.config.intercomAppClientId,
  'INTERCOM_ID': commonConfig.config.intercomId,
  'HUBSPOT_ID': commonConfig.config.hubspotId,
  'RECAPTCHA_V2_SITE_KEY': commonConfig.config.recaptchaV2SiteKey,
  'RECAPTCHA_V3_SITE_KEY': commonConfig.config.recaptchaV3SiteKey
};

// build the simulated processEnv for the client to consume
module.exports.getClientExposedProcessEnv = function () {
  var exposedProcessEnv = {};
  Object.keys(clientEnvWhiteList).forEach(function (key) {
    var defaultValue = clientEnvWhiteList[key];
    var definedValue = processEnv[key];
    if (isNil(definedValue)) {
      exposedProcessEnv[key] = defaultValue;
    } else {
      exposedProcessEnv[key] = definedValue;
    }
  });
  return exposedProcessEnv;
};