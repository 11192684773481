"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.checkMenuSelected = void 0;
var _escapeRegExp = _interopRequireDefault(require("lodash/escapeRegExp"));
function _interopRequireDefault(e) { return e && e.__esModule ? e : { default: e }; }
var checkMenuSelected = exports.checkMenuSelected = function checkMenuSelected(renderedLocation, linkTo) {
  if (linkTo === '/createNewTutorial') {
    return renderedLocation.pathname.match('editor') || renderedLocation.pathname.match('audioEditor') || renderedLocation.pathname.match('videoEditor') || renderedLocation.pathname.match('musicEditor');
  }
  if (linkTo === '/termsconditions') {
    return renderedLocation.pathname.match('privacypolicy') || renderedLocation.pathname.match('termsconditions');
  }
  if (linkTo === '/customers') {
    return renderedLocation.pathname === '/customers';
  }
  if (linkTo === '/pricing') {
    return renderedLocation.pathname === '/pricing';
  }
  if (linkTo === '/') {
    return renderedLocation.pathname === '/';
  }

  // if (isPublicHelpCenterUrl(linkTo) && isPublicHelpCenterDomain(hostname)) {
  //   return true;
  // }

  var re = new RegExp((0, _escapeRegExp.default)(linkTo), 'gi');
  return renderedLocation.pathname.match(re);
};