"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = createStore;
var _redux = require("redux");
var _clientMiddleware = _interopRequireDefault(require("./middleware/clientMiddleware"));
var _reducer = _interopRequireDefault(require("./modules/reducer"));
var _connectedReactRouter = require("connected-react-router");
function _interopRequireDefault(e) { return e && e.__esModule ? e : { default: e }; }
function _toConsumableArray(r) { return _arrayWithoutHoles(r) || _iterableToArray(r) || _unsupportedIterableToArray(r) || _nonIterableSpread(); }
function _nonIterableSpread() { throw new TypeError("Invalid attempt to spread non-iterable instance.\nIn order to be iterable, non-array objects must have a [Symbol.iterator]() method."); }
function _unsupportedIterableToArray(r, a) { if (r) { if ("string" == typeof r) return _arrayLikeToArray(r, a); var t = {}.toString.call(r).slice(8, -1); return "Object" === t && r.constructor && (t = r.constructor.name), "Map" === t || "Set" === t ? Array.from(r) : "Arguments" === t || /^(?:Ui|I)nt(?:8|16|32)(?:Clamped)?Array$/.test(t) ? _arrayLikeToArray(r, a) : void 0; } }
function _iterableToArray(r) { if ("undefined" != typeof Symbol && null != r[Symbol.iterator] || null != r["@@iterator"]) return Array.from(r); }
function _arrayWithoutHoles(r) { if (Array.isArray(r)) return _arrayLikeToArray(r); }
function _arrayLikeToArray(r, a) { (null == a || a > r.length) && (a = r.length); for (var e = 0, n = Array(a); e < a; e++) n[e] = r[e]; return n; }
function _typeof(o) { "@babel/helpers - typeof"; return _typeof = "function" == typeof Symbol && "symbol" == typeof Symbol.iterator ? function (o) { return typeof o; } : function (o) { return o && "function" == typeof Symbol && o.constructor === Symbol && o !== Symbol.prototype ? "symbol" : typeof o; }, _typeof(o); }
function createStore(history, clients, data) {
  var extraMiddleware = arguments.length > 3 && arguments[3] !== undefined ? arguments[3] : [];
  // Sync dispatched route actions to the history
  var reduxRouterMiddleware = (0, _connectedReactRouter.routerMiddleware)(history);
  var middleware = [(0, _clientMiddleware.default)(clients), reduxRouterMiddleware].concat(extraMiddleware);
  var composeEnhancers = (typeof window === "undefined" ? "undefined" : _typeof(window)) === 'object' && window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || _redux.compose;
  var finalCreateStore;
  if (__DEVELOPMENT__ && __CLIENT__) {
    finalCreateStore = composeEnhancers(_redux.applyMiddleware.apply(void 0, _toConsumableArray(middleware)))(_redux.createStore);
  } else {
    finalCreateStore = _redux.applyMiddleware.apply(void 0, _toConsumableArray(middleware))(_redux.createStore);
  }
  var reducer = (0, _reducer.default)(history);
  var store = finalCreateStore(reducer, data);
  if (__DEVELOPMENT__ && module.hot) {
    module.hot.accept('./modules/reducer', function () {
      store.replaceReducer(require('./modules/reducer'));
    });
  }
  if ((typeof window === "undefined" ? "undefined" : _typeof(window)) === 'object' && __DEVELOPMENT__) {
    window.__reduxStore = store;
  }
  return store;
}
module.exports = exports.default;