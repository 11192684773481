"use strict";

function _typeof(o) { "@babel/helpers - typeof"; return _typeof = "function" == typeof Symbol && "symbol" == typeof Symbol.iterator ? function (o) { return typeof o; } : function (o) { return o && "function" == typeof Symbol && o.constructor === Symbol && o !== Symbol.prototype ? "symbol" : typeof o; }, _typeof(o); }
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.createStripeToken = void 0;
exports.handleResendActivation = handleResendActivation;
exports.onCheckoutSubmit = onCheckoutSubmit;
exports.successRedirect = void 0;
var _account = require("./account");
var _tracking = require("./tracking");
var _checkout = require("./checkout");
var _pick = _interopRequireDefault(require("lodash/fp/pick"));
var _mapValues = _interopRequireDefault(require("lodash/fp/mapValues"));
var _dayjs = _interopRequireDefault(require("dayjs"));
var _urls = require("./urls");
function _interopRequireDefault(e) { return e && e.__esModule ? e : { default: e }; }
function ownKeys(e, r) { var t = Object.keys(e); if (Object.getOwnPropertySymbols) { var o = Object.getOwnPropertySymbols(e); r && (o = o.filter(function (r) { return Object.getOwnPropertyDescriptor(e, r).enumerable; })), t.push.apply(t, o); } return t; }
function _objectSpread(e) { for (var r = 1; r < arguments.length; r++) { var t = null != arguments[r] ? arguments[r] : {}; r % 2 ? ownKeys(Object(t), !0).forEach(function (r) { _defineProperty(e, r, t[r]); }) : Object.getOwnPropertyDescriptors ? Object.defineProperties(e, Object.getOwnPropertyDescriptors(t)) : ownKeys(Object(t)).forEach(function (r) { Object.defineProperty(e, r, Object.getOwnPropertyDescriptor(t, r)); }); } return e; }
function _defineProperty(e, r, t) { return (r = _toPropertyKey(r)) in e ? Object.defineProperty(e, r, { value: t, enumerable: !0, configurable: !0, writable: !0 }) : e[r] = t, e; }
function _toPropertyKey(t) { var i = _toPrimitive(t, "string"); return "symbol" == _typeof(i) ? i : i + ""; }
function _toPrimitive(t, r) { if ("object" != _typeof(t) || !t) return t; var e = t[Symbol.toPrimitive]; if (void 0 !== e) { var i = e.call(t, r || "default"); if ("object" != _typeof(i)) return i; throw new TypeError("@@toPrimitive must return a primitive value."); } return ("string" === r ? String : Number)(t); }
function _slicedToArray(r, e) { return _arrayWithHoles(r) || _iterableToArrayLimit(r, e) || _unsupportedIterableToArray(r, e) || _nonIterableRest(); }
function _nonIterableRest() { throw new TypeError("Invalid attempt to destructure non-iterable instance.\nIn order to be iterable, non-array objects must have a [Symbol.iterator]() method."); }
function _unsupportedIterableToArray(r, a) { if (r) { if ("string" == typeof r) return _arrayLikeToArray(r, a); var t = {}.toString.call(r).slice(8, -1); return "Object" === t && r.constructor && (t = r.constructor.name), "Map" === t || "Set" === t ? Array.from(r) : "Arguments" === t || /^(?:Ui|I)nt(?:8|16|32)(?:Clamped)?Array$/.test(t) ? _arrayLikeToArray(r, a) : void 0; } }
function _arrayLikeToArray(r, a) { (null == a || a > r.length) && (a = r.length); for (var e = 0, n = Array(a); e < a; e++) n[e] = r[e]; return n; }
function _iterableToArrayLimit(r, l) { var t = null == r ? null : "undefined" != typeof Symbol && r[Symbol.iterator] || r["@@iterator"]; if (null != t) { var e, n, i, u, a = [], f = !0, o = !1; try { if (i = (t = t.call(r)).next, 0 === l) { if (Object(t) !== t) return; f = !1; } else for (; !(f = (e = i.call(t)).done) && (a.push(e.value), a.length !== l); f = !0); } catch (r) { o = !0, n = r; } finally { try { if (!f && null != t.return && (u = t.return(), Object(u) !== u)) return; } finally { if (o) throw n; } } return a; } }
function _arrayWithHoles(r) { if (Array.isArray(r)) return r; }
var createStripeToken = exports.createStripeToken = function createStripeToken(Stripe, fields, email) {
  return new Promise(function (resolve, reject) {
    var _split$slice = (fields.expDate.value || '').split(/(\d{2})\/?(\d{4}|\d{2})/).slice(1, 3),
      _split$slice2 = _slicedToArray(_split$slice, 2),
      _split$slice2$ = _split$slice2[0],
      expMonth = _split$slice2$ === void 0 ? '' : _split$slice2$,
      _split$slice2$2 = _split$slice2[1],
      expYear = _split$slice2$2 === void 0 ? '' : _split$slice2$2;
    var stripeData = {
      number: fields.cardNumber.value || '',
      cvc: fields.cvc.value.trim() || '',
      exp_month: expMonth,
      exp_year: expYear,
      name: email
    };
    Stripe.card.createToken(stripeData, function (status, response) {
      response.error ? reject(response.error) : resolve(response);
    });
  });
};
var successRedirect = exports.successRedirect = function successRedirect(notificationSuccess, account, planPricing, newPlan, replace) {
  if (newPlan.isCustomFreePlan) {
    replace("/tutorials?msg=account-upgraded");
    return;
  }
  if (account && !(0, _account.isFreeUser)(account)) {
    var _planChanges = (0, _checkout.planChanges)(account, planPricing, newPlan, newPlan.userCount, newPlan.frequency),
      charge = _planChanges.charge;
    if (charge) {
      replace(newPlan.userCount > 1 ? "/creators/invitations?msg=upgrade-success-with-creators" : "/tutorials?msg=upgrade-success-no-creators");
      return;
    }
    var formattedDate = (0, _dayjs.default)(account.creditPeriodEnd).format('MMMM DD, YYYY');
    var message = "Account changes will be effective on ".concat(formattedDate);
    replace("/account/account?msg=custom&customMessage=".concat(encodeURIComponent(message)));
    return;
  }
  //console.log('UPGRADED PLAN NAME', newPlan.name);
  (0, _tracking.pushGoogleAnalyticsEvent)('upgradedToPremiumSuccess', {
    planName: newPlan.name
  });
  replace(newPlan.userCount > 1 ? "/creators/invitations?msg=upgrade-success-with-creators" : "/tutorials?msg=upgrade-success-no-creators");
};
function handleResendActivation(_ref) {
  var resendActivation = _ref.resendActivation,
    notificationSuccess = _ref.notificationSuccess,
    returnTo = _ref.returnTo;
  resendActivation({
    returnTo: returnTo
  }).then(function () {
    notificationSuccess({
      message: 'Verification email sent.'
    });
  });
}
function onCheckoutSubmit(_ref2, event) {
  var user = _ref2.user,
    account = _ref2.account,
    fields = _ref2.fields,
    notificationDanger = _ref2.notificationDanger,
    checkoutCart = _ref2.checkoutCart,
    checkout = _ref2.checkout,
    historyPush = _ref2.historyPush,
    location = _ref2.location,
    checkEmail = _ref2.checkEmail,
    plans = _ref2.plans,
    setStripeCheckoutInProgress = _ref2.setStripeCheckoutInProgress,
    selectedPlan = _ref2.selectedPlan,
    resendActivation = _ref2.resendActivation,
    notificationSuccess = _ref2.notificationSuccess,
    setPricingKey = _ref2.setPricingKey;
  if (event) event.preventDefault();
  var cardSkipped = checkout.cardSkipped;
  var userCount = selectedPlan.isCustomFreePlan ? selectedPlan.userLimit : (0, _checkout.curUserCount)(account, fields.userCount.value, selectedPlan);
  var frequency = (0, _checkout.curFrequency)(account, plans, location, fields);
  var sendActivation = function sendActivation() {
    return handleResendActivation({
      resendActivation: resendActivation,
      notificationSuccess: notificationSuccess,
      returnTo: location.pathname + location.search
    });
  };
  if (!user) {
    var query = (0, _urls.parseQuery)(location.search);
    var email = query.email,
      spi = query.spi,
      specialPlanId = query.specialPlanId,
      dc = query.dc;
    return Promise.resolve().then(checkEmail(email).then(function (userExists) {
      var pathname = !email || !!email && !userExists ? '/signup' : '/login';
      var returnTo = dc ? location.pathname + location.search : selectedPlan.isSpecialPlan && spi ? "/specialProcedure?spi=".concat(encodeURIComponent(spi)) : "/acceptSpecial?publicPlanId=".concat(encodeURIComponent(specialPlanId), "&dc=").concat(dc);
      historyPush({
        pathname: pathname,
        search: (0, _urls.queryParser)({
          returnTo: returnTo,
          email: email,
          spi: spi,
          specialPlanId: specialPlanId
        })
      });
    }));
  }
  if (user && !user.verified && !selectedPlan.isSpecialPlan) {
    notificationDanger({
      message: 'You need to verify your email.',
      action: {
        label: 'Resend',
        callback: sendActivation
      }
    });
    return Promise.reject({
      code: 'unverified_user',
      message: 'Unverified user',
      quiet: true
    });
  }
  if (selectedPlan.manualInvoice) {
    if (selectedPlan.id === account.planId) {
      return Promise.reject({
        code: 'special_upgrade_disallowed',
        message: 'Unable to make changes to a special plan.'
      });
    }
  }
  var activeCreatorsCount = (0, _account.accountToActiveCreatorsCount)(account);
  if (fields.userCount.value < activeCreatorsCount) {
    notificationDanger({
      message: "Your active creator count is greater than ".concat(fields.userCount.value, "."),
      action: {
        label: 'Deactivate',
        callback: function callback() {
          historyPush({
            pathname: '/creators/creators'
          });
        }
      }
    });
    return Promise.resolve();
  }
  setStripeCheckoutInProgress(true);
  var contactInfo = (0, _mapValues.default)(function (field) {
    return field.value;
  }, (0, _pick.default)(['first_name', 'last_name', 'address', 'company_name', 'zip_code', 'country'], fields));

  // send form here
  return Promise.resolve().then(function () {
    return !(0, _checkout.customerExists)(account) && !selectedPlan.isCustomFreePlan && !selectedPlan.manualInvoice && !(0, _checkout.isTrialCardSkipped)(account, plans, cardSkipped, frequency) ? createStripeToken(window.Stripe, fields, user ? user.email : '') : {
      id: '',
      email: user.email
    };
  } // use existing credit card
  ).then(function () {
    var res = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : {};
    setStripeCheckoutInProgress(false);
    return checkoutCart({
      token: res.id,
      email: res.email,
      user_count: userCount,
      plan_id: selectedPlan.id,
      discount_code: selectedPlan.discountCodeApplied,
      contactInfo: contactInfo
    });
  }).then(function () {
    return setPricingKey(selectedPlan.pricingKey);
  }).then(function () {
    var pds = ['id', 'basePrice', 'perUserPrice', 'isSpecialPlan', 'freeUsers', 'isCustomFreePlan', 'name', 'frequency'];
    return _objectSpread({
      // Return new plan data
      userCount: userCount
    }, (0, _pick.default)(pds, selectedPlan));
  }).catch(function (err) {
    setStripeCheckoutInProgress(false);
    return Promise.reject(err);
  });
}