"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.setWindowSize = exports.setIsListeningResize = exports.refreshWindowSize = exports.listenWindowResize = exports.initialState = exports.default = exports.SET_WINDOW_SIZE = void 0;
var _module = require("../../utils/module");
var _pick = _interopRequireDefault(require("lodash/fp/pick"));
function _interopRequireDefault(e) { return e && e.__esModule ? e : { default: e }; }
function _typeof(o) { "@babel/helpers - typeof"; return _typeof = "function" == typeof Symbol && "symbol" == typeof Symbol.iterator ? function (o) { return typeof o; } : function (o) { return o && "function" == typeof Symbol && o.constructor === Symbol && o !== Symbol.prototype ? "symbol" : typeof o; }, _typeof(o); }
function ownKeys(e, r) { var t = Object.keys(e); if (Object.getOwnPropertySymbols) { var o = Object.getOwnPropertySymbols(e); r && (o = o.filter(function (r) { return Object.getOwnPropertyDescriptor(e, r).enumerable; })), t.push.apply(t, o); } return t; }
function _objectSpread(e) { for (var r = 1; r < arguments.length; r++) { var t = null != arguments[r] ? arguments[r] : {}; r % 2 ? ownKeys(Object(t), !0).forEach(function (r) { _defineProperty(e, r, t[r]); }) : Object.getOwnPropertyDescriptors ? Object.defineProperties(e, Object.getOwnPropertyDescriptors(t)) : ownKeys(Object(t)).forEach(function (r) { Object.defineProperty(e, r, Object.getOwnPropertyDescriptor(t, r)); }); } return e; }
function _defineProperty(e, r, t) { return (r = _toPropertyKey(r)) in e ? Object.defineProperty(e, r, { value: t, enumerable: !0, configurable: !0, writable: !0 }) : e[r] = t, e; }
function _toPropertyKey(t) { var i = _toPrimitive(t, "string"); return "symbol" == _typeof(i) ? i : i + ""; }
function _toPrimitive(t, r) { if ("object" != _typeof(t) || !t) return t; var e = t[Symbol.toPrimitive]; if (void 0 !== e) { var i = e.call(t, r || "default"); if ("object" != _typeof(i)) return i; throw new TypeError("@@toPrimitive must return a primitive value."); } return ("string" === r ? String : Number)(t); }
var type = (0, _module.namespaceType)('redux/window/');
var SET_WINDOW_SIZE = exports.SET_WINDOW_SIZE = type('SET_WINDOW_SIZE');
var SET_IS_LISTENING_RESIZE = type('SET_IS_LISTENING_RESIZE');
var initialState = exports.initialState = {
  height: 0,
  width: 0,
  isListeningResize: false
};
var actions = _defineProperty(_defineProperty({}, SET_WINDOW_SIZE, function (state, action) {
  return _objectSpread(_objectSpread({}, state), (0, _pick.default)(['width', 'height'], action));
}), SET_IS_LISTENING_RESIZE, function (state, action) {
  return _objectSpread(_objectSpread({}, state), {}, {
    isListeningResize: action.isListeningResize
  });
});
var _default = exports.default = (0, _module.createReducer)(actions, initialState);
var setWindowSize = exports.setWindowSize = function setWindowSize(size) {
  return _objectSpread({
    type: SET_WINDOW_SIZE
  }, (0, _pick.default)(['width', 'height'], size));
};
var setIsListeningResize = exports.setIsListeningResize = function setIsListeningResize(isListeningResize) {
  return {
    type: SET_IS_LISTENING_RESIZE,
    isListeningResize: isListeningResize
  };
};
var refreshWindowSize = exports.refreshWindowSize = function refreshWindowSize(dispatch) {
  return dispatch(setWindowSize({
    height: window.innerHeight,
    width: window.innerWidth
  }));
};
var listenWindowResize = exports.listenWindowResize = function listenWindowResize() {
  return function (dispatch) {
    var listener = function listener() {
      return refreshWindowSize(dispatch);
    };
    window.addEventListener('resize', listener);
    return function () {
      return window.removeEventListener('resize', listener);
    };
  };
};