"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.updateCategoryChange = exports.types = exports.persistOptionMenu = exports.openOptionMenu = exports.getPersistedOptionMenu = exports.default = exports.closeAllOptionMenus = exports.clearPersistedOptionMenu = void 0;
var _stateModule = require("../../../utils/stateModule");
// The state of application for tutorial options menu
// Works only for single tutorial at the time, since these are requirements at the moment
var initialState = {
  tutorialIds: [] // which tutorials have option menu open
};
var _module = (0, _stateModule.createStateModule)({
  typeNamespace: 'tutorials/optionMenu/',
  initialState: initialState
});
var types = exports.types = _module.types;
var _default = exports.default = _module.reducer;
var updateCategoryChange = exports.updateCategoryChange = _module.actionCreators.updateState;

// CONFUSING: the redux action is called "open" but it actually sets the whole array
var openOptionMenu = exports.openOptionMenu = function openOptionMenu(tutorialIds) {
  return function (dispatch) {
    dispatch(updateCategoryChange({
      tutorialIds: tutorialIds
    }));
  };
};
var closeAllOptionMenus = exports.closeAllOptionMenus = function closeAllOptionMenus() {
  return function (dispatch) {
    dispatch(updateCategoryChange({
      tutorialIds: []
    }));
  };
};
var LOCAL_STORAGE_KEY = 'iorad/redux/moodules/tutorials/optionMenu/tutorialIds';
var persistOptionMenu = exports.persistOptionMenu = function persistOptionMenu() {
  return function (dispatch, getState) {
    var state = getState();
    var optionMenu = state.tutorials.optionMenu;
    var openedTutorialIds = optionMenu.tutorialIds;
    try {
      localStorage.setItem(LOCAL_STORAGE_KEY, JSON.stringify(openedTutorialIds));
    } catch (err) {
      // 3rd party cookies/data are disabled
      console.error(err);
    }
  };
};
var getPersistedOptionMenu = exports.getPersistedOptionMenu = function getPersistedOptionMenu() {
  return function (dispatch) {
    var openedTutorialIds;
    try {
      openedTutorialIds = JSON.parse(localStorage.getItem(LOCAL_STORAGE_KEY));
    } catch (err) {
      // 3rd party cookies/data are disabled or there is no persisted item
      console.error(err);
    }
    return openedTutorialIds;
  };
};
var clearPersistedOptionMenu = exports.clearPersistedOptionMenu = function clearPersistedOptionMenu() {
  return function (dispatch) {
    try {
      localStorage.removeItem(LOCAL_STORAGE_KEY);
    } catch (err) {
      // 3rd party cookies/data are disabled or there is no persisted item
      console.error(err);
    }
  };
};