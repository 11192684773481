"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.type = exports.FETCH_ACCOUNT_SUCCESS = void 0;
var _module = require("../../utils/module");
// shared types in a separate file for cycle dependency fix

var type = exports.type = (0, _module.namespaceType)('redux/account/');
var FETCH_ACCOUNT_SUCCESS = exports.FETCH_ACCOUNT_SUCCESS = type('FETCH_ACCOUNT_SUCCESS');