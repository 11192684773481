"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = header;
exports.setMoreNavShown = exports.setHideMenuBar = exports.setCustomClassName = exports.setAccountNavShown = exports.isLogoutView = exports.isHeaderVisible = exports.getTopNavBarHeight = void 0;
exports.toggleHeader = toggleHeader;
exports.toggleMenu = toggleMenu;
exports.toggleMenuAtBottom = toggleMenuAtBottom;
exports.toggleMenuInvert = toggleMenuInvert;
var _auth = require("./auth");
var _bundleLoader = require("./nonSerializable/bundleLoader");
function _typeof(o) { "@babel/helpers - typeof"; return _typeof = "function" == typeof Symbol && "symbol" == typeof Symbol.iterator ? function (o) { return typeof o; } : function (o) { return o && "function" == typeof Symbol && o.constructor === Symbol && o !== Symbol.prototype ? "symbol" : typeof o; }, _typeof(o); }
function ownKeys(e, r) { var t = Object.keys(e); if (Object.getOwnPropertySymbols) { var o = Object.getOwnPropertySymbols(e); r && (o = o.filter(function (r) { return Object.getOwnPropertyDescriptor(e, r).enumerable; })), t.push.apply(t, o); } return t; }
function _objectSpread(e) { for (var r = 1; r < arguments.length; r++) { var t = null != arguments[r] ? arguments[r] : {}; r % 2 ? ownKeys(Object(t), !0).forEach(function (r) { _defineProperty(e, r, t[r]); }) : Object.getOwnPropertyDescriptors ? Object.defineProperties(e, Object.getOwnPropertyDescriptors(t)) : ownKeys(Object(t)).forEach(function (r) { Object.defineProperty(e, r, Object.getOwnPropertyDescriptor(t, r)); }); } return e; }
function _defineProperty(e, r, t) { return (r = _toPropertyKey(r)) in e ? Object.defineProperty(e, r, { value: t, enumerable: !0, configurable: !0, writable: !0 }) : e[r] = t, e; }
function _toPropertyKey(t) { var i = _toPrimitive(t, "string"); return "symbol" == _typeof(i) ? i : i + ""; }
function _toPrimitive(t, r) { if ("object" != _typeof(t) || !t) return t; var e = t[Symbol.toPrimitive]; if (void 0 !== e) { var i = e.call(t, r || "default"); if ("object" != _typeof(i)) return i; throw new TypeError("@@toPrimitive must return a primitive value."); } return ("string" === r ? String : Number)(t); }
var TOGGLE_HEADER = 'redux/header/TOGGLE_HEADER';
var MENU_TOGGLE_DISABLED = 'redux/header/MENU_TOGGLE_DISABLED';
var MENU_TOGGLE_AT_BOTTOM = 'redux/header/MENU_TOGGLE_AT_BOTTOM';
var MENU_TOGGLE_INVERT = 'redux/header/MENU_TOGGLE_INVERT';
var SET_MORE_NAV_SHOWN = 'redux/header/SET_MORE_NAV_SHOWN';
var SET_ACCOUNT_NAV_SHOWN = 'redux/header/SET_ACCOUNT_NAV_SHOWN';
var SET_CUSTOM_CLASSNAME = 'redux/header/SET_CUSTOM_CLASSNAME';
var SET_HIDE_MENU_BAR = 'redux/header/SET_HIDE_MENU_BAR';
var initialState = {
  visible: true,
  menuToggleDisabled: false,
  menuToggleBottom: false,
  invertMenu: false,
  moreNavShown: false,
  accountNavShown: false,
  customClassName: '',
  hideMenuBar: false
};
function header() {
  var state = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : initialState;
  var action = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : {};
  switch (action.type) {
    case TOGGLE_HEADER:
      return _objectSpread(_objectSpread({}, state), {}, {
        visible: typeof action.showOrHide === 'boolean' ? action.showOrHide : !state.visible
      });
    case MENU_TOGGLE_DISABLED:
      return _objectSpread(_objectSpread({}, state), {}, {
        menuToggleDisabled: typeof action.showOrHide === 'boolean' ? action.showOrHide : !state.menuToggleDisabled
      });
    case MENU_TOGGLE_AT_BOTTOM:
      return _objectSpread(_objectSpread({}, state), {}, {
        menuToggleBottom: typeof action.showOrHide === 'boolean' ? action.showOrHide : !state.menuToggleBottom
      });
    case MENU_TOGGLE_INVERT:
      return _objectSpread(_objectSpread({}, state), {}, {
        menuToggleInvert: typeof action.invert === 'boolean' ? action.invert : !state.menuToggleInvert
      });
    case SET_MORE_NAV_SHOWN:
      return _objectSpread(_objectSpread({}, state), {}, {
        moreNavShown: action.moreNavShown
      }, action.moreNavShown ? {
        accountNavShown: false
      } : {});
    case SET_ACCOUNT_NAV_SHOWN:
      return _objectSpread(_objectSpread({}, state), {}, {
        accountNavShown: action.accountNavShown
      }, action.accountNavShown ? {
        moreNavShown: false
      } : {});
    case SET_CUSTOM_CLASSNAME:
      return _objectSpread(_objectSpread({}, state), {}, {
        customClassName: action.customClassName
      });
    case SET_HIDE_MENU_BAR:
      return _objectSpread(_objectSpread({}, state), {}, {
        hideMenuBar: action.hideMenuBar
      });
    default:
      return state;
  }
}
function toggleHeader(showOrHide) {
  return {
    type: TOGGLE_HEADER,
    showOrHide: showOrHide
  };
}
function toggleMenu(showOrHide) {
  return {
    type: MENU_TOGGLE_DISABLED,
    showOrHide: showOrHide
  };
}
function toggleMenuAtBottom(showOrHide) {
  return {
    type: MENU_TOGGLE_AT_BOTTOM,
    showOrHide: showOrHide
  };
}
function toggleMenuInvert(invert) {
  return {
    type: MENU_TOGGLE_INVERT,
    invert: invert
  };
}
var setMoreNavShown = exports.setMoreNavShown = function setMoreNavShown(moreNavShown) {
  return {
    type: SET_MORE_NAV_SHOWN,
    moreNavShown: moreNavShown
  };
};
var setAccountNavShown = exports.setAccountNavShown = function setAccountNavShown(accountNavShown) {
  return {
    type: SET_ACCOUNT_NAV_SHOWN,
    accountNavShown: accountNavShown
  };
};
var setCustomClassName = exports.setCustomClassName = function setCustomClassName(customClassName) {
  return {
    type: SET_CUSTOM_CLASSNAME,
    customClassName: customClassName
  };
};
var setHideMenuBar = exports.setHideMenuBar = function setHideMenuBar(hideMenuBar) {
  return {
    type: SET_HIDE_MENU_BAR,
    hideMenuBar: hideMenuBar
  };
};
var isLogoutView = exports.isLogoutView = function isLogoutView(state) {
  var renderedLocation = (0, _bundleLoader.getRenderedLocation)(state);
  return renderedLocation.pathname.startsWith('/login') || renderedLocation.pathname.startsWith('/signup');
};
var getTopNavBarHeight = exports.getTopNavBarHeight = function getTopNavBarHeight(state, collapsedMenu) {
  var user = (0, _auth.getUser)(state);
  return user ? 48 : state.window.width < 768 ? 58 : collapsedMenu ? 66 : 92;
};
var isHeaderVisible = exports.isHeaderVisible = function isHeaderVisible(state) {
  return state.header.visible;
};