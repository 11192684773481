"use strict";

function _typeof(o) { "@babel/helpers - typeof"; return _typeof = "function" == typeof Symbol && "symbol" == typeof Symbol.iterator ? function (o) { return typeof o; } : function (o) { return o && "function" == typeof Symbol && o.constructor === Symbol && o !== Symbol.prototype ? "symbol" : typeof o; }, _typeof(o); }
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.setterHandler = exports.nestReducers = exports.namespaceType = exports.makeRequestTypes = exports.makeRequestAction = exports.createReducer = exports.createNestedReducer = exports.addOrReplace = exports.actionCreator = void 0;
var _findIndex = _interopRequireDefault(require("lodash/findIndex"));
var _pick = _interopRequireDefault(require("lodash/pick"));
var _curry = _interopRequireDefault(require("lodash/curry"));
var _zip = _interopRequireDefault(require("lodash/zip"));
var _fromPairs = _interopRequireDefault(require("lodash/fromPairs"));
var _get = _interopRequireDefault(require("lodash/fp/get"));
var _set = _interopRequireDefault(require("lodash/fp/set"));
function _interopRequireDefault(e) { return e && e.__esModule ? e : { default: e }; }
function ownKeys(e, r) { var t = Object.keys(e); if (Object.getOwnPropertySymbols) { var o = Object.getOwnPropertySymbols(e); r && (o = o.filter(function (r) { return Object.getOwnPropertyDescriptor(e, r).enumerable; })), t.push.apply(t, o); } return t; }
function _objectSpread(e) { for (var r = 1; r < arguments.length; r++) { var t = null != arguments[r] ? arguments[r] : {}; r % 2 ? ownKeys(Object(t), !0).forEach(function (r) { _defineProperty(e, r, t[r]); }) : Object.getOwnPropertyDescriptors ? Object.defineProperties(e, Object.getOwnPropertyDescriptors(t)) : ownKeys(Object(t)).forEach(function (r) { Object.defineProperty(e, r, Object.getOwnPropertyDescriptor(t, r)); }); } return e; }
function _defineProperty(e, r, t) { return (r = _toPropertyKey(r)) in e ? Object.defineProperty(e, r, { value: t, enumerable: !0, configurable: !0, writable: !0 }) : e[r] = t, e; }
function _toPropertyKey(t) { var i = _toPrimitive(t, "string"); return "symbol" == _typeof(i) ? i : i + ""; }
function _toPrimitive(t, r) { if ("object" != _typeof(t) || !t) return t; var e = t[Symbol.toPrimitive]; if (void 0 !== e) { var i = e.call(t, r || "default"); if ("object" != _typeof(i)) return i; throw new TypeError("@@toPrimitive must return a primitive value."); } return ("string" === r ? String : Number)(t); }
function _toConsumableArray(r) { return _arrayWithoutHoles(r) || _iterableToArray(r) || _unsupportedIterableToArray(r) || _nonIterableSpread(); }
function _nonIterableSpread() { throw new TypeError("Invalid attempt to spread non-iterable instance.\nIn order to be iterable, non-array objects must have a [Symbol.iterator]() method."); }
function _unsupportedIterableToArray(r, a) { if (r) { if ("string" == typeof r) return _arrayLikeToArray(r, a); var t = {}.toString.call(r).slice(8, -1); return "Object" === t && r.constructor && (t = r.constructor.name), "Map" === t || "Set" === t ? Array.from(r) : "Arguments" === t || /^(?:Ui|I)nt(?:8|16|32)(?:Clamped)?Array$/.test(t) ? _arrayLikeToArray(r, a) : void 0; } }
function _iterableToArray(r) { if ("undefined" != typeof Symbol && null != r[Symbol.iterator] || null != r["@@iterator"]) return Array.from(r); }
function _arrayWithoutHoles(r) { if (Array.isArray(r)) return _arrayLikeToArray(r); }
function _arrayLikeToArray(r, a) { (null == a || a > r.length) && (a = r.length); for (var e = 0, n = Array(a); e < a; e++) n[e] = r[e]; return n; }
var namespaceType = exports.namespaceType = (0, _curry.default)(function (namespace, type) {
  return namespace + type;
});

// just [0], [1], [2] for request, success and fail types correspondingly
var makeRequestTypes = exports.makeRequestTypes = function makeRequestTypes(typePrefix) {
  return [typePrefix, typePrefix + '_SUCCESS', typePrefix + '_FAIL'];
};
var createReducer = exports.createReducer = function createReducer(handlers, initialState) {
  return function () {
    var state = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : initialState;
    var action = arguments.length > 1 ? arguments[1] : undefined;
    return handlers[action.type] ? handlers[action.type](state, action) : state;
  };
};

// replaces an item if list already has one with the same idKey, adds otherwise
var addOrReplace = exports.addOrReplace = function addOrReplace(list, item) {
  var idKey = arguments.length > 2 && arguments[2] !== undefined ? arguments[2] : 'id';
  var index = (0, _findIndex.default)(list, function (i) {
    return i[idKey] === item[idKey];
  });
  return index === -1 ? [].concat(_toConsumableArray(list), [item]) : [].concat(_toConsumableArray(list.slice(0, index)), [item], _toConsumableArray(list.slice(index + 1)));
};
var setterHandler = exports.setterHandler = function setterHandler() {
  for (var _len = arguments.length, keys = new Array(_len), _key = 0; _key < _len; _key++) {
    keys[_key] = arguments[_key];
  }
  return function (state, action) {
    return _objectSpread(_objectSpread({}, state), (0, _pick.default)(action, keys));
  };
};
var actionCreator = exports.actionCreator = function actionCreator(type) {
  for (var _len2 = arguments.length, keys = new Array(_len2 > 1 ? _len2 - 1 : 0), _key2 = 1; _key2 < _len2; _key2++) {
    keys[_key2 - 1] = arguments[_key2];
  }
  return function () {
    for (var _len3 = arguments.length, args = new Array(_len3), _key3 = 0; _key3 < _len3; _key3++) {
      args[_key3] = arguments[_key3];
    }
    return _objectSpread({
      type: type
    }, (0, _fromPairs.default)((0, _zip.default)(keys, args)));
  };
};
var makeRequestAction = exports.makeRequestAction = function makeRequestAction(namespacePrefix, apiCallback) {
  return function () {
    return {
      types: makeRequestTypes(namespacePrefix),
      promise: apiCallback.apply(void 0, arguments)
    };
  };
};
var createNestedReducer = exports.createNestedReducer = function createNestedReducer(parentReducer, subreducerPath, subreducer) {
  return function (state, action) {
    var parentUpdate = parentReducer(state, action);
    var subreducerState = (0, _get.default)(subreducerPath, state);
    var subreducerUpdate = subreducer(subreducerState, action);
    var updatedState = (0, _set.default)(subreducerPath, subreducerUpdate, _objectSpread({}, parentUpdate));
    return updatedState;
  };
};
var nestReducers = exports.nestReducers = function nestReducers(parentReducer, pathToReducerMap) {
  return Object.keys(pathToReducerMap).reduce(function (parentReducerI, path) {
    return createNestedReducer(parentReducerI, path, pathToReducerMap[path]);
  }, parentReducer);
};