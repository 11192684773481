"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.updateCategoryChange = exports.types = exports.targetType = exports.default = exports.cancelCategoryChange = exports.activateCategoryChange = void 0;
var _stateModule = require("../../../utils/stateModule");
var _tutorials = require("../tutorials");
// Some actions (category/tag change) can be called from different places (bottom bar, context menu)
var targetType = exports.targetType = {
  selection: 'selection',
  optionMenu: 'optionMenu'
};

// The state of application when user changes categories of selected tutorials
var initialState = {
  active: false,
  target: targetType.selection
};
var _module = (0, _stateModule.createStateModule)({
  typeNamespace: 'tutorials/categoryChange/',
  initialState: initialState
});
var types = exports.types = _module.types;
var _default = exports.default = _module.reducer;
var updateCategoryChange = exports.updateCategoryChange = _module.actionCreators.updateState;
var activateCategoryChange = exports.activateCategoryChange = function activateCategoryChange(target) {
  return function (dispatch) {
    dispatch(updateCategoryChange({
      active: true,
      target: target
    }));
    dispatch(_tutorials.actionCreators.setExpandElement('categoryList', true));
  };
};
var cancelCategoryChange = exports.cancelCategoryChange = function cancelCategoryChange() {
  return function (dispatch) {
    dispatch(updateCategoryChange({
      active: false
    }));
  };
};