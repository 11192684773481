"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.domOnlyProps = void 0;
var _excluded = ["initialValue", "autofill", "onUpdate", "valid", "invalid", "dirty", "pristine", "active", "touched", "visited", "autofilled", "error"];
function _objectWithoutProperties(e, t) { if (null == e) return {}; var o, r, i = _objectWithoutPropertiesLoose(e, t); if (Object.getOwnPropertySymbols) { var n = Object.getOwnPropertySymbols(e); for (r = 0; r < n.length; r++) o = n[r], t.indexOf(o) >= 0 || {}.propertyIsEnumerable.call(e, o) && (i[o] = e[o]); } return i; }
function _objectWithoutPropertiesLoose(r, e) { if (null == r) return {}; var t = {}; for (var n in r) if ({}.hasOwnProperty.call(r, n)) { if (e.indexOf(n) >= 0) continue; t[n] = r[n]; } return t; }
/* eslint-disable no-unused-vars */
// https://github.com/erikras/redux-form/issues/1249
// TODO: remove once upgraded to redux-form v6, which makes this redundant
var domOnlyProps = exports.domOnlyProps = function domOnlyProps(_ref) {
  var initialValue = _ref.initialValue,
    autofill = _ref.autofill,
    onUpdate = _ref.onUpdate,
    valid = _ref.valid,
    invalid = _ref.invalid,
    dirty = _ref.dirty,
    pristine = _ref.pristine,
    active = _ref.active,
    touched = _ref.touched,
    visited = _ref.visited,
    autofilled = _ref.autofilled,
    error = _ref.error,
    domProps = _objectWithoutProperties(_ref, _excluded);
  return domProps;
};
/* eslint-enable no-unused-vars */