"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.setPricingKey = exports.isDefaultPlan = exports.getPlans = exports.getPlanOptions = exports.findPromo = exports.fetchPricingPlans = exports.fetchPlanInfo = exports.default = void 0;
var _module = require("../../utils/module");
var _startsWith = _interopRequireDefault(require("lodash/startsWith"));
var _sortBy = _interopRequireDefault(require("lodash/fp/sortBy"));
var _pricing = require("./pricing");
function _interopRequireDefault(e) { return e && e.__esModule ? e : { default: e }; }
function _typeof(o) { "@babel/helpers - typeof"; return _typeof = "function" == typeof Symbol && "symbol" == typeof Symbol.iterator ? function (o) { return typeof o; } : function (o) { return o && "function" == typeof Symbol && o.constructor === Symbol && o !== Symbol.prototype ? "symbol" : typeof o; }, _typeof(o); }
function _slicedToArray(r, e) { return _arrayWithHoles(r) || _iterableToArrayLimit(r, e) || _unsupportedIterableToArray(r, e) || _nonIterableRest(); }
function _nonIterableRest() { throw new TypeError("Invalid attempt to destructure non-iterable instance.\nIn order to be iterable, non-array objects must have a [Symbol.iterator]() method."); }
function _unsupportedIterableToArray(r, a) { if (r) { if ("string" == typeof r) return _arrayLikeToArray(r, a); var t = {}.toString.call(r).slice(8, -1); return "Object" === t && r.constructor && (t = r.constructor.name), "Map" === t || "Set" === t ? Array.from(r) : "Arguments" === t || /^(?:Ui|I)nt(?:8|16|32)(?:Clamped)?Array$/.test(t) ? _arrayLikeToArray(r, a) : void 0; } }
function _arrayLikeToArray(r, a) { (null == a || a > r.length) && (a = r.length); for (var e = 0, n = Array(a); e < a; e++) n[e] = r[e]; return n; }
function _iterableToArrayLimit(r, l) { var t = null == r ? null : "undefined" != typeof Symbol && r[Symbol.iterator] || r["@@iterator"]; if (null != t) { var e, n, i, u, a = [], f = !0, o = !1; try { if (i = (t = t.call(r)).next, 0 === l) { if (Object(t) !== t) return; f = !1; } else for (; !(f = (e = i.call(t)).done) && (a.push(e.value), a.length !== l); f = !0); } catch (r) { o = !0, n = r; } finally { try { if (!f && null != t.return && (u = t.return(), Object(u) !== u)) return; } finally { if (o) throw n; } } return a; } }
function _arrayWithHoles(r) { if (Array.isArray(r)) return r; }
function ownKeys(e, r) { var t = Object.keys(e); if (Object.getOwnPropertySymbols) { var o = Object.getOwnPropertySymbols(e); r && (o = o.filter(function (r) { return Object.getOwnPropertyDescriptor(e, r).enumerable; })), t.push.apply(t, o); } return t; }
function _objectSpread(e) { for (var r = 1; r < arguments.length; r++) { var t = null != arguments[r] ? arguments[r] : {}; r % 2 ? ownKeys(Object(t), !0).forEach(function (r) { _defineProperty(e, r, t[r]); }) : Object.getOwnPropertyDescriptors ? Object.defineProperties(e, Object.getOwnPropertyDescriptors(t)) : ownKeys(Object(t)).forEach(function (r) { Object.defineProperty(e, r, Object.getOwnPropertyDescriptor(t, r)); }); } return e; }
function _defineProperty(e, r, t) { return (r = _toPropertyKey(r)) in e ? Object.defineProperty(e, r, { value: t, enumerable: !0, configurable: !0, writable: !0 }) : e[r] = t, e; }
function _toPropertyKey(t) { var i = _toPrimitive(t, "string"); return "symbol" == _typeof(i) ? i : i + ""; }
function _toPrimitive(t, r) { if ("object" != _typeof(t) || !t) return t; var e = t[Symbol.toPrimitive]; if (void 0 !== e) { var i = e.call(t, r || "default"); if ("object" != _typeof(i)) return i; throw new TypeError("@@toPrimitive must return a primitive value."); } return ("string" === r ? String : Number)(t); }
var type = (0, _module.namespaceType)('redux/plan/');
var FETCH_PLAN = type('FETCH_PLAN');
var FETCH_PLAN_SUCCESS = type('FETCH_PLAN_SUCCESS');
var FETCH_PLAN_FAIL = type('FETCH_PLAN_FAIL');
var FETCH_PRICING_PLANS = type('FETCH_PRICING_PLANS');
var FETCH_PRICING_PLANS_SUCCESS = type('FETCH_PRICING_PLANS_SUCCESS');
var FETCH_PRICING_PLANS_FAIL = type('FETCH_PLAN_FAIL');
var FIND_PROMO = type('FIND_PROMO');
var FIND_PROMO_SUCCESS = type('FIND_PROMO_SUCCESS');
var FIND_PROMO_FAIL = type('FIND_PROMO_FAIL');
var SET_PRICING_KEY = type('SET_PRICING_KEY');
var initialState = {
  data: [],
  // dynamic data
  planPricing: {},
  pricingKey: 'premium1',
  error: ''
};
var handlers = _defineProperty(_defineProperty(_defineProperty(_defineProperty(_defineProperty(_defineProperty(_defineProperty(_defineProperty(_defineProperty({}, FETCH_PLAN, function (state, action) {
  return _objectSpread(_objectSpread({}, state), {}, {
    specialPlanId: action.specialPlanId
  });
}), FETCH_PLAN_SUCCESS, function (state, action) {
  return _objectSpread(_objectSpread({}, state), {}, {
    data: action.result.reduce(function (list, item) {
      return (0, _module.addOrReplace)(list, item);
    }, state.data)
  });
}), FETCH_PLAN_FAIL, function (state, action) {
  return _objectSpread(_objectSpread({}, state), {}, {
    error: action.error
  });
}), FETCH_PRICING_PLANS_SUCCESS, function (state, _ref) {
  var r = _ref.result;
  return _objectSpread(_objectSpread({}, state), {}, {
    planPricing: r
  });
}), FETCH_PRICING_PLANS_FAIL, function (state, action) {
  return _objectSpread(_objectSpread({}, state), {}, {
    error: action.error
  });
}), FIND_PROMO, function (state, _ref2) {
  var r = _ref2.result;
  return _objectSpread(_objectSpread({}, state), {}, {
    promoLoading: true,
    promoPlan: null
  });
}), FIND_PROMO_SUCCESS, function (state, _ref3) {
  var r = _ref3.result;
  return _objectSpread(_objectSpread({}, state), {}, {
    promoLoading: false,
    promoPlan: r.plan
  });
}), FIND_PROMO_FAIL, function (state, _ref4) {
  var r = _ref4.result;
  return _objectSpread(_objectSpread({}, state), {}, {
    promoLoading: false
  });
}), SET_PRICING_KEY, function (s, a) {
  return _objectSpread(_objectSpread({}, s), {}, {
    pricingKey: a.pricingKey
  });
});
var _default = exports.default = (0, _module.createReducer)(handlers, initialState);
var fetchPlanInfo = exports.fetchPlanInfo = function fetchPlanInfo(specialPlanId, spi, discountCode) {
  return {
    types: [FETCH_PLAN, FETCH_PLAN_SUCCESS, FETCH_PLAN_FAIL],
    promise: function promise(client) {
      return client.get("/getPlanInfo", {
        params: {
          plan_ids: [specialPlanId].join(';'),
          pricing: true,
          spi: spi,
          discountCode: discountCode
        }
      });
    },
    specialPlanId: specialPlanId ? parseInt(specialPlanId, 0) : undefined
  };
};
var fetchPricingPlans = exports.fetchPricingPlans = function fetchPricingPlans() {
  return {
    types: [FETCH_PRICING_PLANS, FETCH_PRICING_PLANS_SUCCESS, FETCH_PRICING_PLANS_FAIL],
    promise: function promise(client) {
      return client.get("/getPlanPricing");
    }
  };
};
var findPromo = exports.findPromo = function findPromo(promoCode) {
  return {
    types: [FIND_PROMO, FIND_PROMO_SUCCESS, FIND_PROMO_FAIL],
    promise: function promise(client) {
      return client.get("/findPromoCode", {
        params: {
          promoCode: promoCode
        }
      });
    }
  };
};
function comparePricingKey(keyA, keyB) {
  return !keyA && !keyB || (0, _startsWith.default)(keyA, keyB);
}
var getPlans = exports.getPlans = function getPlans(state) {
  return {
    month: state.data.find(function (item) {
      return comparePricingKey(state.pricingKey, item.pricingKey) && item.frequency === 'month';
    }),
    year: state.data.find(function (item) {
      return comparePricingKey(item.pricingKey, state.pricingKey) && item.frequency === 'year';
    }),
    special: state.data.find(function (item) {
      return Number(item.id) === Number(state.specialPlanId);
    }),
    promo: state.promoPlan
  };
};
var setPricingKey = exports.setPricingKey = (0, _module.actionCreator)(SET_PRICING_KEY, 'pricingKey');
var isDefaultPlan = exports.isDefaultPlan = function isDefaultPlan(state) {
  return function (id) {
    var plan = state.data.find(function (item) {
      return item.id === id;
    });
    return plan && plan.pricingKey && plan.pricingKey.startsWith('premium');
  };
};
var getPlanOptions = exports.getPlanOptions = function getPlanOptions(state) {
  var planOptions = Object.entries(state.plan.planPricing).filter(function (_ref5) {
    var _ref6 = _slicedToArray(_ref5, 2),
      v = _ref6[1];
    return v.pricingKey && v.pricingKey.includes('premium') && v.frequency === 'month';
  }).map(function (_ref7) {
    var _ref8 = _slicedToArray(_ref7, 1),
      k = _ref8[0];
    return {
      text: (0, _pricing.getPricingPlanName)(k),
      key: k
    };
  });
  var sortedPlanOptions = (0, _sortBy.default)('key', planOptions);
  return sortedPlanOptions;
};